import { DocumentReportItem, DocumentReportParams, ReportDto, ReportResultDto } from "../../domain";
import { JObject } from "../../../../common/data/models";
import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { ReportMapper } from "../mappers";
import { PaginatedRequestParams } from "../../../../common/pagination";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { TawreedConstants, TawreedMenu } from "../../../constants";
import { CssUtils } from "../../../../helpers/css";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { ReportRepository } from "./report.repository";
import { DocumentReportMapper } from "../mappers/documents-report";
import { DocumentReportParamsMapper } from "../mappers/documents-params";
import { OutboundPaymentMethodService } from "../../../finance/domain";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { Routes } from "../../../../router";
import { useHistory } from "react-router-dom";
import { DocumentType } from "../../../finance/data";

export class DocumentsReportRepository extends ReportRepository<DocumentReportParams, DocumentReportItem> {

    private readonly paymentMethodService = new OutboundPaymentMethodService();
    private paymentMethods?: TawreedMenu;
    history = useHistory();

    /**
     *
     * @param params
     */
    public async generate(params: DocumentReportParams, scale: number, constants: TawreedConstants | undefined): Promise<ReportDto<DocumentReportItem>> {

        if (!this.paymentMethods) {
            this.paymentMethods = await this.paymentMethodService.getPaymentMethods();
        }
        const dataKey = 'documentId';
        const columns = [
            { field: "documentId", header: 'lbl_reports_documents_documentid', style: CssUtils.width('10%') },
            { field: "documentDate", header: 'lbl_reports_documents_documentdate', ...TawreedTableFilters.date.range('documentDate', 'date'), style: CssUtils.width('10%') },
            { field: "documentType", header: 'lbl_reports_documents_documenttype', ...TawreedTableFilters.dropdown.dropdown("documentType", constants && constants.documentTypes ? constants.documentTypes : [], 'value', 'label'), style: CssUtils.width('10%') },
            { field: "partner.partnerName", header: 'lbl_reports_documents_partner', style: CssUtils.width('10%') },
            { field: "description", header: 'lbl_reports_documents_description', style: CssUtils.width('25%') },
            { field: "amount", header: 'lbl_reports_documents_amount', ...TawreedTableFormatters.decimal('amount', scale, CssUtils.width('10%')) },
            { field: "paymentMethodId", header: 'lbl_reports_documents_paymentmethod', ...TawreedTableFilters.dropdown.dropdown("paymentMethodId", this.paymentMethods ?? [], 'value', 'label'), style: CssUtils.width('10%') },
            { field: "paymentReference", header: 'lbl_reports_documents_paymentreference', style: CssUtils.width('10%') },
        ];
        const actions = (rowData: DocumentReportItem) => {
            console.log("rowData", rowData);
            const res = new Array<TawreedAction>();
            if (rowData.showPrintDocument) {
                const onExportAs = (url?: string) => {
                    return new Promise<void>((resolve, reject) => {
                        try {
                            this.export(url!);
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    });
                }
                res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_pdf', PrimeIcons.FILE_PDF, () => onExportAs(rowData.documentUrls.pdfReportURI)));
                res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => onExportAs(rowData.documentUrls.xlsReportURI)));
            }
            return res;
        };
        const onDetails = (sender?: DocumentReportItem): Promise<void> => {
            return new Promise((resolve, reject) => {
                if (sender && sender.documentId) {
                    switch (sender.documentType) {
                        case DocumentType.CREDIT_NOTE:
                            this.history.push(Routes.FinanceDocumentType3Details.replace(':id', sender.documentId.toString()));
                            break;
                        case DocumentType.DEBIT_NOTE:
                            this.history.push(Routes.FinanceDocumentType4Details.replace(':id', sender.documentId.toString()));
                            break;
                        case DocumentType.PAYMENT_VOUCHER:
                            this.history.push(Routes.FinanceDocumentType1Details.replace(':id', sender.documentId.toString()));
                            break;
                        case DocumentType.RECEIPT_VOUCHER:
                            this.history.push(Routes.FinanceDocumentType2Details.replace(':id', sender.documentId.toString()));
                            break;
                        case DocumentType.JOURNAL:
                            this.history.push(Routes.FinanceDocumentJournalTypeDetails.replace(':id', sender.documentId.toString()));
                            break;
                        default:
                            this.history.push(Routes.FinanceDocumentTypeGeneralDetails.replace(':id', sender.documentId.toString()));
                            break;

                    }
                    resolve();
                } else {
                    reject();
                }
            });
        }
        const filter = DocumentReportParamsMapper.toFilter(params);
        const onSearch = (req: PaginatedRequestParams) => this.search(req, scale, constants);
        const onclick = new TawreedAction(TawreedActionName.DeliveryPartnersDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false)
        return Promise.resolve(ReportMapper.toDto({ ...params, dataKey, columns, filter, onSearch, actions, onclick }));
    }

    public search(request: PaginatedRequestParams, scale: number, constants: TawreedConstants | undefined): Promise<ReportResultDto<DocumentReportItem>> {
        const params = {
            sort: 'documentId,desc',
            page: request.page,
            size: request.size,
        };
        const data = this.fromRequestParamsToJObject(request);

        return tawreedHttpService.post<JObject, JObject>(endpoints.REPORTS_DOCUMENTS_FETCH, data, params, undefined, 'error')
            .then((res) => {
                if (!res || !res.data) {
                    return Promise.reject(null);
                }
                return DocumentReportMapper.fromJson(res.data, constants, scale);
            });
    }
}
