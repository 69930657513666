import { JObject } from "../../../../common/data/models"
import { DocumentDto, ManualDocumentDTo } from "../../domain"
import { Document, DocumentType, ManualDocument } from "../models"
import { PartnerMapper } from "../../../partners/data";
import { DateUtils } from "../../../../helpers/date";
import { DocumentFileMapper } from "./document-file";
import { AccountDtoMapper } from "./account";

export const ManualDocumentMapper = {

    /**
     *
     */
    toJson: (model: ManualDocument): JObject => {
        const obj: JObject = {
            partnerId: PartnerMapper.toJson(model.partner).partnerId,
            documentDate: DateUtils.toISO(model.documentDate, 'date', 'end'),
            descriptionEn: model.descriptionEn,
            amount: model.amount,
            typeId: model.typeId,
            paymentReference: model.paymentReference,
            paymentMethodId: model.paymentMethodId,
            paymentTransactionId: model.paymentTransactionId,
            documentType: model.documentType,
            documentId: model.documentId,
            descriptionAr: model.descriptionAr,
            firstAccountId: model.firstAccountId,
            secondAccountId: model.secondAccountId,
            isFirstAccountCredit: model.isFirstAccountCredit,
            isSecondAccountCredit: model.isSecondAccountCredit,
        };
        return obj;
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {Document}
     */
    fromDto: (dto: Partial<ManualDocumentDTo>): ManualDocument => {

        return { partner: PartnerMapper.fromDto(dto.partner!), documentDate: dto.documentDate!, 
            descriptionEn: dto.descriptionEn!, amount: dto.amount!, typeId: dto.typeId, paymentReference: dto.paymentReference, 
            paymentMethodId: dto.paymentMethodId, descriptionAr: dto.descriptionAr, documentId: dto.documentId,
             documentType: dto.documentType, paymentTransactionId: dto.paymentTransactionId,
             isFirstAccountCredit: dto.isFirstAccountCredit, isSecondAccountCredit: dto.isSecondAccountCredit,
             firstAccountId: dto.firstAccountId, secondAccountId: dto.secondAccountId
             }

    },

    /**
    *
    */
    fromJson: (model: JObject): ManualDocument => {
        return {
            partner: {
                partnerId: model.partnerId,
                partnerName: model.partnerName
            }, documentDate: DateUtils.fromISO(model.documentDate), descriptionEn: model.description!,
            amount: model.amount!, typeId: model.documentType,
            files: model.documentFiles!.map((el: JObject) => DocumentFileMapper.toModel(el)),
            documentId: model.documentId,
            documentType: model.documentType,
            paymentTransactionId: model.paymentTransactionId,
            descriptionAr: model.descriptionAr,
            paymentMethodId: model.paymentMethodId,
            paymentReference: model.paymentReference,
            isFirstAccountCredit: model.isFirstAccountCredit,
             isSecondAccountCredit: model.isSecondAccountCredit,
         firstAccountId: model.firstAccountId, secondAccountId: model.secondAccountId,
         firstAccount: AccountDtoMapper.fromJson(model.firstAccount),
         secondAccount: AccountDtoMapper.fromJson(model.secondAccount),
        }
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {Document}
     */
    toDto: (dto: Partial<ManualDocument>): ManualDocumentDTo => {
        return {
            partner: PartnerMapper.toDto(dto.partner!),
            documentDate: dto.documentDate!,
            descriptionEn: dto.descriptionEn!,
            amount: dto.amount!,
            typeId: dto.typeId!,
            files: dto.files!.map((el: any) => DocumentFileMapper.toModel(el)),
            documentId: dto.documentId,
            documentType: dto.documentType,
            paymentTransactionId: dto.paymentTransactionId,
            descriptionAr: dto.descriptionAr,
            paymentMethodId: dto.paymentMethodId,
            paymentReference: dto.paymentReference,
            isFirstAccountCredit: dto.isFirstAccountCredit,
             isSecondAccountCredit: dto.isSecondAccountCredit,
         firstAccountId: dto.firstAccountId, 
         secondAccountId: dto.secondAccountId,
         firstAccount: dto.firstAccount,
         secondAccount: dto.secondAccount
        };
    },
}
