import React from 'react'
import {useParams} from 'react-router-dom';
import {OrderForm} from '../components';
import {OrdersTable} from '../components/orders-table';
import { PublicOrderForm } from '../components/order-form/public-order-form';
import { tawreedTranslationService } from '../../../../../common/translations';
import { AuthUtils } from '../../../../auth/domain';
import { OrderFormFullHistory } from '../components/order-form/order-form-full-history';

/**
 *
 */
export const OrdersPage: React.FC = () => <OrdersTable name="tbl.sales.orders"/>;

/**
 *
 * @returns
 */
export const OrderPage: React.FC = () => {

    const {id} = useParams<{ id: string | undefined }>();
    return <OrderForm orderId={id ? Number.parseInt(id) : undefined} showBack/>
}
export const OrderTrackingHistoryPage: React.FC = () => {

    const {id} = useParams<{ id: string | undefined }>();
    return <OrderFormFullHistory/>
}

/**
 *
 * @returns
 */
export const PublicOrderPage: React.FC = () => {

    
    const {id} = useParams<{ id: string | undefined }>();
    const {lang} = useParams<{ lang: string | undefined }>();
    // tawreedTranslationService.setLocale(lang!);
    // if (['ar'].includes(lang!)) {
    //     document.documentElement.dir = 'rtl';
    //     document.documentElement.classList.remove('ltr');
    //     document.documentElement.classList.add('rtl');
    // } else {
    //     document.documentElement.dir = 'ltr';
    //     document.documentElement.classList.remove('rtl');
    //     document.documentElement.classList.add('ltr');
    // }
    AuthUtils.current().setLanguage(lang!); 
    return <PublicOrderForm lang={lang} orderId={id ? id : undefined}/>
}
