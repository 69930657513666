import React from "react";
import { tawreedTranslationService } from "../../../../common/translations";
import { AuthUtils } from "../../../auth/domain";
import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";
import { Chart } from "primereact/chart";
import { DashboardItemValue, PartnersChartDto } from "../../domain/dtos/dashboard";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { ObjectUtils } from "../../../../helpers/object";
import { GlobalContext } from "../../../../context";

declare type DashboardPartnersChartProps = {

    showTotal: boolean,
    className: string;
    title: string;

    loading: boolean;
    displayPartner: boolean;

    dto: PartnersChartDto | undefined;
    showDetails: (value: number, xlsxReportURI :string, pdfReportURI :string) => void;
    type: number,
}

declare type DashboardPartnersChartState = {
    data: {
        labels: Array<string>;
        datasets: Array<{ label: string; backgroundColor: string; data: Array<any>; [key: string]: any }>;
    };
}

export const DashboardFinancePartnersChart: React.FC<DashboardPartnersChartProps> = (props) => {

    const { auth: { user } } = React.useContext(GlobalContext);
    const [state, setState] = React.useState<DashboardPartnersChartState>({
        data: {
            labels: [],
            datasets: [
                {
                    label: tawreedTranslationService.translate('lbl_dashboard_partners_chart_total_count'),
                    backgroundColor: '#FFA726',
                    data: []
                }
            ]
        },
    });

    const valueCalculator = (e: DashboardItemValue): string => {
        if (e === undefined || e == null) {
            return '';
        } else if (typeof e === 'number') {
            return e.toString();
        } else if (typeof e === 'string') {
            return e;
        } 
        return '';
    }
    React.useEffect(
        () => {

            setState(previous => {
                return {
                    ...previous,
                    data: {
                        labels: props.dto ? props.dto.value.map(e => tawreedTranslationService.translate(e.label)) : [],
                        datasets: [
                            {
                                label: tawreedTranslationService.translate('lbl_dashboard_partners_chart_total_' + "count"),
                                backgroundColor: props.dto ? props.dto.color : '#FFA726',
                                data: props.dto ? props.dto.value.map(e => valueCalculator(e.value)) : [],
                                barThickness: 30,
                                maxBarThickness: 32,
                            }
                        ]
                    },
                }
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.dto]);

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        height: 300,
        plugins: {
            legend: {
                align: 'end',
                labels: {
                    font: {
                        family: AuthUtils.current().language === 'ar' ? '--arabic-font-family' : 'var(--font-family)'
                    },
                    color: '#495057'
                },
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    const header = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <span>
                        {tawreedTranslationService.translate(props.title)}
                    </span>
                </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="col-12 sm:col-6">
                        {props.showTotal && <Tag severity={"primary"} style={{ width: '10rem', height: '3rem', fontSize: '1.2rem' }} value={ObjectUtils.toFixed(props.dto?.total, user?.scale)} /> }
                        </div>
                        <div className="col-12 sm:col-6">
                            <div className="flex justify-content-start sm:justify-content-end">
                                <Button tooltip={tawreedTranslationService.translate("lbl_finance_header")} icon={PrimeIcons.EYE} onClick={()=>props.showDetails(props.type, props.dto?.xlsxReportURI!,props.dto?.pdfReportURI!)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Card className={"card " + props.className} header={header()}>
            {
                (props.loading) ? <Skeleton/> : <Chart key="tawreed_dashboard_partners_chart" id="tawreed_dashboard_partners_chart" type="bar" height="16rem" data={state.data} options={options} style={{width: '100%'}}/>
            }
        </Card>
    )
}
