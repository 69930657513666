import React, { useState } from "react";
import { PrimeIcons } from "primereact/api";
import { useHistory } from "react-router-dom";

import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { CssUtils } from "../../../../../helpers/css";
import { Routes } from "../../../../../router";
import { TawreedTableFilters } from "../../../../../common/components/table/filters";
import { GlobalContext } from "../../../../../context";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { PaymentOrder, PaymentorderService } from "../../../domain";
import { Button } from "primereact/button";
import { AuthUtils } from "../../../../auth/domain";
import { tawreedTranslationService } from "../../../../../common/translations";


export const PaymentorderTable: React.FC = () => {
    const history = useHistory();
    const service = React.useMemo(() => new PaymentorderService(), []);
    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);

    const onRelease = (orderId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            setReload(false);
                service.releaseAmount(orderId)
                    .then((result:boolean) => {
                        if(result){
                            setReload(true);
                            resolve();
                        }
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        reject(err);
                    });
        });
    };
    const onCancel = (orderId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            setReload(false);
                service.cancel(orderId)
                    .then((result:boolean) => {
                        if(result){
                            setReload(true);
                            resolve();
                        }
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        reject(err);
                    });
        });
    };
    const onDetails = (sender?: PaymentOrder): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sender && sender.paymentOrderId) {
                history.push(Routes.PaymentOrderDetails.replace(':id', sender.paymentOrderId.toString()));
                resolve();
            } else {
                reject();
            }
        });
    }
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'paymentOrderId',
        name: 'tbl.payment.orders',
        title: 'lbl_payment_orders',
        reload: reload,
        outLoader: loading,
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                field: "paymentOrderId",
                header: "lbl_payment_orders_orderId",
                sortable: true,
                filter: true,
                style: CssUtils.width('10%'),
            },
            {                
                field: "createdAt",
                header: "lbl_payment_orders_createdat",
                sortable: true,
                filter: true,
                style: CssUtils.width('10%'),
                ...TawreedTableFilters.date.range("createdAt", 'datetime'),
            },
            {
                field: "sellerPartner.partnerName",
                header: "lbl_payment_orders_seller_partner",
                sortable: true,
                sortField: 'store',
                filter: true,
                filterField: 'store',
                style: CssUtils.width('10%'),
            },
            {
                field: "buyerPartner.partnerName",
                header: "lbl_payment_orders_buyer_partner",
                sortable: true,
                sortField: 'customer',
                filter: true,
                filterField: 'customer',
                style: CssUtils.width('10%'),
            },
            {
                field: AuthUtils.current().language === 'en' ? "paymentPartnerTitleEn": "paymentPartnerTitleAr",
                header: "lbl_payment_orders_payment_partner",
                sortable: true,
                sortField:  AuthUtils.current().language === 'en' ? "paymentPartnerTitleEn": "paymentPartnerTitleAr",
                filter: true,
                filterField: AuthUtils.current().language === 'en' ? "paymentPartnerTitleEn": "paymentPartnerTitleAr",
                style: CssUtils.width('10%')
            },
            {
                field: "total",
                header: "lbl_payment_orders_amount",
                sortable: true,
                filter: true,
                headerStyle: AuthUtils.current().language === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
                ...TawreedTableFormatters.decimal('paymentOrderAmount', user?.scale ,undefined, CssUtils.width('5%')),
            },
            {
                field: "paymentOrderStatus",
                header: "lbl_payment_txn_txnstatus",
                style: CssUtils.width('15%'),
                 ...TawreedTableFilters.dropdown.dropdown("paymentOrderStatus", constants && constants.paymentOrderStatuses ? constants.paymentOrderStatuses : [], 'value', 'label', 'in'),
            },
            {
                field: "actions",
                // header: "lbl_add_to_cart",
                body: (options: any) => {
                    if(options.paymentOrderStatus === constants!.paymentOrderStatuses.filter(x=>x.label==="AMOUNT_BLOCKED").at(0)?.id){
                    return <React.Fragment>
                        <Button
                            type="button"
                            style={{ width: "40%", height: "35px" }}
                            icon={PrimeIcons.CHECK}
                            className="p-button-success"
                            tooltip={tawreedTranslationService.translate("lbl_payment_orders_release")}
                            onClick={() => {
                                onRelease(options.orderId).finally(() => {
                                    setLoading(false);
                                });
                            }} />
                        <Button
                            type="button"
                            style={{ width: "40%", height: "35px" }}
                            icon={PrimeIcons.TIMES}
                            className="p-button-danger ml-1"
                            tooltip={tawreedTranslationService.translate("lbl_cancel")}
                            onClick={() => {
                                onCancel(options.orderId).finally(() => {
                                    setLoading(false);
                                });
                            }} />
                    </React.Fragment>
                    }
                },
                style: CssUtils.width('12%'),
            }

        ],
        row: {
            onClick: new TawreedAction(TawreedActionName.PaymentTransactionsDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onDetails, 'p-button-secondary', false),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'paymentOrderId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                'createdAt': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        },
                    ]
                },
                'store': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'customer': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'paymentPartnerTitleEn': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'paymentPartnerTitleAr': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'total': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
                // 'paymentOrderStatus': {
                //     operator: 'AND',
                //     constraints: [
                //         {
                //             value: 1,
                //             matchMode: 'equals',
                //         },
                //     ]
                // },                
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'createdAt',
                sortOrder: -1,
                multiSortMeta: undefined,
            },
        },
    }
    return (<TawreedTable  {...tawreed} />);
}