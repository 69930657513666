import { JObject } from "../../../../../common/data/models";
import { paymentMode } from "../models";
import { paymentModeDto } from "../../domain";

export const PaymentModeMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {paymentMode}
     */
    fromJson: (json?: JObject): paymentMode => {
        return {
            paymentModeId: json?.paymentModeId,
            priceListId: json?.priceListId,
            titleAr: json?.titleAr,
            titleEn: json?.titleEn,
            payThroughUS: json?.payThroughUS,
            isValid: json?.isValid,
            msg: json?.msg
        }
    },

    /**
     *
     */
    toJson: (model: paymentMode): JObject => {
        return { ...model };
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {paymentMode}
     */
    fromDto: (dto: paymentModeDto): paymentMode => {
        return {
            paymentModeId: dto.paymentModeId,
            priceListId: dto.priceListId,
            titleAr: dto.titleAr,
            titleEn: dto.titleEn,
            payThroughUS: dto.payThroughUS,
        }
    },

    /**
     *
     */
    toDto: (model: paymentMode): paymentModeDto => {
        return {
            paymentModeId: model.paymentModeId,
            priceListId: model.priceListId,
            titleAr: model.titleAr,
            titleEn: model.titleEn,
            payThroughUS: model.payThroughUS,
            isValid: model.isValid,
            msg: model.msg,
            disabled: !model.isValid
        };
    },
}

