import React from "react";
import { Panel } from "primereact/panel";
import { PrimeIcons } from "primereact/api";

import { TawreedCalendar, TawreedCheckbox, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputText } from "../../../../common/components/form";
import { DocumentDto, DocumentService, ManualDocumentDTo } from "../../domain";
import { tawreedTranslationService } from "../../../../common/translations";
import { DocumentMapper, DocumentType } from "../../data";
import { PartnerAutoCompleteComponent } from "../../../partners";
import { TawreedActionName } from "../../../../common/actions";
import { FinancePaymentMethodDropdown } from "./finance-payment-method.dropdown";
import { Routes } from "../../../../router";
import { useHistory, useParams } from "react-router-dom";
import { FinanceDocumentTable } from "./finance-document-table";
import { FileService, TawreedFile } from "../../../upload";
import { GlobalContext } from "../../../../context";
import { AccountAutoCompleteComponent } from "./account-autocomplete.component";

export const JournalDocumentForm: React.FC = () => {
    // di
    const service: DocumentService = React.useMemo(() => new DocumentService(), []);
    const fileService: FileService = React.useMemo(() => new FileService(), []);



    const { id } = useParams<{ id: string | undefined }>();
    // states
    const [loading, setLoading] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<ManualDocumentDTo>>({
        isFirstAccountCredit: false,
        isSecondAccountCredit: false
    });
    const [balance, setBalance] = React.useState<number>(0);
    const history = useHistory();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const { constants: { constants } } = React.useContext(GlobalContext);



    const onSubmitExecute = (data: Partial<ManualDocumentDTo>, cb: TawreedFormSubmitCallback<Partial<ManualDocumentDTo>>): Promise<void> => {
        setLoading(true);
        return service.createManualDocument(data)
            .then((_res) => {
                if (_res) {
                    setData({...data, documentId: _res});
                    history.replace(Routes.FinanceDocumentJournalTypeDetails.replace(':id', _res ? _res.toString() : "0"));
                    
                    
                setLoading(false);

                }
                else{
                // setData(initial);
                setBalance(0);
                setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                cb(err);
            });
    };

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setDisabled(true);
                setLoading(true);
            }
            service.getManualDetails(Number.parseInt(id))
                .then(res => {
                    console.log("res",res);
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
            setDisabled(true);
        }
    }, [data.documentId, reload, id]);

    const addDocumentFile = (deliveryCost: TawreedFile, title: string) => {
        setLoading(true);
        service.createFile(deliveryCost, title, data.documentId!).then((res) => {

        }).finally(() => {
            setReload(!reload);
        });
    }

    const deleteDocumentFile = async (id: number) => {
        try {
            setLoading(true);
            const result = await service.deleteFile(id);
            if (result) {
                let deliveryCosts = data.files?.filter(el => el.id !== id);
                setData({ ...data, files: deliveryCosts });
            }

        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }
    const downloadFile = async (fileId: string, name?: string) => {
        try {
            setLoading(true);
            await fileService.downloadS3(fileId, name);

        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const onSubmit: TawreedFormSubmitProps<Partial<DocumentDto>> = {
        showButton: !disabled,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<Partial<DocumentDto>>(TawreedActionName.FinanceDocumentsCreate, 'lbl_save', PrimeIcons.SAVE, onSubmitExecute),
    };

    const getBalance = (partnerId: number) => service.getBalance(partnerId).then(res => setBalance(res));

    return (
        <TawreedForm
            className="grid form-grid"
            title={"lbl_finance_documents_journal"} loading={loading} mode="Create"
            dataKey="documentId" data={data}
            submit={onSubmit}
            useReset={!disabled}>

            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                <TawreedFormField name="firstAccount" title="lbl_finance_documents_first_account_id" className="field col-12 md:col-6">
                        <AccountAutoCompleteComponent autoCompleteDisabled={disabled} accountType={undefined} autoCompleteName="firstAccount" autoCompleteField="accountTitle" autoCompleteRender="form" autoCompleteRules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="isFirstAccountCredit" title="lbl_finance_documents_is_first_account_credit" className="field col-12 md:col-6">
                        <TawreedCheckbox render="form" name="isFirstAccountCredit" disabled={disabled} />
                    </TawreedFormField>

                    <TawreedFormField name="secondAccount" title="lbl_finance_documents_second_account_id" className="field col-12 md:col-6">
                    <AccountAutoCompleteComponent autoCompleteDisabled={disabled} accountType={undefined} autoCompleteName="secondAccount" autoCompleteField="accountTitle" autoCompleteRender="form" autoCompleteRules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="isSecondAccountCredit" title="lbl_finance_documents_is_second_account_credit" className="field col-12 md:col-6">
                        <TawreedCheckbox render="form" name="isSecondAccountCredit" disabled={disabled}/>
                    </TawreedFormField>

                    <TawreedFormField name="documentDate" title="lbl_finance_documents_document_date" className="field col-12 md:col-6">
                        <TawreedCalendar render="form" name="documentDate" rules={{ required: 'msg_field_is_required' }} disabled={disabled} />
                    </TawreedFormField>


                    <TawreedFormField name="amount" title="lbl_finance_documents_amount" className="field col-12 md:col-6">
                        <TawreedInputNumber render="form" name="amount" mode="decimal" rules={{ required: 'msg_field_is_required' }} disabled={disabled} />
                    </TawreedFormField>
                    {(!id || (id && data.partner?.partnerName)) && <TawreedFormField name="partner" title="lbl_finance_documents_partner" className="field col-12 md:col-6">
                        {<PartnerAutoCompleteComponent partnerType={undefined}
                            autoCompleteName="partner"
                            autoCompleteField="partnerName"
                            autoCompleteRules={{ required: 'msg_field_is_required' }}
                            autoCompleteRender="form"
                            autoCompleteOnSelect={(e) => getBalance(e.value.partnerId)}
                            autoCompleteDisabled={disabled} />}
                    </TawreedFormField>}


                    <TawreedFormField name="balance" title="lbl_finance_documents_partner_balance" className="field col-12 md:col-6">
                        <TawreedInputNumber render="standalone" name="balance" value={balance} mode="decimal" readOnly disabled />
                    </TawreedFormField>
                    {
                            <React.Fragment>
                                <TawreedFormField name="paymentMethodId" title="lbl_finance_documents_paymentmethodid" className="field col-12 md:col-6">
                                    <FinancePaymentMethodDropdown render="form" name="paymentMethodId"  rules={{ required: 'msg_field_is_required' }} disabled={disabled} />
                                </TawreedFormField>

                            </React.Fragment>
                    }
                     {
                        //(id &&
                            <React.Fragment>
                                <TawreedFormField name="paymentReference" title="lbl_finance_documents_paymentreference" className="field col-12 md:col-6">
                                    <TawreedInputText render="form" name="paymentReference" disabled={disabled} />
                                </TawreedFormField>

                            </React.Fragment>//)
                    }
                    {/* <TawreedFormField name="paymentTransactionId" title="lbl_payment_transactions_id" className="field col-12 md:col-6">
                        <TawreedInputText render="form" name="paymentTransactionId" disabled={disabled} />
                    </TawreedFormField> */}

                 
                    {<TawreedFormField name="descriptionAr" title="lbl_finance_documents_description_Ar" className="field col-12">
                        <TawreedInputText render="form" name="descriptionAr" rules={{ required: 'msg_field_is_required' }} disabled={disabled} />
                    </TawreedFormField>}

                    <TawreedFormField name="descriptionEn" title="lbl_finance_documents_description" className="field col-12">
                        <TawreedInputText render="form" name="descriptionEn" rules={{ required: 'msg_field_is_required', maxLength: { value: 100, message: 'msg_field_is_too_long' } }} disabled={disabled} />
                    </TawreedFormField>
                </div>
            </Panel>
            {data.documentId &&
                <Panel header={tawreedTranslationService.translate('lbl_Doument_files')} className="col-12">
                    {
                        <FinanceDocumentTable
                            addDocumentFile={addDocumentFile}
                            deleteDocumentFile={deleteDocumentFile}
                            downloadDocumentFile={downloadFile}
                            data={data.files}
                            loading={loading}
                        ></FinanceDocumentTable>
                    }
                </Panel >
            }
        </TawreedForm>
    );
}
