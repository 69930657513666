import React from "react";
import { DashboardState, getDashboardInitialState } from "./dashboard.state";
import { GlobalContext } from "../../../../../context";
import { DashboardItemRoute, DashboardItemValue, DashboardService } from "../../../domain";
import { AuthUtils } from "../../../../auth/domain";
import { TawreedActionName } from "../../../../../common/actions";
import { tawreedTranslationService } from "../../../../../common/translations";
import { DashboardCard } from "../dashboard-card";
import { DashboardOrdersChart } from "../dashboard-orders-chart";
import { DashboardPartnersChart } from "../dashboard-partners-chart";
import { DashboardOrdersTotalChart } from "../dashboard-orders-total-chart";
import { Routes } from "../../../../../router";
import { DashboardOrdersPerZoneChart } from "../dashboard-orders-per-zone-chart";


export const DashboardComponent: React.FC = (_props) => {

    // di
    const { constants: { constants }, auth: { user } } = React.useContext(GlobalContext);
    const service = React.useMemo(() => new DashboardService(), []);

    // state
    const [state, setState] = React.useState<DashboardState>(getDashboardInitialState());

    const onSearchPartners = React.useCallback((startDate: Date, endDate: Date): void => {
        setState(previous => {
            return {
                ...previous,
                partners: {
                    ...previous.partners,
                    charts: {
                        ...previous.partners.charts,
                        loading: true,
                        partners: undefined
                    },

                },
            }
        });
        service.getPartnersChart(startDate, endDate)
            .then(res => setState(previous => {
                return {
                    ...previous,
                    partners: {
                        ...previous.partners,
                        charts: {
                            ...previous.partners.charts,
                            loading: false,
                            partners: res,
                        }
                    },
                }
            }))
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        partners: {
                            ...previous.partners,
                            charts: {
                                ...previous.partners.charts,
                                loading: false,
                            }
                        },
                    }
                });
            });
    }, [service]);

    const onSearchOrders = React.useCallback((startDate: Date, endDate: Date): void => {
        setState(previous => {
            return {
                ...previous,
                orders: {
                    ...previous.orders,
                    charts: {
                        ...previous.orders.charts,
                        totals: {
                            ...previous.orders.charts.totals,
                            loading: true,
                            totals: undefined
                        },
                    }
                },
            }
        });
        service.getOrdersChartsTotals(startDate, endDate)
            .then(res => {
                setState(previous => {
                    return {
                        ...previous,
                        orders: {
                            ...previous.orders,
                            charts: {
                                ...previous.orders.charts,
                                totals: {
                                    ...previous.orders.charts.totals,
                                    loading: false,
                                    totals: res,
                                },
                            }
                        },
                    }
                });
            })
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        orders: {
                            ...previous.orders,
                            charts: {
                                ...previous.orders.charts,
                                totals: {
                                    ...previous.orders.charts.totals,
                                    loading: false,
                                },
                            },
                        },
                    }
                });
            });
    }, [service]);

    const onSearchZones = React.useCallback((startDate: Date, endDate: Date): void => {
        setState(previous => {
            return {
                ...previous,
                orders: {
                    ...previous.orders,
                    charts: {
                        ...previous.orders.charts,
                        zones: {
                            ...previous.orders.charts.zones,
                            loading: true,
                            zones: undefined
                        },
                    }
                },
            }
        });
        service.getOrdersPerZoneChartData(startDate, endDate)
            .then(res => {
                setState(previous => {
                    return {
                        ...previous,
                        orders: {
                            ...previous.orders,
                            charts: {
                                ...previous.orders.charts,
                                zones: {
                                    ...previous.orders.charts.zones,
                                    loading: false,
                                    zones: res,
                                },
                            }
                        },
                    }
                });
            })
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        orders: {
                            ...previous.orders,
                            charts: {
                                ...previous.orders.charts,
                                zones: {
                                    ...previous.orders.charts.zones,
                                    loading: false,
                                },
                            },
                        },
                    }
                });
            });
    }, [service]);

    const onSearchStates = React.useCallback((startDate: Date, endDate: Date): void => {
        setState(previous => {
            return {
                ...previous,
                orders: {
                    ...previous.orders,
                    charts: {
                        ...previous.orders.charts,
                        states: {
                            ...previous.orders.charts.states,
                            loading: true,
                            states: undefined
                        },
                    }
                },
            }
        });
        service.getOrdersPerStateChartData(startDate, endDate)
            .then(res => {
                setState(previous => {
                    return {
                        ...previous,
                        orders: {
                            ...previous.orders,
                            charts: {
                                ...previous.orders.charts,
                                states: {
                                    ...previous.orders.charts.states,
                                    loading: false,
                                    states: res,
                                },
                            }
                        },
                    }
                });
            })
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        orders: {
                            ...previous.orders,
                            charts: {
                                ...previous.orders.charts,
                                states: {
                                    ...previous.orders.charts.states,
                                    loading: false,
                                },
                            },
                        },
                    }
                });
            });
    }, [service]);

    React.useEffect(
        () => {
            let mounted = true;
            if (mounted) {

                const next = {
                    orders: {
                        counts: {
                            canShow: {
                                placed: AuthUtils.current().authorized(TawreedActionName.DashboardCardsOrdersPlaced),
                                processing: AuthUtils.current().authorized(TawreedActionName.DashboardCardsOrdersProcessing),
                                shipped: AuthUtils.current().authorized(TawreedActionName.DashboardCardsOrdersShipped),
                                readyForDelivery: AuthUtils.current().authorized(TawreedActionName.DashboardCardsOrdersReadyForDelivery),
                            },
                            loading: true,
                            value: undefined,
                        },
                        charts: {
                            counts: {
                                canShow: AuthUtils.current().authorized(TawreedActionName.DashboardChartOrders),
                                loading: true,
                                value: undefined,
                            },
                            totals: {
                                canShow: AuthUtils.current().authorized(TawreedActionName.DashboardChartOrdersTotals),
                                loading: false,
                                totals: undefined,
                            },
                            zones: {
                                canShow: AuthUtils.current().authorized(TawreedActionName.DashboardChartOrdersPerZone),
                                loading: false,
                                zones: undefined,
                            },
                            states: {
                                canShow: AuthUtils.current().authorized(TawreedActionName.DashboardChartOrdersPerState),
                                loading: false,
                                states: undefined,
                            }
                        },
                    },
                    sales: {
                        canShow: AuthUtils.current().authorized(TawreedActionName.DashboardCardsSales),
                        loading: true,
                        sales: undefined,
                    },
                    balance: {
                        canShow: AuthUtils.current().authorized(TawreedActionName.DashboardCardBalance),
                        loading: true,
                        balance: undefined,
                    },
                    partners: {
                        counts: {
                            canShow: AuthUtils.current().authorized(TawreedActionName.DashboardCardsPartners),
                            loading: true,
                            partners: undefined,
                        },
                        charts: {
                            canShow: AuthUtils.current().authorized(TawreedActionName.DashboardChartPartners),
                            loading: false,
                            partners: undefined,
                        },
                    },
                };

                setState(next);
                if (next.orders.counts.canShow) {
                    service.getOrdersCount()
                        .then(res => setState(previous => {
                            return {
                                ...previous,
                                orders: {
                                    ...previous.orders,
                                    counts: {
                                        ...previous.orders.counts,
                                        loading: false,
                                        value: previous.orders.counts.canShow ? res : undefined,
                                    },
                                }
                            }
                        }))
                        .catch(() => {
                            setState(previous => {
                                return {
                                    ...previous,
                                    orders: {
                                        ...previous.orders,
                                        counts: {
                                            ...previous.orders.counts,
                                            loading: false,
                                        }
                                    }
                                }
                            })
                        });
                }
                if (next.orders.charts.counts.canShow) {
                    service.getOrdersChartsCounts()
                        .then(res => setState(previous => {
                            return {
                                ...previous,
                                orders: {
                                    ...previous.orders,
                                    charts: {
                                        ...previous.orders.charts,
                                        counts: {
                                            ...previous.orders.charts.counts,
                                            loading: false,
                                            value: previous.orders.counts.canShow ? res : undefined,
                                        },
                                    },
                                }
                            }
                        }))
                        .catch(() => {
                            setState(previous => {
                                return {
                                    ...previous,
                                    orders: {
                                        ...previous.orders,
                                        counts: {
                                            ...previous.orders.counts,
                                            loading: false,
                                        }
                                    }
                                }
                            })
                        });
                }
                if (next.sales.canShow) {
                    service.getSalesCount(user?.scale)
                        .then(res => setState(previous => {
                            return {
                                ...previous,
                                sales: {
                                    ...previous.sales,
                                    loading: false,
                                    sales: res,
                                },
                            }
                        }))
                        .catch(() => {
                            setState(previous => {
                                return {
                                    ...previous,
                                    sales: {
                                        ...previous.sales,
                                        loading: false,
                                    },
                                }
                            })
                        });
                }
                if (next.balance.canShow) {
                    service.getBalance(user?.scale)
                        .then(res => setState(previous => {
                            return {
                                ...previous,
                                balance: {
                                    ...previous.balance,
                                    loading: false,
                                    balance: res,
                                },
                            }
                        }))
                        .catch(() => {
                            setState(previous => {
                                return {
                                    ...previous,
                                    balance: {
                                        ...previous.balance,
                                        loading: false,
                                    },
                                }
                            });
                        });
                }
                if (next.partners.counts.canShow) {
                    service.getPartnersCounts()
                        .then(res => setState(previous => {
                            return {
                                ...previous,
                                partners: {
                                    ...previous.partners,
                                    counts: {
                                        ...previous.partners.counts,
                                        loading: false,
                                        partners: res,
                                    }
                                },
                            }
                        })).catch(() => {
                            setState(previous => {
                                return {
                                    ...previous,
                                    partners: {
                                        ...previous.partners,
                                        counts: {
                                            ...previous.partners.counts,
                                            loading: false,
                                        }
                                    },
                                }
                            });
                        });
                }
            }
            return () => { mounted = false; }
        },
        [service]);

    const valueCalculator = (e: DashboardItemValue): string => {
        if (e === undefined || e == null) {
            return '';
        } else if (typeof e === 'number') {
            return e.toString();
        } else if (typeof e === 'string') {
            return e;
        } else {
            if (constants && user) {
                return e(constants, user).toString();
            }
            return '';
        }
    }

    const routeCalculator = (e: DashboardItemRoute): string => {
        if (e === undefined || e == null) {
            return '';
        } else if (typeof e === 'string') {
            return e.toString();
        } else {
            if (constants && user) {
                return e(constants, user);
            }
            return Routes.Dashboard;
        }
    }

    return (
        <div className="grid">
            <div className="col-12">
                <h3>{tawreedTranslationService.translate("lbl_dashboard")}</h3>
            </div>
            <div className="col-12">
                <div className="grid">
                    {
                        //  orders | counts | placed
                        state.orders.counts.canShow.placed &&
                        <div className="col-12 lg:col-6 xl:col-3">
                            <DashboardCard loading={state.orders.counts.loading} valueCalculator={valueCalculator} routeCalculator={routeCalculator} item={state.orders.counts.value?.placed} />
                        </div>
                    }
                    {
                        // orders | counts | processing
                        state.orders.counts.canShow.processing &&
                        <div className="col-12 lg:col-6 xl:col-3">
                            <DashboardCard loading={state.orders.counts.loading} valueCalculator={valueCalculator} routeCalculator={routeCalculator} item={state.orders.counts.value?.processing} />
                        </div>
                    }
                    {
                        // orders | counts | shipped
                        state.orders.counts.canShow.shipped &&
                        <div className="col-12 lg:col-6 xl:col-3">
                            <DashboardCard loading={state.orders.counts.loading} valueCalculator={valueCalculator} routeCalculator={routeCalculator} item={state.orders.counts.value?.shipped} />
                        </div>
                    }
                    {
                        // orders | counts | shipped
                        state.orders.counts.canShow.readyForDelivery &&
                        <div className="col-12 lg:col-6 xl:col-3">
                            <DashboardCard loading={state.orders.counts.loading} valueCalculator={valueCalculator} routeCalculator={routeCalculator} item={state.orders.counts.value?.readyForDelivery} />
                        </div>
                    }

                    {
                        state.partners.counts.canShow &&
                        <React.Fragment>
                            {/* partners | counts | total */}
                            <div className="col-12 lg:col-6 xl:col-3">
                                <DashboardCard loading={state.partners.counts.loading} valueCalculator={valueCalculator} routeCalculator={routeCalculator} item={state.partners.counts.partners} />
                            </div>
                        </React.Fragment>
                    }
                    {
                        state.sales.canShow &&
                        <React.Fragment>
                            {/* sales | total */}
                            <div className="col-12 lg:col-6 xl:col-3">
                                <DashboardCard loading={state.sales.loading} valueCalculator={valueCalculator} routeCalculator={routeCalculator} item={state.sales.sales} />
                            </div>
                        </React.Fragment>
                    }
                    {
                        state.balance.canShow &&
                        <React.Fragment>
                            {/* balance | credit */}
                            <div className="col-12 lg:col-6 xl:col-3">
                                <DashboardCard loading={state.balance.loading} valueCalculator={valueCalculator} routeCalculator={routeCalculator} item={state.balance.balance?.totalCredit} />
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>

            {
                state.orders.charts.counts.canShow &&
                <React.Fragment>
                    {/* orders | chart */}
                    <div className="col-12 md:col-6">
                        <DashboardOrdersChart className="" loading={state.orders.charts.counts.loading} counts={state.orders.charts.counts.value} calculator={valueCalculator} />
                    </div>
                </React.Fragment>
            }
            {
                state.partners.charts.canShow &&
                <React.Fragment>
                    <div className="col-12 md:col-6">
                        <DashboardPartnersChart className="" loading={state.partners.charts.loading} dto={state.partners.charts.partners} calculator={valueCalculator} onSearch={onSearchPartners} />
                    </div>
                </React.Fragment>
            }
            {
                state.orders.charts.totals.canShow &&
                <React.Fragment>
                    {/* orders | totals */}
                    <div className="col-12">
                        <DashboardOrdersTotalChart className="" loading={state.orders.charts.totals.loading} dto={state.orders.charts.totals.totals} calculator={valueCalculator} onSearch={onSearchOrders} />
                    </div>
                </React.Fragment>
            }
            {
                state.orders.charts.zones.canShow &&
                <React.Fragment>
                    {/* orders | zones */}
                    <div className="col-12">
                        <DashboardOrdersPerZoneChart isState={false} className="" loading={state.orders.charts.zones.loading} dto={state.orders.charts.zones.zones} calculator={valueCalculator} onSearch={onSearchZones} />
                    </div>
                </React.Fragment>
            }
            {
                state.orders.charts.states.canShow &&
                <React.Fragment>
                    {/* orders | zones */}
                    <div className="col-12">
                        <DashboardOrdersPerZoneChart isState={true} className="" loading={state.orders.charts.states.loading} dto={state.orders.charts.states.states} calculator={valueCalculator} onSearch={onSearchStates} />
                    </div>
                </React.Fragment>
            }


        </div>
    )
}
