import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { setFocus, TawreedFormField, TawreedInputNumber } from "../../../../../common/components/form";
import { CartService } from '../../domain';
import { CssUtils } from '../../../../../helpers/css';
import { Panel } from 'primereact/panel';
import { ObjectUtils } from '../../../../../helpers/object';
import { AuthUtils } from '../../../../auth/domain';
import { StoreProduct } from '../../../../catalog/store-products/data';

import './cart-form.scss';
import { DateUtils } from '../../../../../helpers/date';
import { IBonusOperations, IBonusType } from '../../../../constants';
import { PriceUtils } from '../../../../../helpers/price';
import { GlobalContext } from '../../../../../context';

export interface QuantityDialogProps {
    visible: boolean;
    storeProduct: StoreProduct;
    customerId: number;

    hide: () => void;

    accept: () => void;
    changeQuantity: (quantity: number, bonusType: string) => void;
}

export const QuantityDialog: FC<QuantityDialogProps> = ({ visible, hide, accept, customerId ,storeProduct, changeQuantity }) => {

    const service: CartService = React.useMemo(() => new CartService(), []);
    const [details, setDetails] = useState<any>({})
    const [quantity, setQuantity] = useState<number>(storeProduct.minimumOrderQuantity ?? 1)
    const [loader, setLoader] = useState<boolean>(false);
    const [bonusType, setBonusType] = useState(IBonusType.EXTRA_ITEMS);
    const [imageUrl, setImageUrl] = useState(storeProduct.imageUrl);
    const [sellingPrice, setSellingPrice] = useState<number>(0);
    const [priceLoader, setPriceLoader] = useState<boolean>(false);
    const { auth: { user } } = React.useContext(GlobalContext);
    useEffect(() => {
        let mounted = true;
        if (mounted && visible) {
            setFocus('quantity', true);
        }
        return () => { mounted = false; };
    }, [visible]);

    useEffect(() => {
        let time = setTimeout(() => {
            getDetails();
        }, 500)

        return () => clearTimeout(time);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity])
    const priceUtils: PriceUtils=  new PriceUtils();
    useEffect(()=>{
        if(storeProduct)
            {
                setPriceLoader(true);
                priceUtils.getSellingPrice(storeProduct.price ?? 0, storeProduct.discountType ?? 'fixed', storeProduct.discountValue ?? 0, user?.scale!)
                .then((res)=>{
                    setPriceLoader(false);
                setSellingPrice(res);
                }).catch((res)=>{
                    setPriceLoader(false);
                });
            }
    },[storeProduct]);

    const getDetails = () => {
        setLoader(true);
        if (storeProduct.storeProductId) {

            service.getItemDetails({ customerId: customerId, 
                quantity: quantity, storeProductId: storeProduct.storeProductId,
                priceListId: storeProduct.priceListId }).then(res => {
                setDetails(res);
                setLoader(false);
                changeQuantity(quantity, bonusType);
            }).catch((res)=>{
                setLoader(false);
            });
        }
    }




    const BonusDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" />
            <Button onClick={() => accept()} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" />
        </div>);
    }
    const getBox = (discount: string) => {
        if (discount === 'fixed')
            return "JOD"
        if (discount === 'percentage')
            return "%"

        return "%+"
    }

    return (

        <Dialog style={{ maxWidth: '512px', minWidth: '55%' }} header={tawreedTranslationService.translate('lbl_add_to_cart')} visible={visible} footer={<BonusDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <div className="flex flex-row">
                {imageUrl &&
                    <img onError={() => {
                        let img = imageUrl.split("/products")[0] + "/categories/" + storeProduct?.categoryId?.toString() ?? "";
                        if (imageUrl.indexOf("categories") === -1)
                            setImageUrl(img)
                    }} src={imageUrl} width={'30%'} height={'30%'} alt='' />

                    // <div className="card col-4 mt-2  image-card " style={{ textAlign: 'left', backgroundImage: `url(${options.imageUrl})` }}>

                    // </div>
                }

                <div className="col-8 md:col-8">
                    <Panel header={storeProduct.store.partnerName}>
                        <div className="grid">
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_product')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {AuthUtils.current().language === 'en' ? storeProduct.product.productNameEn : storeProduct.product.productName}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_store_product_expire_date')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {storeProduct.expireDate && DateUtils.format(storeProduct.expireDate, 'date')}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_price_scheme_price_list_name')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {storeProduct.priceListName}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_price')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(storeProduct.price, user?.scale)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_discount')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {ObjectUtils.toDiscount(storeProduct.discountType!) + ObjectUtils.toLocalString(storeProduct.discountValue, user?.scale)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_sellingPrice')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(sellingPrice, user?.scale)}
                            </div>

                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_quantity')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {storeProduct.quantity ?? ""}
                            </div>

                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_min_order_qty')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {storeProduct.minimumOrderQuantity ?? ""}
                            </div>


                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_max_order_qty')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {storeProduct.maximumOrderQuantity ?? ""}
                            </div>


                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_tax')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'% ' + ObjectUtils.toLocalString(storeProduct.tax, user?.scale)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_bonus')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {
                                    storeProduct.bonuses !== undefined &&
                                    storeProduct.bonuses.length > 0 &&
                                    <React.Fragment>
                                        <div className="bonuses">
                                            {storeProduct.bonuses.map((e) => {
                                                if (e.bonusType === IBonusType.EXTRA_ITEMS)
                                                    return <div className="bonuses-item">{e.quantity} + {e.bonus}</div>
                                                return <></>
                                            })
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_Discounts')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {
                                    storeProduct.bonuses !== undefined &&
                                    storeProduct.bonuses.length > 0 &&
                                    <React.Fragment>
                                        <div className="bonuses">
                                            {storeProduct.bonuses.map((e) => {
                                                if (e.bonusType === IBonusType.DISCOUNT)
                                                    return <div className="bonuses-item">{e.quantity}{e.bonusOperation === IBonusOperations.GREATER_THAN ? " < " : " * "}  {e.bonus} {getBox(e.discountType!)}</div>
                                                return <></>
                                            })
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </Panel>
                </div>


            </div>




            {visible &&


                <React.Fragment>
                    <TawreedFormField name="quantity" title="lbl_store_products_bonus_qty" className="field col-12 md:col-12">
                        <span>
                            {loader && <i className="pi pi-spinner pi-spin ml-2"></i>}
                            <TawreedInputNumber
                                autoFocus allowEmpty={false} min={1} value={quantity} name="quantity" rules={{ required: "msg_field_is_required" }} render="form"
                                onChange={(e) => {
                                    console.log(e);
                                    if (!(e && e.value && (e.value <= 0)))
                                        setQuantity(e.value || 0);
                                    else
                                        setQuantity(1);
                                }}
                            />

                        </span>

                    </TawreedFormField>
                </React.Fragment>}
            <hr></hr>
            <div className='grid'>
                <div className='col-6' onClick={() => { setBonusType(IBonusType.EXTRA_ITEMS); changeQuantity(quantity, IBonusType.EXTRA_ITEMS) }}>
                    <Panel style={{ cursor: 'pointer' }} className={bonusType === IBonusType.EXTRA_ITEMS ? 'selectedBonus' : ''} header={tawreedTranslationService.translate('lbl_sales_cart_summary_bonus')}>
                        <div className="grid" >

                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_sub_total')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(details.sellingPrice * details.quantity, user?.scale)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_total_tax')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(details.itemTotalTax, user?.scale)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_bonus')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {details?.availableBonus?.some((el: any) => el.bonusType === IBonusType.EXTRA_ITEMS) ? details?.availableBonus?.find((el: any) => el.bonusType === IBonusType.EXTRA_ITEMS).bonus : 0}
                            </div>
                            <div className="col-12"><hr></hr></div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_total')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(details.sellingPrice * details.quantity + details.itemTotalTax, user?.scale)}
                            </div>

                        </div>
                    </Panel>
                </div>
                <div className='col-6' onClick={() => { setBonusType(IBonusType.DISCOUNT); changeQuantity(quantity, IBonusType.DISCOUNT) }}>
                    <Panel style={{ cursor: 'pointer' }} className={bonusType === IBonusType.DISCOUNT ? 'selectedBonus' : ''} header={tawreedTranslationService.translate('lbl_sales_cart_summary_discount')}>
                        <div className="grid" >

                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_sub_total')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(details.sellingPrice * details.quantity, user?.scale)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_total_tax')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(details.itemTotalTaxUsingOffers, user?.scale)}
                            </div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_additional_discount')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(details?.availableBonus?.some((el: any) => el.bonusType === IBonusType.DISCOUNT) ? details?.availableBonus?.find((el: any) => el.bonusType === IBonusType.DISCOUNT).bonus : 0, user?.scale)}
                            </div>
                            <div className="col-12"><hr></hr></div>
                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_total')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {'JOD ' + ObjectUtils.toLocalString(details.itemTotalAfterBonusDiscount, user?.scale)}
                            </div>

                        </div>
                    </Panel>
                </div>
            </div>



        </Dialog>
    )
}
