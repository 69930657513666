import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { WindowUtils } from "../../../../helpers/window";
import { DashboardRepository } from "../../data";
import { FinancePartnerDto, PartnersChartDto } from "../dtos/dashboard";

export class DashboardService {
    private readonly repository = new DashboardRepository();

    public getFinanceReportForPartner(type:number): Promise<PartnersChartDto> {
        return this.repository.getFinanceReportForPartner(type);
    }
    public print(reportUrl: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                WindowUtils.print(reportUrl);
                resolve(true);
            } catch {
                reject();
            }
        });
    };
    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<FinancePartnerDto>> {
        return this.repository.search(request);
    }
}