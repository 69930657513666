import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { TawreedAction, TawreedActionButton, TawreedActionName } from "../../../../../common/actions";
import { TawreedCalendar, TawreedCheckbox, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputSwitch, TawreedInputText, TawreedInputTextarea } from "../../../../../common/components/form";
import { tawreedTranslationService } from "../../../../../common/translations";
import { PartnerAutoCompleteComponent } from "../../../../partners";
import { PartnerType } from "../../../../partners/data";
import { Routes } from "../../../../../router";
import { ProductAutoCompleteComponent } from "../../../products/presentaion/components";
import { StoreProductDto, StoreProductsService, priceListDto, PriceSchemeService, priceSchemeDto } from "../../domain";
import { PriceListServcie } from "../../domain/services/price-list.service";
import { PriceListForm } from "./price-list-form";
import { toastService } from "../../../../notifications/domain";
import { GlobalContext } from "../../../../../context";
import { ProductDto, ProductsService } from "../../../products/domain";
import { CommissionService } from "../../../../partners/domain";
import { resolve } from "path";
import { ProductDialog } from "./product-dialog";
import { Button } from "primereact/button";
import { AuthUtils } from "../../../../auth/domain";
import { QuantityDialog } from "./quantity-dialog";
import { JObject } from "../../../../../common/data/models";
import { IStockType } from "../../../../constants";
import { PriceUtils } from "../../../../../helpers/price";
import { StockHistoryDialog } from "./stock-history-dialog";
import { BasicInfoForm } from "./basic-info-form";
import { Steps } from "primereact/steps";
import { MenuItem } from "primereact/menuitem";
import { Toolbar } from "primereact/toolbar";
import { QuantityInfoForm } from "./quantity-info-form";

export const StoreProductForm: FC = (_props) => {
    const { id } = useParams<{ id: string | undefined }>();
    const { pid } = useParams<{ pid: string | undefined }>();
    const [popUp, setPopUp] = useState(false);

    const defaultRef = useRef(null);
    const creditRef = useRef(null);
    const ref = useRef<{ submit: () => void }>();

    const [activeIndex, setActiveIndex] = useState(0);
    const [array, setArray] = useState<priceSchemeDto[]>([]);
    // let array: any[] = [];

    // di
    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);
    const productService: ProductsService = React.useMemo(() => new ProductsService(), []);
    const priceListService: PriceListServcie = useMemo(() => new PriceListServcie(), []);

    const priceSchemeService: PriceSchemeService = useMemo(() => new PriceSchemeService(), []);
    const commissionService: CommissionService = useMemo(() => new CommissionService(), []);
    const [quantityDialog, setQuantityDialog] = useState<{ isOpen: boolean, quantity?: number, isEnable: boolean }>({ isOpen: false, quantity: undefined, isEnable: false, })
    const [historyQuantityDialog, setHistoryQuantityDialog] = useState<boolean>(false);
    const [product, setProduct] = React.useState<ProductDto>({});
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);


    const [loading, setLoading] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>("Create");
    const [defaultMode, setDefaultMode] = useState("Create");
    const [creditMode, setCreditMode] = useState("Create");
    const [data, setData] = useState<StoreProductDto>({
        active: true,
    });
    const [activeQuantity, setActiveQuantity] = useState({
        quantity: false,
        minqty: false,
        maxqty: false,
    });
    const [priceLists, setPriceLists] = useState<Array<priceListDto>>([]);

    const history = useHistory();
    useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode("Edit");
                setLoading(true);
                setCreditMode("View");
                setDefaultMode("View");
            }
            service
                .getDetails(Number.parseInt(id))
                .then((res) => {
                    if (mounted) {


                        setData(res);
                        productService.getDetails(res.product?.productId!)
                            .then(res2 => {
                                setProduct(res2);
                            })
                            .catch(() => {
                            })
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                });

            priceListService.getAll().then((res) => {
                setPriceLists(res);
                var newArray: priceSchemeDto[] = [];
                res.forEach(price => {
                    priceSchemeService.getDetails(price.priceListId, Number.parseInt(id))
                        .then(res => {
                            const idx = array.findIndex((el) => el.priceListId === price.priceListId);
                            var item = {};
                            if (res.priceSchemeId) {

                                item = {
                                    ...res, price: res.price ?? data.price,
                                    priceListId: price.priceListId, storeProductId: Number.parseInt(id)
                                };
                            }
                            else {
                                item = {
                                    ...res, price: res.price ?? data.price, discountValue: 0, discountType: "fixed", bonuses: [],
                                    priceListId: price.priceListId, storeProductId: Number.parseInt(id)
                                };
                            }

                            if (idx >= 0) {
                                newArray[idx] = item;
                            }
                            else
                                newArray.push(item);

                            setLoading(false);
                            setArray([...newArray]);
                        }).catch(err => {
                            setLoading(false);
                        });
                });
            });

        } else {
            if (mounted) {
                setMode("Create");
                setCreditMode("Create");
                setDefaultMode("Create");
                priceListService.getAll().then((res) => {
                    setPriceLists(res);
                    var newArray: priceSchemeDto[] = [];
                    res.forEach(price => {

                        var item = {
                            bonuses: [],
                            priceListId: price.priceListId, storeProductId: -1
                        };

                        newArray.push(item);
                        setArray([...newArray]);
                    });
                });
            }
            if (pid) {
                setLoading(true);
                productService.getDetails(Number.parseInt(pid))
                    .then(res2 => {
                        setProduct(res2);
                        setData({
                            ...data,
                            product: res2,
                            titleAr: res2.productName,
                            titleEn: res2.productNameEn,
                            tax: res2.productTax,
                            price: res2.price,
                            storeItemId: res2.internationalCode
                        })
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    })
            }
        }
        //setData({ ...data, store: { partnerId: user!.partnerId } })
        if (user?.roleCode === 'STORE_ADMIN')
            setData({ ...data, store: { partnerId: user.partnerId } })
        return () => {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, service, priceListService,pid]);

    const updateQuantity = async (data: JObject) => {
        setLoading(true);
        if (!data.type)
            data.type = IStockType.Inbound
        await service.updateQuantity({ ...data, storeProductId: +id! }).then((res) => {
            setLoading(false);
            setReload(!reload)
        }).catch(err => setLoading(false))

    }
    const priceUtils: PriceUtils =  new PriceUtils();
    const onCreateOrSave = async (datas: any, cb: any): Promise<void> => {
        // //@ts-ignore
        // defaultRef.current.click();
        // //@ts-ignore
        // creditRef.current.click();
        let ok = false;
        array.forEach((el) => {
            if (el.price && el.price > 0 && el.discountType && el.discountValue! >= 0)
                ok = true;
        })

        if (!ok) {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_price_list_error'), severity: 'error' });
            return new Promise<void>((resolve, reject) => {
                reject();
            });
        }
        ok = true;
        // for await (const item of array) {
        //     if (item.sellingPrice<=0) {
        //         toastService.show({ detail: tawreedTranslationService.translate('lbl_selling_price_error'), severity: 'error' });
        //             return new Promise<void>((resolve, reject) => {
        //                 reject();
        //             });
        //     }
        // }
        setLoading(true);

        // for await (const item of array) {
        //     if (item.price && item.price > 0) {
        //         const sellingPrice =await priceUtils.getSellingPrice(item.price ?? 0, item.discountType ?? 'fixed', item.discountValue ?? 0, user?.scale!) 
                
        //         const result = await commissionService.checkPrice({ partnerId: data.store?.partnerId, priceListId: item.priceListId, value: sellingPrice });
        //         if (!result)
        //             ok = false;
        //     }
                
        // }
        for await (const item of array) {
            if (item.price && item.price > 0) {
                const sellingPrice =await priceUtils.getSellingPrice(item.price ?? 0, item.discountType ?? 'fixed', item.discountValue ?? 0, user?.scale!) 
                if (sellingPrice <= 0) {
                    toastService.show({ detail: tawreedTranslationService.translate('lbl_selling_price_error'), severity: 'error' });
                    return new Promise<void>((resolve, reject) => {
                        setLoading(false);
                        reject();
                    });
                }

                else {
                    const result = await commissionService.checkPrice({ partnerId: data.store?.partnerId, priceListId: item.priceListId, value: sellingPrice });
                    if (!result)
                        ok = false;
                }
            }
        }
        if (!ok) {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_price_list_commission_error'), severity: 'error' });
            return new Promise<void>((resolve, reject) => {
                setLoading(false);
                reject();
            });

        }
        return service
        .createOrUpdate(data)
        .then(async (res) => {
            for await (const priceSchemeDto of array) {
                if (priceSchemeDto.price! > 0 && priceSchemeDto.discountType && priceSchemeDto.discountValue! >= 0)
                    await priceSchemeService.createOrUpdate({ ...priceSchemeDto, storeProductId: res.storeProductId })
            }
            setLoading(false);
            if (!data.storeProductId && res.storeProductId) {
                history.replace(Routes.StoreProductsDetails.replace(":id", res.storeProductId.toString()));
            }
        })
        .catch((err) => {
            setLoading(false);
        });
    };


    const onSubmit: TawreedFormSubmitProps<StoreProductDto> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<StoreProductDto>(TawreedActionName.StoreProductsSubmit, "lbl_save", PrimeIcons.SAVE, onCreateOrSave),
    };


    const onDelete = () => {
        setLoading(true);
        return service
            .delete(data.storeProductId!)
            .then((res) => {
                setLoading(false);
                if (res) {
                    history.replace(Routes.StoreProductsSearch);
                }
            })
            .catch((err) => {
                setLoading(false);
                throw err;
            });
    };
    const onBack = () => {
        return new Promise<void>((res, rej) => {
            setActiveIndex(activeIndex - 1);
            res()
        })
    }
    const onBackToCatalog =()=>{
        return new Promise<void>((res, rej) => {
        history.goBack();
        });
    }
    const onNext = () => {
        return new Promise<void>((res, rej) => {
            if (activeIndex == 0) {
                if (mode == 'Create')
                    ref.current?.submit();  
                else
                    setActiveIndex(activeIndex+1);
            }
            else{
            setActiveIndex(activeIndex+1);
            }
            res();
        })
    }
    const createNew = () => {
        return new Promise<void>((res, rej) => {
            history.push(Routes.StoreProductsNew)
            res()
        })
    }
    const itemRenderer = (item: JObject, itemIndex: number) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

        return (
            <React.Fragment>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span
                        className="inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 h-3rem w-3rem z-1 cursor-pointer"
                        style={{ backgroundColor: backgroundColor, color: textColor, marginTop: '0px' }}
                        onClick={() => {

                            if (!isActiveItem) {
                                if (activeIndex == 0) {
                                    if (mode == 'Create')
                                        ref.current?.submit();
                                    else
                                        setActiveIndex(itemIndex);
                                }
                                else{
                                    setActiveIndex(itemIndex);
                                }
                            }
                        }}
                    >
                        {mode === 'Edit' ? <i className={`${item.icon} text-xl`} /> : itemIndex + 1}

                    </span>
                    <p className='mt-2' style={{ fontWeight: activeIndex === itemIndex ? 700 : 400 }}>{
                        itemIndex === 0 ?
                            `${tawreedTranslationService.translate("lbl_general_information")}` :
                            itemIndex === 1 ?
                            `${tawreedTranslationService.translate("lbl_quantity_trackin")}` :
                            `${tawreedTranslationService.translate('lbl_price_scheme_price_list_name')}`
                    }</p>
                </div>

            </React.Fragment>
        );
    };
    const saveProduct = (storeProduct: StoreProductDto) => {
        setData({ ...storeProduct });
        setActiveIndex(1);
    }
    const saveProductWithoutChange = (storeProduct: StoreProductDto) => {
        setData({ ...storeProduct });
    }
    const items: MenuItem[] = [
        {
            icon: 'pi pi-check-circle',
            template: (item) => itemRenderer(item, 0),


        },
        {
            icon: 'pi pi-plus',
            template: (item) => itemRenderer(item, 1)
        },
        {
            icon: 'pi pi-dollar',
            template: (item) => itemRenderer(item, 2)
        },
    ];
    var actions = [
        new TawreedAction(TawreedActionName.Back,  "statefull", "lbl_back_to_catalog", PrimeIcons.ARROW_LEFT, onBackToCatalog, "p-button-outlined ml-1")
    ];
    if (id) {
        if(AuthUtils.current().authorized(TawreedActionName.StoreProductsDelete))
        {
            actions.push(new TawreedAction(TawreedActionName.StoreProductsDelete, "statefull", "lbl_delete", PrimeIcons.TRASH, onDelete, "p-button-danger p-button-outlined ml-1", "confirmDelete"));
        }
    }
    else {
        if (activeIndex === 2) {
            actions.push(new TawreedAction(TawreedActionName.StoreProductsSubmit, "statefull", "lbl_save", PrimeIcons.PLUS, onCreateOrSave, "p-button-outlined ml-1"));
        }
    }
    
    if (activeIndex !== 0) {
        actions.push(new TawreedAction(TawreedActionName.StoreProductsDelete, "statefull", "lbl_previous", PrimeIcons.CHEVRON_LEFT, onBack, "p-button-secondary p-button-outlined ml-1",undefined));
    }
    if (activeIndex !== 2) {
        actions.push(new TawreedAction(TawreedActionName.StoreProductsSubmit, "statefull", "lbl_next", PrimeIcons.CHEVRON_RIGHT, onNext, "p-button-secondary p-button-outlined ml-1"));
    }
    // const actions = [new TawreedAction(TawreedActionName.StoreProductsDelete, "statefull", "lbl_delete", PrimeIcons.TRASH, onDelete, "p-button-danger", "confirmDelete")];
    // if (id && actions.length === 1) {
    //     actions.push(new TawreedAction(TawreedActionName.StoreProductsSubmit, "statefull", "lbl_new", PrimeIcons.PLUS, createNew))
    // }
    return (
        <React.Fragment>
 <Toolbar className="form-tawreed-toolbar"
                left={
                    <React.Fragment>
                        <span></span>
                        <h4>{data.titleEn ? (AuthUtils.current().language === 'en' ? data.titleEn : data.titleAr) : tawreedTranslationService.translate("lbl_store_product_new")}</h4>
                    </React.Fragment>
                }
                right={
                    <React.Fragment>
                        <div className="md:flex">
                            {
                                actions.map(((e, index) => <TawreedActionButton key={index} type="button" action={e} />))
                            }
                        </div>
                    </React.Fragment>
                } />
{/* <Panel header={tawreedTranslationService.translate("lbl_general_information")} className="col-12">
                    <div className="grid">

                        {user?.roleCode !== 'STORE_ADMIN' &&
                            <React.Fragment>
                                <TawreedFormField name="store" title="lbl_store_name" className="field col-12 md:col-6">
                                    <PartnerAutoCompleteComponent
                                        partnerType={PartnerType.Store}
                                        autoCompleteName="store"
                                        autoCompleteField="partnerName"
                                        autoCompleteRules={{ required: "msg_field_is_required" }}
                                        autoCompleteDisabled={data.storeProductId != null && true}
                                        autoCompleteOnSelect={(e) => {
                                            setData({ ...data, store: e.value });
                                        }}
                                        autoCompleteRender="form" />
                                </TawreedFormField>
                                <TawreedFormField name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                                    <TawreedInputSwitch name="active" render="form" />
                                </TawreedFormField>
                            </React.Fragment>

                        }
                        <React.Fragment>
                            <TawreedFormField name="product" title="lbl_store_products_product_id" className="field col-12 md:col-6">
                                <React.Fragment>
                                    <ProductAutoCompleteComponent
                                        storeId={data.store?.partnerId}
                                        autoCompleteName="product"
                                        autoCompleteRules={{ required: "msg_field_is_required" }}
                                        autoCompleteDisabled={data.store ? (data.storeProductId != null && true) : true}
                                        autoCompleteRender="form"
                                        autoCompleteOnSelect={e => {
                                            const p = e.value as ProductDto;
                                            console.log("ProductDto", p);
                                            if (p) {
                                                setData({
                                                    ...data,
                                                    storeItemId: p.internationalCode,
                                                    product: p,
                                                    titleAr: p.displayName,
                                                    titleEn: p.displayName,
                                                    tax: p.productTax,
                                                    price: p.price,
                                                });
                                            } else {
                                                setData({
                                                    ...data,
                                                    product: undefined,
                                                    titleAr: undefined,
                                                    titleEn: undefined,
                                                    tax: undefined,
                                                    price: undefined,
                                                    storeItemId: undefined,
                                                });
                                            }
                                        }} />
                                    <div className="p-2 font-bold">
                                        {
                                            data.storeProductId == null &&
                                            <NavLink to={"#"} onClick={() => setPopUp(true)}>{tawreedTranslationService.translate("lbl_store_product_browse")}</NavLink>

                                        }

                                    </div>
                                </React.Fragment>
                            </TawreedFormField>
                        </React.Fragment>
                        {user?.roleCode === "STORE_ADMIN" &&
                            <TawreedFormField name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                                <TawreedInputSwitch name="active" render="form" />
                            </TawreedFormField>}
                        <TawreedFormField name="storeItemId" title="lbl_store_product_item_id" className="field col-12 md:col-6">
                            <React.Fragment>
                                <TawreedInputText placeholder="" name="storeItemId" rules={{ required: "msg_field_is_required" }} render="form" />
                                <p >{tawreedTranslationService.translate("lbl_store_product_store_hint")}</p>

                            </React.Fragment>
                        </TawreedFormField>

                        <TawreedFormField name="expireDate" title="lbl_store_product_expire_date" className="field col-12 md:col-6">
                            <TawreedCalendar minDate={new Date()} render="form" name="expireDate" rules={data.product?.categoryId === 1 ? { required: 'msg_field_is_required' } : {}} />
                        </TawreedFormField>

                        <TawreedFormField name="tax" title="lbl_store_products_tax" className="field col-12 md:col-6">
                            <TawreedInputNumber suffix={" %"} mode={"decimal"} name="tax" rules={{ required: "msg_field_is_required" }} render="form" />
                        </TawreedFormField>

                        <TawreedFormField name="titleAr" title="lbl_store_product_title_ar" className="field col-12 md:col-6">
                            <TawreedInputText name="titleAr" render="form" rules={{
                                required: 'msg_field_is_required',
                                validate: (value) => {
                                    var trim_value = value.trim();
                                    if (trim_value.length == 0) {
                                        return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                    }

                                }
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="titleEn" title="lbl_store_product_title_en" className="field col-12 md:col-6">
                            <TawreedInputText name="titleEn" render="form" rules={{
                                required: 'msg_field_is_required',
                                validate: (value) => {
                                    var trim_value = value.trim();
                                    if (trim_value.length == 0) {
                                        return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                    }

                                }
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="description" title="lbl_store_product_description" className="field col-12 md:col-12">
                            <TawreedInputTextarea name="description" render="form" />
                        </TawreedFormField>
                    </div>
                </Panel> */}
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)}
                 readOnly={false} className="m-2 pt-4" />
            <div>
                {activeIndex === 0 &&
                    <BasicInfoForm
                        id={id}
                        initLoading={loading}
                        baseProduct={product}
                        ref={ref}
                        product={data}
                        mode={mode}
                        accept={saveProduct}
                        saveProduct={saveProductWithoutChange}
                    ></BasicInfoForm>
                }
                {activeIndex === 1 && <Panel>
                    <QuantityInfoForm
                    initloading={loading}
                    mode={mode}
                    id={id}
                    onSaveProductQuantity={(quantityData)=>{
                        setData({
                            ...data,
                            quantity: quantityData.quantity,
                            maximumOrderQuantity: quantityData.maxqty,
                            minimumOrderQuantity: quantityData.minqty
                        })
                    }}
                    productQauntity={{quantity:data.quantity,minqty:data.minimumOrderQuantity,maxqty:data.maximumOrderQuantity}}
                    ></QuantityInfoForm>
                    </Panel>
                }
                {activeIndex === 2 && <Panel>

                    {
                        priceLists.map((el, idx) => {
                            return (
                                <PriceListForm
                                    name={el.priceListName!}
                                    key={idx}
                                    onSavePriceList={(data) => {
                                        const idx = array.findIndex((el) => el.priceListId === data.priceListId)
                                        const newArray = [...array];
                                        if (idx >= 0) {
                                            newArray[idx] = data;
                                        }
                                        else
                                            newArray.push(data);
                                        setArray(newArray);
                                    }}
                                    ref1={idx === 0 ? defaultRef : creditRef}
                                    mode={idx === 0 ? defaultMode : creditMode}
                                    storeProductId={id ? +id : -1}
                                    priceListId={el.priceListId}
                                    price={data.price}
                                    loading={loading}
                                    setMode={(mode) => idx === 0 ? setDefaultMode(mode) : setCreditMode(mode)}
                                    priceScheme={array.filter(x => x.priceListId === el.priceListId).at(0)!}
                                ></PriceListForm>
                            )
                        })
                    }
                </Panel >}
            </div>
            {/* <TawreedForm customeTitle={data.titleEn ? (AuthUtils.current().language === 'en' ? data.titleEn : data.titleAr) + " - " + constants?.productCategories.find(el => el.id === (data.product?.categoryId ? data.product?.categoryId : data.categoryId))?.label : ''} ref={ref} title="lbl_store_product" dataKey="storeProductId" data={data} useReset submit={onSubmit} loading={loading} mode={mode} useBack=".." actions={actions} className="grid form-grid">
                

                <Panel header={tawreedTranslationService.translate("lbl_quantity_trackin")} className="col-12" icons={
                <React.Fragment>
                {id &&     <Button
                            label={tawreedTranslationService.translate("lbl_stock_history")}
                            icon={PrimeIcons.CHART_LINE}
                            type="button" 
                            //loading={loading}
                            className={"p-button-text"}
                            onClick={() => { setHistoryQuantityDialog(true)}} />
                }
                </React.Fragment>
            } >
                    <div className="grid">

                        <TawreedFormField name="quantity" title={"lbl_quantity"} className="field col-12 md:col-9">
                            <TawreedInputNumber disabled={id ? true : (!activeQuantity.quantity)} name="quantity" render="form" onChange={(e) => {
                            }} />
                        </TawreedFormField>
                        {id && AuthUtils.current().authorized(TawreedActionName.StoreProductsSubmit) && <div className="field-checkbox col-12 md:col-3 mt-5">
                            {data.quantity !== null && <Button onClick={() => { setQuantityDialog({ isOpen: true, quantity: data.quantity, isEnable: false }) }} label={tawreedTranslationService.translate("lbl_update")} type="button" className="p-button-sm mr-2" />}

                            <Button onClick={() => {
                                if (data.quantity == null)
                                    setQuantityDialog({ isOpen: true, quantity: data.quantity, isEnable: true })
                                else
                                    updateQuantity({})
                            }
                            } label={tawreedTranslationService.translate(data.quantity == null ? "lbl_enable" : "lbl_disable")} type="button" className={`p-button-sm  ${data.quantity == null ? "" : "p-button-danger"}`} />
                        </div>}
                        {!id && <TawreedFormField name="activeQuantity" title="lbl_product_active" className="field-checkbox col-12 md:col-3 mt-5" flip>
                            <TawreedInputSwitch name="activeQuantity" checked={activeQuantity.quantity} render="standalone" onChange={(e) => { setActiveQuantity({ ...activeQuantity, quantity: e.value }) }} />
                        </TawreedFormField>}
                        <TawreedFormField name="minimumOrderQuantity" title={"lbl_min_order_qty"} className="field col-12 md:col-9">
                            <TawreedInputNumber disabled={!activeQuantity.minqty} name="minimumOrderQuantity" render="form" onChange={(e) => {
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="activeMinQuantity" title="lbl_product_active" className="field-checkbox col-12 md:col-3 mt-5" flip>
                            <TawreedInputSwitch name="activeMinQuantity" render="standalone" checked={activeQuantity.minqty} onChange={(e) => { setActiveQuantity({ ...activeQuantity, minqty: e.value }) }} />
                        </TawreedFormField>
                        <TawreedFormField name="maximumOrderQuantity" title={"lbl_max_order_qty"} className="field col-12 md:col-9">
                            <TawreedInputNumber disabled={!activeQuantity.maxqty} name="maximumOrderQuantity" render="form" onChange={(e) => {
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="activeMaxQuantity" title="lbl_product_active" className="field-checkbox col-12 md:col-3 mt-5" flip>
                            <TawreedInputSwitch name="activeMinQuantity" render="standalone" checked={activeQuantity.maxqty} onChange={(e) => { setActiveQuantity({ ...activeQuantity, maxqty: e.value }) }} />
                        </TawreedFormField>
                    </div>
                </Panel>
            </TawreedForm>
            {data.product && <Panel header={tawreedTranslationService.translate('lbl_price_scheme_price_list_name')} >
                {
                    priceLists.map((el, idx) => {
                        return (

                            <PriceListForm
                                name={el.priceListName!}
                                onSavePriceList={(data) => {
                                    const idx = array.findIndex((el) => el.priceListId === data.priceListId)
                                    if (idx >= 0) {
                                        array[idx] = data;
                                    }
                                    else
                                        array.push(data);
                                }}
                                ref1={idx === 0 ? defaultRef : creditRef}
                                storeProductId={id ? +id : -1}
                                priceListId={el.priceListId}
                                price={data.price}
                                key={idx}
                                loading={loading}
                            ></PriceListForm>

                        )
                    })
                }
            </Panel >} */}
            {popUp && <ProductDialog createCase={false} showCancel={true} visible={popUp} isDiscount={false} hide={() => setPopUp(false)} accept={(selectedData) => {
                setData({
                    ...data,
                    product: selectedData,
                    titleAr: selectedData.productName,
                    titleEn: selectedData.productNameEn,
                    tax: selectedData.productTax,
                    price: selectedData.price,
                    storeItemId: selectedData.internationalCode,
                })
                setPopUp(false);
            }}></ProductDialog>}

            {quantityDialog.isOpen && <QuantityDialog isEnable={quantityDialog.isEnable} accept={(data) => { updateQuantity(data); setQuantityDialog({ isEnable: false, isOpen: false, quantity: undefined }) }} visible={quantityDialog.isOpen} hide={() => setQuantityDialog({ isOpen: false, quantity: undefined, isEnable: false, })}></QuantityDialog>}
            {historyQuantityDialog && <StockHistoryDialog hide={()=> {setHistoryQuantityDialog(false)}}
                storeProductId={id ? Number.parseInt(id): undefined} visible={historyQuantityDialog}
                ></StockHistoryDialog>}
        </React.Fragment >
    );
};
