import { JObject } from "../../../../common/data/models"
import { DocumentDto } from "../../domain"
import { Document, DocumentType } from "../models"
import { PartnerMapper } from "../../../partners/data";
import { DateUtils } from "../../../../helpers/date";
import { DocumentFileMapper } from "./document-file";
import { AccountDtoMapper } from "./account";

export const DocumentMapper = {

    initial: (type: DocumentType): Partial<DocumentDto> => {
        let description = '';
        switch (type) {
            case DocumentType.PAYMENT_VOUCHER:
                description = 'دفعة نقدية';
                break;
            case DocumentType.RECEIPT_VOUCHER:
                description = 'استلام دفعة نقدية';
                break;
            case DocumentType.CREDIT_NOTE:
            case DocumentType.DEBIT_NOTE:
                description = 'تسوية على الحساب';
                break;
        }

        return {
            typeId: type,
            documentDate: new Date(),
            description: description,
        }
    },

    /**
     *
     */
    toJson: (model: Document): JObject => {
        const obj: JObject = {
            partnerId: PartnerMapper.toJson(model.partner).partnerId,
            documentDate: DateUtils.toISO(model.documentDate, 'date', 'end'),
            description: model.description,
            amount: model.amount,
            typeId: model.typeId,
            paymentReference: model.paymentReference,
            paymentMethodId: model.paymentMethodId,
            paymentTransactionId: model.paymentTransactionId,
            documentType: model.documentType,
            documentId: model.documentId,
            descriptionAr: model.descriptionAr,
            platformAccountId: model.platformAccountId

        };
        return obj;
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {Document}
     */
    fromDto: (dto: Partial<DocumentDto>): Document => {

        return { partner: PartnerMapper.fromDto(dto.partner!), documentDate: dto.documentDate!,
             description: dto.description!, amount: dto.amount!, typeId: dto.typeId, paymentReference: dto.paymentReference,
              paymentMethodId: dto.paymentMethodId, descriptionAr: dto.descriptionAr, documentId: dto.documentId,
               documentType: dto.documentType, paymentTransactionId: dto.paymentTransactionId,
               platformAccountId: dto.platformAccount?.balanceId }

    },

    /**
    *
    */
    fromJson: (model: JObject): Document => {
        if (model.documentType === DocumentType.RECEIPT_VOUCHER || model.documentType === DocumentType.PAYMENT_VOUCHER) {
            return {
                partner: {
                    partnerId: model.partnerId,
                    partnerName: model.partnerName
                }, documentDate: DateUtils.fromISO(model.documentDate), description: model.description!,
                amount: model.amount!, typeId: model.documentType, paymentReference: model.paymentReference!,
                paymentMethodId: model.paymentMethodId!,
                files: model.documentFiles!.map((el: JObject) => DocumentFileMapper.toModel(el)),
                descriptionAr: model.descriptionAr,
                documentId: model.documentId,
                documentType: model.documentType,
                paymentTransactionId: model.paymentTransactionId,
                platformAccountId: model.platformAccountId,
                platformAccount: AccountDtoMapper.fromJson(model.secondAccount)
            }
        } else {
            return {
                partner: {
                    partnerId: model.partnerId,
                    partnerName: model.partnerName
                }, documentDate: DateUtils.fromISO(model.documentDate), description: model.description!,
                amount: model.amount!, typeId: model.documentType,
                files: model.documentFiles!.map((el: JObject) => DocumentFileMapper.toModel(el)),
                documentId: model.documentId,
                documentType: model.documentType,
                paymentTransactionId: model.paymentTransactionId,
                descriptionAr: model.descriptionAr,
                paymentMethodId: model.paymentMethodId,
                paymentReference: model.paymentReference
            }
        }
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {Document}
     */
    toDto: (dto: Partial<Document>): DocumentDto => {
        if (dto.typeId === DocumentType.RECEIPT_VOUCHER || dto.typeId === DocumentType.PAYMENT_VOUCHER) {
            return {
                partner: PartnerMapper.toDto(dto.partner!),
                documentDate: dto.documentDate!,
                description: dto.description!,
                amount: dto.amount!,
                typeId: dto.typeId,
                paymentReference: dto.paymentReference!,
                paymentMethodId: dto.paymentMethodId!,
                files: dto.files!.map((el: any) => DocumentFileMapper.toDto(el)),
                descriptionAr: dto.descriptionAr,
                documentId: dto.documentId,
                documentType: dto.documentType,
                paymentTransactionId: dto.paymentTransactionId,
                platformAccountId: dto.platformAccountId,
                platformAccount: dto.platformAccount
            }
        } else {
            return {
                partner: PartnerMapper.toDto(dto.partner!),
                documentDate: dto.documentDate!,
                description: dto.description!,
                amount: dto.amount!,
                typeId: dto.typeId!,
                files: dto.files!.map((el: any) => DocumentFileMapper.toModel(el)),
                documentId: dto.documentId,
                documentType: dto.documentType,
                paymentTransactionId: dto.paymentTransactionId,
                descriptionAr: dto.descriptionAr,
                paymentMethodId: dto.paymentMethodId,
                paymentReference: dto.paymentReference,
            };
        }
    },
}
