import { Partner } from "../../../partners/data";
import { AccountDto } from "../../domain";
import { TransactionDocumentFile } from "./transaction-document-file";

export enum DocumentType {
    PAYMENT_VOUCHER = 1,
    RECEIPT_VOUCHER = 2,
    CREDIT_NOTE = 3,
    DEBIT_NOTE = 4,
    OTHER = 5,
    JOURNAL = 31
}

export type Document =
    {
        partner: Partner; documentDate: Date; description: string; amount: number;
        files?: TransactionDocumentFile[];
        paymentReference?: string;
        paymentMethodId?: number;
        paymentTransactionId?: number;
        documentType?: number;
        documentId?: number;
        descriptionAr?: string;
        typeId?: number;
        platformAccountId?: number,
        platformAccount?: AccountDto,

    }

    export type ManualDocument =
    {
        partner: Partner; documentDate: Date; descriptionEn: string; amount: number;
        files?: TransactionDocumentFile[];
        paymentReference?: string;
        paymentMethodId?: number;
        paymentTransactionId?: number;
        documentType?: number;
        documentId?: number;
        descriptionAr?: string;
        typeId?: number;

        firstAccountId?: number;
        secondAccountId?: number;
        isFirstAccountCredit?: boolean;
        isSecondAccountCredit?: boolean;
        firstAccount?: AccountDto,
        secondAccount?: AccountDto
    }