import { TawreedMenu } from "../../../constants";
import { FileService, TawreedFile } from "../../../upload";
import {DocumentRepository} from "../../data";
import {DocumentDto, ManualDocumentDTo} from "../dtos";
import { TransactionDocumentFileDto, TransactionDocumentFilesDTO } from "../dtos/transaction-document-file";

export class DocumentService {

    private readonly repository: DocumentRepository;
    
    private filesService = new FileService();

    constructor() {
        this.repository = new DocumentRepository();
    }

    /**
     * Create new finance document instance
     * @param document
     */
    public createDocument(document: Partial<DocumentDto>): Promise<number> {
        return this.repository.createDocument(document);
    }
    public createManualDocument(document: Partial<ManualDocumentDTo>): Promise<number> {
        console.log("d",document);
        document = {...document, firstAccountId: document.firstAccount!.balanceId, secondAccountId: document.secondAccount!.balanceId};
        return this.repository.createManualDocument(document);
    }
    public async createFile(file: TawreedFile, title:string, documentId:number): Promise<DocumentDto> {
        const key = await this.filesService.upload(file).then((res) => res);
        var data:TransactionDocumentFilesDTO ={documentId:documentId, files: [{fileId: key,title:title}]}
        return this.repository.uploadTransactionFile(data);

    }
    public deleteFile(id: number): Promise<boolean>{
        return this.repository.deleteTransactionFile(id);
    }

    public getBalance(partnerId: number): Promise<number> {
        return this.repository.getBalance(partnerId);
    }

    public getDetails(documentId: number): Promise<DocumentDto>
    {
        return this.repository.getDetails(documentId);
    }
    public getManualDetails(documentId: number): Promise<ManualDocumentDTo>
    {
        return this.repository.getManualDetails(documentId);
    }
}
