import React,{ FC, ForwardedRef, MutableRefObject, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { TawreedCalendar, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputFile, TawreedInputNumber, TawreedInputPlain, TawreedInputSwitch, TawreedInputText, TawreedInputTextarea } from "../../../../../common";
import { Panel } from "primereact/panel";
import { tawreedTranslationService } from "../../../../../common/translations";
import { ProductAutoCompleteComponent } from "../../../products/presentaion/components";
import { ProductDto } from "../../../products/domain";
import { Link, NavLink } from "react-router-dom";
import { AuthUtils } from "../../../../auth/domain";
import { StoreProductDto, StoreProductsService } from "../../domain";
import { TawreedActionName } from "../../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { ProductDialog } from "./product-dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import { BaseProductInfo } from "./base-product-info";
import { GlobalContext } from "../../../../../context";
import { PartnerAutoCompleteComponent } from "../../../../partners";
import { PartnerType } from "../../../../partners/data";

export interface BasicInfoProps {
    id?:string,
    product: StoreProductDto;
    mode:TawreedFormMode;
    accept: (data:StoreProductDto)=>void;
    saveProduct: (data:StoreProductDto)=>void;
    baseProduct: ProductDto;
    initLoading: boolean;
}
export const BasicInfoForm = forwardRef<any | undefined, BasicInfoProps>(({id,product,mode,accept,baseProduct,saveProduct,initLoading},ref)=>{

    const [loading, setLoading] = useState<boolean>(initLoading);
    const [data, setData] = useState<StoreProductDto>(product);
    const [oldData, setOldData] = useState<StoreProductDto>({
        ...product
    });
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);

    useEffect(()=>{
        setLoading(initLoading);
    },[initLoading])
    
    const [popUp, setPopUp] = useState(false);
    const productRef = React.useRef<OverlayPanel>(null);
    
    const [inlineMode, setInlineMode] = useState<string>(
      mode === 'Create' ? 'Create' : 'View'  
    );
  
      
    useEffect(()=>{
        if(mode === 'Create')
        {
            setInlineMode('Create');
        }
        else
        {
            setInlineMode('View');
        }
    },[mode]);

    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);
    const onCreateOrSave = async (data: StoreProductDto, cb: TawreedFormSubmitCallback<StoreProductDto>): Promise<void> => {
        if(mode ==='Edit'){
            setLoading(true);
            // data.expireDate?.setTime(data.expireDate.getTime() + (3 * 60 * 60 * 1000));
        return service
            .createOrUpdate(data)
            .then(async (res) => {
                setLoading(false);
               setInlineMode('View');
               saveProduct(data);
                cb(res);
            })
            .catch((err) => {
                setLoading(false);
                cb(err);
            });
        }
        else{
            accept(data);
            return;
        }
        
    };
    

    const onSubmit: TawreedFormSubmitProps<StoreProductDto> = {
        showButton: false,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<StoreProductDto>(TawreedActionName.StoreProductsSubmit, "lbl_save", PrimeIcons.SAVE, onCreateOrSave),
    };
    useEffect(()=>{
        setData({...product});
    },[product]);

    return(
        <React.Fragment>
        <TawreedForm
         customeTitle=' '
          ref={ref}
          title="" 
          dataKey="storeProductId" data={data} useReset submit={onSubmit} 
          loading={loading} mode={mode} 
          actions={[]}
          className="grid form-grid">
                    <Panel header={tawreedTranslationService.translate("lbl_general_information")} className="col-12" icons={
                        <React.Fragment>
                        {inlineMode === 'Edit'&&
                        <Button
                        label={tawreedTranslationService.translate("lbl_save")}
                        icon={PrimeIcons.SAVE}
                        loading={loading}
                        onClick={()=>onCreateOrSave}
                        className={"p-button-text"}
                        style={mode==='Create' ? {display:"none"}:{}}
                    />
                    }
                    {inlineMode === 'View' && AuthUtils.current().authorized(TawreedActionName.StoreProductsSubmit) && 
                        <Button
                            label={tawreedTranslationService.translate("lbl_edit")}
                            icon={PrimeIcons.PENCIL}
                            className={"p-button-text"}
                            loading={loading}
                            onClick={()=>{setOldData({...data}); setInlineMode('Edit')}}/>
                    }
                    {inlineMode === 'Edit' && 
                        <Button
                            label={tawreedTranslationService.translate("lbl_cancel")}
                            icon={PrimeIcons.TIMES}
                            loading={loading}
                            className={"p-button-text"}
                            onClick={()=>{setData({...oldData}); setInlineMode('View')}}/>
                    }
                    </React.Fragment>
                    } >
                        <div className="grid">
                        {user?.roleCode !== 'STORE_ADMIN' &&
                            <React.Fragment>
                                <TawreedFormField name="store" title="lbl_store_name" className="field col-12 md:col-12">
                                    <PartnerAutoCompleteComponent
                                        partnerType={PartnerType.Store}
                                        autoCompleteName="store"
                                        autoCompleteField="partnerName"
                                        autoCompleteRules={{ required: "msg_field_is_required" }}
                                        autoCompleteDisabled={data.storeProductId != null && true}
                                        autoCompleteOnSelect={(e) => {
                                            setData({ ...data, store: e.value });
                                        }}
                                        autoCompleteRender="form" />
                                </TawreedFormField>
                                {/* <TawreedFormField name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                                    <TawreedInputSwitch name="active" render="form" />
                                </TawreedFormField> */}
                            </React.Fragment>

                        }
                            <React.Fragment>
                                <TawreedFormField name="product" title="lbl_store_products_product_id" className="field col-12 md:col-6">
                                    {
                                        inlineMode != 'View' ?
                                    <React.Fragment>
                                        <ProductAutoCompleteComponent
                                            storeId={data.store?.partnerId}
                                            autoCompleteName="product"
                                            autoCompleteRules={{ required: "msg_field_is_required" }}
                                            autoCompleteDisabled={data.storeProductId != null && true}
                                            autoCompleteRender="form"
                                            autoCompleteOnSelect={e => {
                                                const p = e.value as ProductDto;
                                                if (p) {
                                                    setData({
                                                        ...data,
                                                        storeItemId: p.internationalCode,
                                                        product: p,
                                                        titleAr: p.displayName,
                                                        titleEn: p.displayName,
                                                        tax: p.productTax,
                                                        price: p.price,
                                                    });
                                                } else {
                                                    setData({
                                                        ...data,
                                                        product: undefined,
                                                        titleAr: undefined,
                                                        titleEn: undefined,
                                                        tax: undefined,
                                                        price: undefined,
                                                        storeItemId: undefined,
                                                    });
                                                }
                                            }} />
                                        <div className="p-2 font-bold">
                                            {
                                                data.storeProductId == null && 
                                                    <NavLink to={"#"} onClick={() => setPopUp(true)}>{tawreedTranslationService.translate("lbl_store_product_browse")}</NavLink>
                                                
                                            }
                                            
                                        </div>
                                    </React.Fragment>
                                    :
                                <React.Fragment>
                                <br/>
                                <OverlayPanel ref={productRef} style={{ width: '33vw' }} breakpoints={{ '960px': '60vw', '640px': '80vw' }}>
                                        <BaseProductInfo product={baseProduct}></BaseProductInfo>
                                </OverlayPanel>
                                <span className="text-primary cursor-pointer" onClick={(e) => productRef.current?.toggle(e)}>
                                {data.product?.displayName}
                                </span>
                                </React.Fragment>
                                }
                                </TawreedFormField>
                            </React.Fragment>
                            {inlineMode != 'View' ?
                            <TawreedFormField name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                              
                                <TawreedInputSwitch name="active" render="form" />
                                
                            </TawreedFormField> : 
                            <div className="field-tawreed field col-12 md:col-6">
                                <label>{tawreedTranslationService.translate("lbl_product_active")}</label>
                                <br/>
                                <span>{data.active ? tawreedTranslationService.translate("lbl_product_active") : tawreedTranslationService.translate("lbl_products_not_active")}</span>
                            </div>
                            
                            }
                            <TawreedFormField name="storeItemId" title="lbl_store_product_item_id" className="field col-12 md:col-6">
                            {inlineMode != 'View' ? 
                                <React.Fragment>
                                    <TawreedInputText placeholder="" name="storeItemId" rules={{ required: "msg_field_is_required" }} render="form" />
                                    <p >{tawreedTranslationService.translate("lbl_store_product_store_hint")}</p>
    
                                </React.Fragment> : <TawreedInputPlain name="storeItemId" render="form" />
                            }
                            </TawreedFormField>
    
                            <TawreedFormField name="expireDate" title="lbl_store_product_expire_date" className="field col-12 md:col-6">
                            {inlineMode != 'View' ? 
                               <TawreedCalendar minDate={new Date()} render="form" name="expireDate" rules={data.product?.categoryId === 1 ? { required: 'msg_field_is_required' } : {}} />
                                : <TawreedInputPlain name="expireDate" render="form" mode="datetime"/>
                            }
                            </TawreedFormField>
                            <TawreedFormField name="titleAr" title="lbl_store_product_title_ar" className="field col-12 md:col-6">
                            {inlineMode != 'View' ? 
                               <TawreedInputText name="titleAr" render="form" rules={{ 
                                required: 'msg_field_is_required',
                                validate: (value) => { 
                                    var trim_value= value.trim();
                                    if(trim_value.length ==0)
                                    {
                                        return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                    }

                                }
                            }} />
                               : <TawreedInputPlain name="titleAr" render="form"/>
                            }
                            </TawreedFormField>
                            <TawreedFormField name="titleEn" title="lbl_store_product_title_en" className="field col-12 md:col-6">
                                {inlineMode != 'View' ? <TawreedInputText name="titleEn" render="form" rules={{ 
                                                    required: 'msg_field_is_required',
                                                    validate: (value) => { 
                                                        var trim_value= value.trim();
                                                        if(trim_value.length ==0)
                                                        {
                                                            return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                                        }
    
                                                    }
                                                }} /> :
                                                <TawreedInputPlain name="titleEn" render="form" rules={{ 
                                                    required: 'msg_field_is_required',
                                                    validate: (value) => { 
                                                        var trim_value= value.trim();
                                                        if(trim_value.length ==0)
                                                        {
                                                            return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                                        }
    
                                                    }
                                                }} />
                                }
                            </TawreedFormField>
                            
    
                            <TawreedFormField name="tax" title="lbl_store_products_tax" className="field col-12 md:col-6">
                            {inlineMode != 'View' ? 
                               <TawreedInputNumber suffix={"%"} mode={"decimal"} name="tax" rules={{ required: "msg_field_is_required" }} render="form" />
                               : <TawreedInputPlain name="tax" render="form" mode="numeric"/>
                            }
                            </TawreedFormField>
                            <TawreedFormField name="description" title="lbl_store_product_description" className="field col-12 md:col-12">
                            {inlineMode != 'View' ? 
                               <TawreedInputTextarea name="description" render="form" />
                               : <TawreedInputPlain name="description" render="form"/>
                            }
                            </TawreedFormField>
                        </div>

                    </Panel>         
        </TawreedForm>
         {popUp && <ProductDialog createCase={false} showCancel={true} visible={popUp} isDiscount={false} hide={() => setPopUp(false)} accept={(selectedData) => {
            setData({
                ...data,
                product: selectedData,
                titleAr: selectedData.productName,
                titleEn: selectedData.productNameEn,
                tax: selectedData.productTax,
                price: selectedData.price,
                storeItemId: selectedData.internationalCode,
            })
            setPopUp(false);
        }}></ProductDialog>}
        </React.Fragment>
    );
});