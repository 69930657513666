import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { FinancePartnerDto, PartnersChartDto } from "../../domain/dtos/dashboard";
import { PartnersChartMapper } from "../mappers/partner-chart";

export class DashboardRepository {

    public getFinanceReportForPartner(type: number): Promise<PartnersChartDto> {
        return tawreedHttpService
            .post<number, Array<JObject>>(endpoints.DASHBOARD_PARTNER_REPORT, type, undefined, undefined, 'error')
            .then(res => PartnersChartMapper.fromJson(res.data,
                type ===2 || type ===3 || type ===5, type!==5
            ));
    }
    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<FinancePartnerDto>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = {
            ...buildFilterMetaToObject(request.keyword, request.filters),
        }
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.DASHBOARD_PARTNER_REPORT_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => PartnersChartMapper.fromFinancePartnerJson(e)) ?? [] },
                };
            });
    }
}