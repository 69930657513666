import React, { useState } from "react";
import { Timeline } from "primereact/timeline";
import { Panel } from "primereact/panel";
import { tawreedTranslationService } from "../../../../../../common/translations";
import { HistoryItem, HistoryTrackingItem } from "../../../data";
import { DateUtils } from "../../../../../../helpers/date";
import { GlobalContext } from "../../../../../../context";
import { TawreedForm, TawreedFormSubmitProps, TawreedInputCustom } from "../../../../../../common/components/form";
import { TawreedMenuItem } from "../../../../../constants";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import {orderService as service } from "../../../domain";
import { useParams } from "react-router-dom";

export type OrderFormFullHistoryProps = {
};

export const OrderFormFullHistory: React.FC<OrderFormFullHistoryProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);
    const { id } = useParams<{ id: string }>();
    const [state, setState] = useState<HistoryTrackingItem[]>();
    React.useEffect(() => {
        let mounted = true;
        if (id) {
           if (mounted) {
                service.getOrderHistory({
                    page: 0,
                    size:100,
                    sort: {sortField: 'historyId', sortOrder: -1, multiSortMeta:undefined},
                    filters:{
                        orderId:{
                            operator: 'AND',
                            constraints: [
                                {
                                    value: id,
                                    matchMode: 'equals',
                                },
                            ]
                        }
                    }
                })
                    .then(res => {
                        setState(
                            res.data?.content
                        );
                    })
                    .catch(() => {
                       
                    });
            }
        }
        return () => {
            mounted = false;
        };
    }, [id]);
    // const customizedMarker = (item:any) => {
    //     return (
    //         <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color }}>
    //             <i className={item.icon}></i>
    //         </span>
    //     );
    // };

    const customizedContent = (item:any) => {
        return (
            <Card title={item.userName} subTitle={DateUtils.format(item.createdAt, 'datetime')}>
                <p>{item.message}</p>
            </Card>
        );
    };
    const submit: TawreedFormSubmitProps<any> = {
        showButton: false,
        resetOnSubmit: false,
    }
    return (
        <TawreedForm
         title={'lbl_sales_orders_o_history'}
                dataKey="orderId" data={state}
                submit={submit}
                loading={false}
                mode={'Edit'}
                 useBack={'..'} className="grid form-grid">
                     <div className="card col-12">
                        <Timeline value={state} align="alternate" className="customized-timeline" 
                        //marker={customizedMarker}
                        content={customizedContent} />
                    </div>
                    </TawreedForm>
    );
}
