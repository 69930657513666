import React, { FC } from "react";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Panel } from "primereact/panel";
import { TawreedForm, TawreedFormField, TawreedFormSubmitProps, TawreedInputCustom, TawreedInputFile, TawreedInputPlain } from "../../../../../common";
import { ProductDto } from "../../../products/domain";
import { GlobalContext } from "../../../../../context";

interface BaseProductInfoProps {
    product: ProductDto
}

export const BaseProductInfo: FC<BaseProductInfoProps> = ({ product }) => {


    const { constants: { constants } } = React.useContext(GlobalContext);

    const onSubmit: TawreedFormSubmitProps<ProductDto> = {
        showButton: false,
        resetOnSubmit: false,
    };
    return (<Panel header={tawreedTranslationService.translate("lbl_product")} className={''}>
        <div className="formgrid grid">
            <React.Fragment>
                <TawreedForm title=""
                    dataKey=""
                    data={product}
                    submit={onSubmit}
                    loading={false} mode={"Edit"}
                    useBack={undefined} useReset actions={[]} className="grid form-grid">
                    <div className="grid">

                        <TawreedFormField className="field col-12 md:col-6" name="categoryId" title="lbl_product_category">
                            {
                                <React.Fragment>
<br/>
                                <span>{ constants?.productCategories.filter(x=>x.value===product.categoryId)[0].label}</span>
                                </React.Fragment>
                                
                            }
                            
                        </TawreedFormField>
                        <TawreedFormField className="field col-12 md:col-6" name="displayName" title="lbl_displayName">
                            <TawreedInputPlain name="displayName" render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="internationalCode" title="lbl_product_international_code">
                            <TawreedInputPlain name="internationalCode" render="form" />
                        </TawreedFormField>

                        <TawreedFormField className="field col-12 md:col-6" name="productTax" title="lbl_product_product_tax">
                            <TawreedInputPlain mode="numeric" name="productTax" render="form" />
                        </TawreedFormField>

                        <TawreedInputCustom render="form" name="categoryId" renderer={(c: any) => {
                            const fields = constants?.productCategoriesFields.find(e => e.categoryId === c)?.fields.filter(x => x.fieldName === "INGREDIENT");

                            return (<React.Fragment>

                                {
                                    fields &&
                                    product.fields &&
                                    fields.map((field, index) =>
                                        <React.Fragment key={index}>
                                            <TawreedFormField className="field col-12 md:col-6" name={product.fields!.filter(x=>x.fieldId===field.fieldId).at(0)?.value} title={field.fieldName}>

                                                <TawreedInputPlain render='form' value={product.fields!.filter(x=>x.fieldId===field.fieldId).at(0)?.value} />
                                            </TawreedFormField>
                                        </React.Fragment>)
                                }
                            </React.Fragment>)

                        }} />

                        <div className="col-12">
                            <div className="grid">
                                <TawreedFormField name="image" className="field col-12 md:col-3" title="lbl_product_images">
                                    <TawreedInputFile render="form" name="image" disabled={true} />
                                </TawreedFormField>
                            </div>
                        </div>

                    </div>
                </TawreedForm>
            </React.Fragment>

        </div>
    </Panel>);
} 