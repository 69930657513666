import PrimeReact, { addLocale, locale } from "primereact/api";
import { JObject } from "../data/models";

class TawreedTranslationService {
    private translations: JObject;

    constructor() {
        // initialize locales
        addLocale("en", {
            // Primereact
            startsWith: 'Starts with',
            contains: 'Contains',
            notContains: 'Not contains',
            endsWith: 'Ends with',
            equals: 'Equals',
            notEquals: 'Not equals',
            noFilter: 'No Filter',
            lt: 'Less than',
            lte: 'Less than or equal to',
            gt: 'Greater than',
            gte: 'Greater than or equal to',
            dateIs: 'Date is',
            dateIsNot: 'Date is not',
            dateBefore: 'Date is before',
            dateAfter: 'Date is after',
            custom: 'Custom',
            clear: 'Clear',
            apply: 'Apply',
            matchAll: 'Match All',
            matchAny: 'Match Any',
            addRule: 'Add Rule',
            removeRule: 'Remove Rule',
            accept: 'Yes',
            reject: 'No',
            choose: 'Choose',
            upload: 'Upload',
            cancel: 'Cancel',
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            weekHeader: 'Wk',
            firstDayOfWeek: 6,
            dateFormat: 'mm/dd/yy',
            weak: 'Weak',
            medium: 'Medium',
            strong: 'Strong',
            passwordPrompt: 'Enter a password',
            emptyFilterMessage: 'No results found',
            emptyMessage: 'No results found',
            lbl_price: "Price",
            lbl_tax: "Tax",
            lbl_discount: "Discount",
            lbl_quantity: "Quantity",
            lbl_sellingPrice: "Selling Price",
            lbl_sellingPrice_befor_per_unit: "Unit price before discount",
            lbl_sellingPrice_befor_after_unit: "Unit price after discount",

            lbl_bonus: "Bonus",
            lbl_Discounts: "Discounts",
            lbl_base_discount: "Base Discount",
            lbl_additional_discount: "Additional Discount",
            lbl_additional_discount_unit: "Additional Discount (J.D)",
            lbl_sub_total: "Sub Total",
            lbl_sub_total1: "Sub Total (JOD)",
            lbl_total_tax: "Total Tax",
            lbl_total: "Total",
            lbl_total_unit: "Total",
            lbl_total1: "Product Total (JOD)",

            // Notification
            lbl_notifications: "Notifications",
            lbl_notifications_mark_as_read: "Mark as read",
            lbl_notifications_filter_mode_all: "All",
            lbl_notifications_filter_mode_unread: "Unread",
            lbl_notification_summary: "",
            lbl_notification_summary_success: "Success",
            lbl_notification_summary_info: "Information",
            lbl_notification_summary_warn: "Warning",
            lbl_notification_summary_error: "Error",
            //
            lbl_clear_filters: "Clear",
            lbl_paginator_curr_of: "Of",
            lbl_footer_app_created_by: "by",
            lbl_items_selected: "{0} items selected",
            msg_field_is_required: "This field is required.",
            msg_field_is_too_long: "This field is too long.",
            msg_field_max_allowed_exceeded: "The field's maximum allowed value is {0}",
            msg_field_min_allowed_exceeded: "The field's minimum allowed value is {0}",
            msg_cat_error: "Product category is not correct. Select another one.",
            lbl_language_toggle: "Language",
            lbl_ar_language_toggle: "العربية",
            lbl_en_language_toggle: "English",
            lbl_home: "Home",
            lbl_all: "All",
            lbl_back_to_catalog: "Back to catalog",
            lbl_previous: "Previous",
            lbl_back: "Back",
            lbl_reset: "Reset",
            lbl_create: "Create",
            lbl_save: "Save",
            lbl_search: "Text, number or date (yyyy-MM-dd)",
            lbl_activate_all: "Activate ({0})",
            lbl_activate: "Activate",
            lbl_deactivate: "Deactivate",
            lbl_deactivate_all: "Deactivate ({0})",
            lbl_logout: "Logout",
            lbl_login: "Login to Mawrid",
            lbl_login_username: "Username",
            lbl_login_password: "Password",
            lbl_login_remember_me: "Remember Me",
            lbl_login_submit: "Login",
            // Dialog
            tawreed_dialog_confirm_msg: 'Are you sure you want to proceed?',
            tawreed_dialog_confirm_hdr: 'Confirmation',
            tawreed_dialog_confirm_delete_msg: 'Do you want to delete this record?',
            tawreed_dialog_confirm_delete_hdr: 'Delete Confirmation',
            // roles
            lbl_roles_1: "Administrator",
            lbl_roles_2: "Manager",
            lbl_roles_3: "Delivery",
            lbl_roles_4: "Sales",
            lbl_roles_5: "Store",
            lbl_roles_6: "Customer",
            lbl_roles_7: "Store Delivery",
            lbl_roles_8: "TopUp Admin",
            lbl_roles_9: "Wallet Admin",
            lbl_roles_10: "Cat Maanager",
            lbl_roles_11: "Sys Admin",
            lbl_roles_12: "Finance",
            //discounts types
            lbl_discountType_1_value: "fixed",
            lbl_discountType_1_label: "Fixed",
            lbl_discountType_2_value: "percentage",
            lbl_discountType_2_label: "Percentage",
            lbl_discountType_3_value: "bonus",
            lbl_discountType_3_label: "Bonus",

            lbl_import_helper_status1_label: "PENDING",
            lbl_import_helper_status2_label: "NOT_STARTED",
            lbl_import_helper_status3_label: "COMPLETED",
            lbl_import_helper_status4_label: "FAILED",
            lbl_import_helper_status1_value: "PENDING",
            lbl_import_helper_status2_value: "NOT_STARTED",
            lbl_import_helper_status3_value: "COMPLETED",
            lbl_import_helper_status4_value: "FAILED",
            lbl_import_store_product: "Upload Products",
            lbl_refresh: "Refresh",
            lbl_download_original_file: "Download Original File",
            lbl_downlad_confirmed_result: "Download Resutl File",
            lbl_downlad_unconfirmed_result: "Download unConfirmed Resutl File",
            lbl_downlad_invalid_result: "Download Invalid Resutl File",

            lbl_update: "Update",
            lbl_view: "View",
            lbl_update_ref: "Re Matching",
            lbl_store_raw_status1_label: "Exact Match",
            lbl_store_raw_status2_label: "High Match",
            lbl_store_raw_status3_label: "Medium Match",
            lbl_store_raw_status4_label: "Low Match",

            lbl_store_raw_status5_label: "No Match",

            lbl_store_raw_title1_label: "Exact Match",
            lbl_store_raw_title2_label: "High Match",
            lbl_store_raw_title3_label: "Medium Match",
            lbl_store_raw_title4_label: "Low Match",
            lbl_store_raw_title5_label: "No Match",
            lbl_store_raw_title6_label: "Invalid",


            lbl_update_match: "Update",
            lbl_matches: "Matches",
            lbl_not_selected: "Not selected",
            lbl_options: "Options",
            lbl_store_raw_confirm_status1: "Confirmed",
            lbl_store_raw_confirm_status2: "unConfirmed",

            lbl_store_raw_confirm_status3: "",








            //checkoutModes
            lbl_checkout_mode_notify: 'Notify Customer',
            lbl_checkout_mode_place: "Placed Order",
            lbl_can_shipping: "Can Shipping",




            // users
            lbl_management: "Management",
            lbl_users: "Users",
            lbl_category: "Categories",
            lbl_category_ar: "Name (ar)",
            lbl_category_en: "Name (en)",
            lbl_banner: "Banners",
            lbl_banner_id: "Banner",
            lbl_users_full_name: "Full Name",
            lbl_users_partner_name: "Partner Name",
            lbl_users_user_name: "User Name",
            lbl_users_locked: "Active",
            msg_users_emailormobilenumberrequired: "Email, Username or Mobile number required",
            lbl_users_is_locked: "Not Active",
            lbl_users_is_not_locked: "Active",
            lbl_users_partner_type: "Partner Type",
            lbl_change_password: "Change Password",
            lbl_partner_information: "Partner Information",
            lbl_users_user: "User",
            lbl_users_first_name: "First Name",
            lbl_users_last_name: "Last Name",
            lbl_users_email: "Email or Username",
            lbl_users_site_id: "Site",
            lbl_users_mobile_no: "Mobile Number",
            lbl_users_nationality: "Nationality",
            lbl_users_birthday: "birthDay",
            lbl_users_partner_admin: "Partner Admin",
            lbl_users_suspend: 'Suspend',
            lbl_users_language: "Language",
            lbl_users_role: "Role",
            lbl_users_new_password: "New Password",
            lbl_users_new_password_required: "New Password is required",
            lbl_users_confirmed_password: "Confirm Password",
            lbl_users_confirmed_password_required: "Confirm Password is required",
            lbl_users_confirmed_password_not_equal: "Passwords didn't match",
            // Partners
            lbl_partners: "Partners",
            lbl_partners_all: "All",
            lbl_partners_1: "Stores",
            lbl_partners_2: "Customers",
            lbl_partners_7: "Payment Partners",
            lbl_partners_4: "TopUps",
            lbl_partner: "Partner",
            lbl_partner_0: "Tawreed",
            lbl_partner_1: "Store",
            lbl_partner_2: "Customer",
            lbl_partner_3: "Delivery",
            lbl_partner_4: "TopUp",
            lbl_wallet_status_Empty: "Not Created",
            lbl_wallet_status_Active: "Active",
            lbl_wallet_status_Inactive: "Inactive",
            lbl_wallet_status_Pending: "Pending",
            lbl_wallet_status: "Wallet status",
            lbl_partners_business_type: "Business Type",
            lbl_partners_partnerid: "Partner Id",
            lbl_partners_balanceid: "Balance Id",
            lbl_partners_partner_name: "Partner Name",
            lbl_partners_status_id: "Status Id",
            lbl_partners_partner_type: "Partner Type",
            lbl_partners_start_date: "Join Date",
            lbl_partners_address_information: "Address Information",
            lbl_partners_address_city_id: "Area",
            lbl_partners_commission_percent: "Commission Percent",
            lbl_partners_order_min: "Order Min Value",
            lbl_partners_national_id: "National ID",
            lbl_partners_establishmentnationalid: "Establishment National ID",
            lbl_partners_license_number: "License Number",
            lbl_partners_licence_expire_date: "License Expire Date",
            lbl_sales_orders_pricelistid: "Price List Id",
            lbl_sales_orders_paymentmodeid: "Payment Mode",
            msg_partners_files_required: "These files are required {0}.",
            lbl_upload: "Upload",
            lbl_download: "Download",
            lbl_partners_logo: "Logo",
            lbl_partners_priority: "Priority",
            lbl_partners_contact_information: "Contact Information",
            lbl_partners_contact_name: "Name",
            lbl_partners_contact_mobile: "Mobile No",
            lbl_partners_contact_email: "Email",
            lbl_partners_address_zone: "Zone",
            lbl_partners_address_state_id: "City",
            lbl_partners_address_street: "Street",
            lbl_partners_address_latitude: "Latitude",
            lbl_partners_address_longitude: "Longitude",
            lbl_partners_address_location: "Show on map",
            lbl_partners_address_address: "Address",
            lbl_partners_files: "Files",
            lbl_partners_new: "New",
            lbl_partners_verified: "Verified",
            lbl_partners_active: "Active",
            lbl_partners_not_active: "Not Active",
            lbl_set: "Set Class ({0})",
            lbl_remove: "Remove Class ({0})",
            lbl_setItem: "Set Class",
            lbl_removeItem: "Remove Class",
            //partner connection
            lbl_add_connection: "Add",
            lbl_partners_Classification: "Class",
            lbl_connections: "Partner Connections",
            lbl_partners_from_partner_name: "Soruce Partner Name",
            lbl_partners_to_partner_name: "Destination Partner name",
            // catalog
            lbl_catalog: "Catalog",
            lbl_products: "Products",
            lbl_unverified_products: "Unverified Products",
            lbl_products_upload: "Upload",
            lbl_success: "Success",
            lbl_failed: "Failed",
            lbl_cancel: "Cancel",
            lbl_next: "Next",
            lbl_add: "Add",
            lbl_edit: "Edit",
            lbl_accept: "Accept",
            lbl_store_products_import: "Import",
            lbl_store_products_download_template: "Download Template",
            lbl_store_products_download: "Download Catalog",

            lbl_store_id: "Store",
            lbl_file: "File",
            lbl_store_products_store_product_name: "Product Name (Store)",
            lbl_store_products_product_id: "Product Name",
            lbl_store_products_id: "ID",
            lbl_store_products_product_id_ar: "Product Name (ar)",
            lbl_store_products_product_id_en: "Product Name (en)",
            msg_update_unverified_category: "You can't update the category of unverified product",

            lbl_store_products_retail_price: "Price",
            lbl_store_products_discount_percent: "Discount",
            lbl_store_products_store_name: "Store",
            lbl_store_products_customer_name: "Customer",
            lbl_store_products_class: "Class",
            lbl_store_products_bonus: "Bonus",
            lbl_store_products_bonus_title: "Bonus Offers",
            lbl_store_products_discount_bonus: "Discount",
            lbl_store_products_discount_bonus_title: "Discounts Offers",
            lbl_store_products_discount_bonus_val: "Discount Value",
            lbl_store_products_qty: "Quantity",
            lbl_store_products_bonus_qty: "Quantity",
            lbl_otp_code: "OTP Code",
            lbl_store_products_bonus_value: "Value",
            lbl_store_products_tax: "Tax",
            lbl_product_tax: "Tax",
            lbl_store_product_item_id: "Store Item Id",
            lbl_store_product_store_hint: "If you are using your own system for products, please enter your  product identification",
            lbl_store_product_expire_date: "Expire Date",
            lbl_min_order_qty: "Minimum Order Quantity",
            lbl_max_order_qty: "Maximum Order Quantity",
            lbl_store_product_browse: "Brows Products",
            lbl_store_product_all_browse: "Brows All Products",
            lbl_store_product_description: "Description",
            lbl_price_scheme_price: "Price before tax",
            lbl_price_scheme_discountValue: "Discount Value",
            lbl_price_scheme_discountType: "Discount Type",
            lbl_bonus_operation: "Operation",

            lbl_price_scheme_price_list_name: "Price List",
            lbl_old_batch: "Update",
            lbl_add_base_product: "Add the non existent products to master catalog",
            lbl_delete_my_account: "Delete my account",
            lbl_price_list_default: "Cash",
            lbl_price_list_credit: "Store Credit",
            lbl_commission_value: "Commission Value",
            lbl_commission_type: "Comission Type",
            lbl_commission_list: "Commission List",
            lbl_commission_error: "You need to add all Commissions",
            lbl_bonus_error: "Bonus already exists",
            lbl_price_list_error: "You need to add at least one price",
            lbl_price_list_commission_error: "Price after discount can’t be less than the commission.",
            lbl_selling_price_error: "Price after discount can’t be less than 0",
            lbl_selling_price: "Selling Price",
            lbl_selling_price1: "Selling Price (JOD)",
            lbl_city_error: "Select correct city",

            lbl_delete: "Delete",
            lbl_add_price: "Add Price",
            lbl_delete_all: "Delete ({0})",
            lbl_cleanup: "Cleanup",
            lbl_new: "New",
            lbl_store_product_new: "Add Product",
            lbl_status_initiated: "Initiated",
            lbl_status_processed: "Processed",
            lbl_info: "Info",
            lbl_product: "Product",
            lbl_product_id: "Product Id",
            lbl_product_international_code: "International Code",
            lbl_product_product_tax: "Product Tax ",
            lbl_product_price: "Product Price",
            lbl_product_keywords: "Keywords",
            lbl_product_dosage: "Dosage",
            lbl_product_agent: "Agent",
            lbl_product_filling: "Filling",
            lbl_product_concentrate: "Concentrate",
            lbl_product_ingredient: "Ingredient",
            lbl_product_industryCmp: "IndustryCmp",
            lbl_product_active: "Active",
            lbl_products_active: "Active",
            lbl_products_not_active: "Not Active",
            lbl_product_name: "Name",
            lbl_product_category: "Category",
            lbl_product_seller_count: "Sellers",
            lbl_product_verified: "Verified",
            lbl_product_unverified: "Unverified",
            lbl_product_categories: "Product Categories",
            lbl_product_name_en: "Name (English)",
            lbl_whiteSapce_field: "Please enter a valid value",
            lbl_store_product_title_en: "Title (English)",
            lbl_store_product_title_ar: "Title (Arabic)",
            lbl_company: "Company",
            lbl_company_en: "Company (English)",
            lbl_displayName: "Display Name",
            lbl_diaplay_name_formula: " (Formula: brand - product - DRG_CONCENTRATE - DRG_FILLING - DOSAGE)",
            lbl_generic: "Generic",
            lbl_generic_en: "Generic (English)",
            lbl_active: "Active",
            lbl_not_active: "Not Active",
            lbl_page_customers: "Customers",
            lbl_page_customer_new: "New Customer",
            lbl_page_customer_details: "Customer Details",
            lbl_page_customer_edit: "Edit Customer",
            lbl_page_stores: "Stores",
            lbl_page_store_new: "New Store",
            lbl_page_store_details: "Store Details",
            lbl_page_store_edit: "Edit Store",
            lbl_product_new: "New Product",
            lbl_retail_price: "Price",
            msg_save_succeeded: "Save completed successfully",
            msg_def_prompt_header: "Confirmation",
            msg_def_prompt_message: "Are you sure?",
            msg_def_prompt_accept_label: "Okay",
            msg_def_prompt_reject_label: "Cancel",
            msg_upload_completed_successfully: "Upload completed successfully",
            msg_unknown_error: "Unknown error occurred",
            lbl_dashboard: "Dashboard",
            lbl_store_products: "Store Products",
            lbl_store_product: "Store Product",
            lbl_store_product_active: "Active",
            lbl_store_name: "Store Name",
            lbl_discount_percent: "Discount",
            lbl_products_variants: "Variants",
            lbl_general_information: "General Information",
            lbl_quantity_trackin: "Quantity Tracking",
            lbl_product_images: "Product Images",
            lbl_category_image: "Category Image",
            lbl_Banner_image: "Banner Image",
            lbl_verify: "Verify",
            lbl_link: "Link",
            lbl_create_wallet: "Create Wallet",
            lbl_enable_upload: "Enable Upload",
            lbl_enable: "Enable",
            lbl_disable: "Disable",

            lbl_unenable_upload: "UnEnable Upload",
            lbl_trade_license: 'Trade License',
            lbl_logo: 'Logo',
            lbl_trade_registry: 'Trade Registry',
            lbl_sales_carts: "Shopping Cart",
            lbl_sales_cart: "Cart {0}",
            lbl_sales_cart_customer_information: "Customer Information",
            lbl_sales_carts_customer_partner_name: "Customer Name",
            lbl_files: "Files",
            lbl_files_filename: "File Name",
            lbl_files_file: "File",
            lbl_sales_cart_products: "Products",
            lbl_sales_carts_products_product_id: "Product Name",
            lbl_sales_carts_products_quantity: "Quantity",
            lbl_add_to_cart: "Add to cart",
            lbl_otp: "OTP",
            lbl_sales_carts_products_store_name: "Store Name",
            lbl_sales_carts_products_retail_price: "Price",
            lbl_sales_carts_products_discount_percent: "Discount",
            lbl_sales_carts_products_sale_price: "Sale Price",
            lbl_sales_cart_summary: "Summary",
            lbl_sales_cart_summary_bonus: "Summary with bonus",
            lbl_sales_cart_summary_discount: "Summary with discount",

            lbl_sales_cart_sub_total: "Sub total",
            lbl_sales_cart_gross_total: "Gross total",
            lbl_sales_cart_gross_total_1: "Gross total",
            lbl_sales_cart_total_tax: "Total tax",
            lbl_sales_cart_total: "Total",

            lbl_sales_cart_shipping_total: "Shipping total",
            lbl_sales_cart_total_discounts: "Total discounts",
            lbl_sales_cart_order_total: "Order Total",
            lbl_checkout_all: "Checkout all",
            lbl_checkout: "Checkout",
            lbl_checkout_note: "Note",
            lbl_sales_cart_store_cart_items_product_name: "Product Name",
            lbl_sales_cart_store_cart_items_quantity: "Quantity",
            lbl_sales_cart_store_cart_items_retail_price: "Unit price",
            lbl_sales_cart_store_cart_items_retail_price_unit: "Unit price (J.D)",
            lbl_sales_cart_store_cart_items_discount_percent: "Discount",
            lbl_sales_cart_store_cart_items_item_total: "Item Total",
            lbl_sales_cart_store_cart_items_item_total_discount: "Total Discount",
            lbl_sales_cart_store_cart_items_item_total_tax: "Total Tax",
            lbl_sales_cart_store_cart_items_item_total_tax1: "Tax (%)",

            lbl_sales_cart_customer: "Customer",
            lbl_sales_cart_order_totals: "Total Amount",
            lbl_sales_cart_type: "Type",
            lbl_sales_cart_types_1: "By Customer",
            lbl_sales_cart_types_2: "By Store (Store)",
            lbl_sales_cart_types_3: "By Store (Customer)",
            lbl_sales_cart_orders_count_total: "Count",
            msg_sales_cart_checkout_dialog_1: "You haven't reached the minimum allowed amount for the following stores:",
            msg_sales_cart_checkout_dialog_2: "Are you sure to confirm order without them?",
            lbl_barcode_price: "Barcode",
            lbl_placed_note: 'You can change the quantity and the bonus before confirming your order. Please make sure to align with your customer about the changes before confirming the order.',
            lbl_sales: "Sales Orders",
            lbl_sales_orders: "Orders",
            lbl_sales_orders_status: "Status",
            lbl_sales_orders_invalidCols: "Invalid Cols",
            lbl_sales_orders_order_id: "Order Id",
            lbl_sales_orders_header_order_id: "ID",
            lbl_sales_orders_header_date: "Date",
            lbl_sales_orders_header_pricelistid: "Price List",
            lbl_sales_orders_header_order_total: "Total",
            lbl_sales_orders_return_id: "Return Id",
            lbl_sales_orders_date: "Order Date",
            lbl_sales_orders_customer: "Customer",
            lbl_sales_orders_store: "Store",
            lbl_sales_orders_customer_state: "State",
            lbl_sales_orders_offer: "Offer",
            lbl_sales_orders_order_total: "Order Total",
            lbl_sales_orders_o_info_items_product_name: "Product Name",
            lbl_sales_orders_o_info_items_quantity: "QTY",
            lbl_sales_orders_o_info_items_retail_price: "Price",
            lbl_salesordersitem_totaldiscount: "Discount",
            lbl_salesordersitem_totaltax: "Tax",
            lbl_sales_orders_o_info_items_discount_percent: "Discount",
            lbl_sales_orders_o_info_items_sale_price: "Sale Price",
            lbl_sales_orders_o_info_items_item_total: "Total",
            lbl_sales_orders_o_info_items_item_total_unit: "Total (J.D)",
            lbl_sales_order: "Order",
            lbl_sales_orders_o_info: "Order Information",
            lbl_sales_orders_o_info_customer: "Customer Information",
            lbl_sales_orders_o_info_items: "Order Items",
            lbl_sales_orders_sh_info: "Shipment Information",
            lbl_sales_orders_shipment_no: "Shipment No.",
            lbl_sales_orders_shipment_date: "Shipment Date",
            lbl_sales_orders_shipment_method: "Shipment Method",
            lbl_sales_orders_carrier: "Delivery",
            lbl_sales_orders_shipping_total: "Shipment Total",
            lbl_sales_orders_shipping_total_unit: "Shipment Total (J.D)",
            lbl_sales_orders_items_count: "Items Count",
            lbl_sales_orders_items_count_short: "Items",
            lbl_sales_orders_items_quantity: "Items Quantity",
            lbl_sales_orders_currency_code: "Currency Code",
            lbl_sales_orders_order_date: "Order Date",
            lbl_sales_orders_updated_at: "Last Updated",
            lbl_sales_orders_cancel: "Cancel",
            lbl_sales_orders_cancel_reason: "Reason",
            lbl_sales_orders_return_reason: "Reason",
            lbl_sales_orders_return_reason_ar: "Reason Ar",
            lbl_sales_orders_return_reason_en: "Reason En",
            lbl_sales_orders_return: "Return",
            lbl_sales_orders_deliver: "Deliver",
            lbl_sales_orders_print: "Print",
            lbl_sales_orders_print_all: "Print All ({0})",
            lbl_sales_orders_ship_all: "Ship All ({0})",
            lbl_sales_orders_return_all: "Return All ({0})",
            lbl_sales_orders_deliver_all: "Deliver All ({0})",
            lbl_sales_orders_confirm: "Confirm",
            lbl_sales_orders_ready: "Ready for delivery",
            lbl_sales_orders_carrier_change: "Update Ship",
            lbl_sales_orders_notify: "Notify",
            lbl_sales_orders_store_print: "Store Report",
            lbl_sales_orders_customer_print: "Customer Report",
            lbl_sales_orders_shipment_print: "Shipment Report",
            lbl_sales_orders_items: "Items Report",
            lbl_sales_orders_sub_total: "Sub Total",
            lbl_sales_orders_sub_total_unit: "Sub Total (J.D)",
            lbl_sales_orders_gross_total: "Gross total",
            lbl_sales_orders_gross_total_unit: "Gross total (J.D)",
            lbl_sales_orders_total_tax: "Total tax",
            lbl_sales_orders_total_tax_unit: "Total tax (J.D)",
            lbl_sales_orders_total: "Total",
            lbl_sales_orders_total_unit: "Total (J.D)",
            lbl_sales_orders_commission_amount: "Commission amount",
            lbl_sales_partners_orders: "Partners Orders",
            lbl_sales_partners_orders_partner: "Name",
            lbl_sales_partners_orders_carrier: "Delivery",
            lbl_sales_partners_orders_total_count: "Count",
            lbl_sales_partners_orders_total_amount: "Total Amount",
            lbl_sales_stores_placed_orders: "Placed Orders",
            lbl_sales_stores_ready_orders: "Ready For Delivery Orders",
            lbl_sales_stores_processing_orders: "Processing Orders",
            lbl_sales_customers_shipped_orders: "Shipped Orders",
            lbl_sales_returned_orders: "Return Orders",
            lbl_sales_orders_o_info_store: "Store Information",
            lbl_sales_orders_o_info_carrier: "Delivery Information",
            lbl_sales_returned_orders_shipped: "Return Shipped Orders",
            lbl_sales_returned_orders_ready: "Ready For Delivery Orders",

            lbl_sales_returned_orders_new: "New Return Order",
            lbl_sales_returned_order_customer: "Customer",
            lbl_sales_returned_order_order_item: "Order Item",
            lbl_sales_returned_order_order_id: "Order Id",
            lbl_sales_returned_order_store_name: "Store",
            lbl_sales_returned_order_retail_price: "Price",
            lbl_sales_returned_order_discount_percent: "Discount",
            lbl_sales_returned_order_qantity: "Quantity",
            lbl_sales_returned_order_return_qantity: "Return Quantity",
            lbl_finance_documents: "Finance",
            lbl_finance_documents_1: "Payment Voucher",
            lbl_finance_documents_2: "Receipt Voucher",
            lbl_finance_documents_3: "Credit Note",
            lbl_finance_documents_4: "Debit Note",
            lbl_finance_documents_partner: "Partner",
            lbl_finance_documents_document_date: "Document Date",
            lbl_finance_documents_description: "Description",
            lbl_finance_documents_description_Ar: "Description Ar",
            lbl_finance_documents_journal: "Manual Journal",
            lbl_finance_documents_first_account_id: "First Account ID",
            lbl_finance_documents_is_first_account_credit: "Credit",
            lbl_finance_documents_second_account_id:"Second Account ID",
            lbl_finance_documents_is_second_account_credit: "Credit",

            lbl_finance_documents_amount: "Amount",
            lbl_finance_documents_type: "Type",
            lbl_finance_documents_paymentreference: "Payment Reference",
            lbl_reference: "Reference",
            lbl_financial_documents_type_payment_voucher: "Payment Voucher",
            lbl_financial_documents_type_receipt_voucher: "Receipt Voucher",
            lbl_financial_documents_type_credit_note: "Credit Note",
            lbl_financial_documents_type_debit_note: "Debit Note",
            lbl_Doument_files: "Files",
            lbl_document_files_title: "Title",
            lbl_document_files_add_file: "Add File",
            lbl_document_files_add_invoice: "Add invoice",

            lbl_sales_orders_ship: "Ship",
            lbl_orders_partners_1_false_1: "Placed Orders",
            lbl_orders_partners_1_false_7: "Ready For Delivery Orders",
            lbl_orders_partners_1_false_2: "Processing Orders",
            lbl_orders_partners_1_false_3: "Shipped Orders",
            lbl_orders_partners_1_true_3: "Shipped Return Orders",
            lbl_orders_partners_1_false_4: "Delivered Orders",
            lbl_orders_partners_1_false_5: "Cancelled Orders",
            lbl_orders_partners_1_false_6: "Return Orders",
            lbl_orders_partners_2_false_1: "Placed Orders",
            lbl_orders_partners_2_false_7: "Ready For Delivery Orders",
            lbl_orders_partners_2_false_2: "Processing Orders",
            lbl_orders_partners_2_false_3: "Shipped Orders",
            lbl_orders_partners_2_true_3: "Shipped Return Orders",
            lbl_orders_partners_2_false_4: "Delivered Orders",
            lbl_orders_partners_2_false_5: "Cancelled Orders",
            lbl_orders_partners_2_false_6: "Return Orders",
            lbl_orders_partner_1_false_1: "Placed Orders {0}",
            lbl_orders_partner_1_false_7: "Ready For Delivery Orders {0}",
            lbl_orders_partner_1_false_2: "Processing Orders {0}",
            lbl_orders_partner_1_false_3: "Shipped Orders {0}",
            lbl_orders_partner_1_true_3: "Shipped Return Orders {0}",
            lbl_orders_partner_1_false_4: "Delivered Orders {0}",
            lbl_orders_partner_1_false_5: "Cancelled Orders {0}",
            lbl_orders_partner_1_false_6: "Return Orders {0}",
            lbl_orders_partner_2_false_1: "Placed Orders {0}",
            lbl_orders_partner_2_false_7: "Ready For Delivery Orders {0}",
            lbl_orders_partner_2_false_2: "Processing Orders {0}",
            lbl_orders_partner_2_false_3: "Shipped Orders {0}",
            lbl_orders_partner_2_true_3: "Shipped Return Orders {0}",
            lbl_orders_partner_2_false_4: "Delivered Orders {0}",
            lbl_orders_partner_2_false_5: "Cancelled Orders {0}",
            lbl_orders_partner_2_false_6: "Return Orders {0}",
            lbl_order_add_note: "Add note",
            lbl_order_notes: "Notes",
            lbl_order_note: "Note",
            lbl_user_name: "User name",
            lbl_user_contact_mobile: "Mobile",
            lbl_user_email: "Email",
            lbl_user_partner_name: "Partner name",
            lbl_user_details: "User info",
            lbl_finance_documents_partner_balance: "Balance",
            lbl_finance_documents_account_id: "Bank Account",
            lbl_sales_orders_o_info_items_returned: "Cancelled Items",
            lbl_reports: "Reports",
            lbl_reports_trial_balance: "Trial Balance",
            lbl_reports_financial_documents: "Documents",
            lbl_reports_financial_statements_per_partner: "Statements",
            lbl_reports_financial_statements_per_account: "Accounts",
            lbl_reports_sales_orders: "Sales Orders",
            lbl_reports_sales_returned_orders: "Return Orders",
            lbl_reports_start_date: "Start Date",
            lbl_reports_end_date: "End Date",
            lbl_reports_financial_documents_partner: "Partner",
            lbl_reports_financial_documents_document_no: "Document No.",
            lbl_reports_financial_documents_type_id: "Document Type",
            lbl_reports_financial_statements_partner: "Partner",
            lbl_reports_financial_statements_account: "Account",
            lbl_reports_sales_orders_store: "Store",
            lbl_reports_sales_orders_customer: "Customer",
            lbl_reports_sales_orders_order: "Order",
            lbl_reports_sales_orders_status_id: "Status Id",
            lbl_reports_sales_returned_orders_store: "Store",
            lbl_reports_sales_returned_orders_customer: "Customer",
            lbl_reports_sales_returned_orders_order: "Order",
            lbl_reports_sales_returned_orders_status_id: "Status Id",
            lbl_reports_invoices: "Invoices",
            lbl_reports_invoice: "Invoice",
            lbl_other: "Other",

            lbl_reports_invoices_returned: "Return Invoices",
            lbl_reports_invoices_store: "Partner",
            lbl_reports_invoices_invoice_no: "Invoice No",
            lbl_sales_orders_summary: "Order Summary",
            lbl_reports_financial: "Finance",
            lbl_reports_sales: "Sales",
            lbl_sales_orders_order: "Order",
            lbl_reports_find: "Find",
            lbl_reports_export_as_pdf: "Export As PDF",
            lbl_reports_export_as_excel: "Export As XLSX",
            lbl_sales_orders_total_discount: "Items Discount",
            lbl_sales_orders_total_discount_unit: "Discount (J.D)",
            lbl_sales_orders_o_info_items_quantity_ordered: "Ordered QTY",
            lbl_sales_orders_applay_discount: "Order Discount",
            lbl_sales_orders_applay_discount_button: "Apply Order Discount",
            // DashboardComponent
            lbl_dashboard_sales_total: "Sales Total",
            lbl_sales_delivered_orders: "Delivered Orders",
            lbl_sales_cancelled_orders: "Cancelled Orders",
            lbl_dashboard_balance_credit_total: "Account Balance",
            lbl_dashboard_balance_debit_total: "Account Receivable",
            lbl_dashboard_partners_customers_total: "Customers",
            lbl_dashboard_partners_customers_new: "{0} New",
            lbl_dashboard_orders_chart_title: "Orders By Status",
            lbl_dashboard_orders_per_zone_chart_title: "Orders By Zone",
            lbl_dashboard_orders_per_state_chart_title: "Orders By State",

            lbl_dashboard_orders_chart_placed: "Placed",
            lbl_dashboard_orders_chart_processing: "Processing",
            lbl_dashboard_orders_chart_shipped: "Shipped",
            lbl_dashboard_orders_chart_delivered: "Delivered",
            lbl_dashboard_orders_chart_cancelled: "Cancelled",
            lbl_dashboard_partners_chart_title: "Orders By Store",
            lbl_dashboard_partners_chart_total_amount: "Amount",
            lbl_dashboard_partners_chart_total_count: "Count",
            lbl_dashboard_orders_totals_chart_title: "Orders By Date",
            lbl_dashboard_orders_totals_chart_total_amount: "Amount",
            lbl_dashboard_orders_totals_chart_total_count: "Count",
            lbl_dashboard_orders_chart_total: "Total {0}",
            //
            KILO: "K",
            MILLION: "M",
            //errors
            lbl_errors_title: "Sorry, Something went wrong",
            lbl_errors_refresh: "Try again!",
            // sotres | zones
            lbl_store_zones: "Store Zones",
            lbl_store_zones_store: "Store",
            lbl_store_zones_state: "State",
            lbl_store_zones_zone: "Zone",
            lbl_store_zones_new: "New Store Zone",
            lbl_store_zones_zones: "Zones",
            // zones
            lbl_zones: "Zones",
            lbl_zones_name: "Zone Name",
            lbl_store_zones_name: "Service Zones",
            lbl_zones_new: "New Zone",
            lbl_zones_state: "State",
            lbl_zones_name_ar: "Zone Name in Arabic",
            lbl_zones_name_en: "Zone Name in English",
            lbl_close: "Close",
            lbl_okay: "Okay",
            lbl_zones_choose: "Choose Zone:",
            //
            lbl_sales_orders_o_history: "History",
            //
            lbl_marketing: "Marketing",
            lbl_marketing_campaign: "Messages",
            lbl_marketing_campaign_title: "Title",
            lbl_marketing_campaign_message: "Message",
            lbl_marketing_campaign_partner_type: "Target",
            lbl_marketing_campaign_zones: "Zones",
            //
            lbl_category_id: "Category",
            lbl_toggle_selection: "Select/Unselect All",
            lbl_sales_orders_o_cancel_reason: "Cancel reason:",
            lbl_sales_cart_store_cart_items_not_available: "Not available.",
            lbl_error_message_checkout_from: ' Error : Only Customer can Select Placed Order For this Payment Mode',
            lbl_select: "Select",
            lbl_checkout_form: "Checkout Information",
            lbl_payment_mode: "Payment Mode",
            lbl_checkout_mode: 'Checkout Mode',
            lbl_topup: "TopUp",
            lbl_topup_partners: "TopUp Partners",
            lbl_topup_partners_topup_information: "TopUp Information",
            lbl_topup_partners_topupfeestype: "Fees Type",
            lbl_topup_partners_topupfeesvalue: "Fees Value",
            lbl_topup_partners_topupcostvalue: "Cost Value",
            lbl_topup_partners_topupcosttype: "Cost Type",
            lbl_topup_partners_platformpercentage: "Platform Percentage",
            lbl_topup_partners_partnerpercentage: "Partner Percentage",
            lbl_topup_partners_topupcashbacktype: "Cashback Type",
            lbl_topup_partners_topupcashbackvalue: "Cashback Value",
            lbl_topup_partners_paylaterterms: "Pay Later Terms",
            lbl_topup_partners_terms: "Terms",
            lbl_topup_partners_minimumtopup: "Minimum TopUp",
            lbl_topup_partners_topupcode: "TopUp Code",
            lbl_finance_documents_paymentmethodid: "Payment Method",
            lbl_reports_documents_documentid: "Document Id",
            lbl_reports_documents_description: "Description",
            lbl_reports_documents_amount: "Amount",
            lbl_reports_documents_documenttype: "Document Type",
            lbl_reports_documents_documentdate: "Document Date",
            lbl_reports_documents_partner: "Partner",
            lbl_reports_documents_paymentmethod: "Payment Method",
            lbl_reports_documents_paymentreference: "Payment Reference",
            lbl_reports_documents_site: "Site",
            lbl_reports_documents_totalcount: "Total Count",
            lbl_reports_documents_totalamount: "Total Amount",
            lbl_reports_statements_transactionid: "Transaction Id",
            lbl_reports_statements_description: "Description",
            lbl_reports_statements_type: "Type",
            lbl_reports_statements_txndate: "Txn Date",
            lbl_reports_statements_createdat: "Created At",
            lbl_users_account_name: "Account",
            lbl_reports_statements_balance: "Balance",
            lbl_reports_statements_creditamount: "Credit",
            lbl_reports_statements_debitamount: "Debit",
            lbl_reports_invoices_invoiceid: "Invoice Id",
            lbl_reports_invoices_description: "Description",
            lbl_reports_invoices_invoicedate: "Invoice Date",
            lbl_reports_invoices_amount: "Amount",
            lbl_reports_invoices_tax: "Tax",
            lbl_reports_invoices_total: "Total",
            lbl_reports_invoices_partner: "Partner",
            lbl_reports_trialbalance_accounttitle: "Account",
            lbl_reports_trialbalance_accounttype: "Account Type",
            lbl_reports_trialbalance_partnername: "Partner",
            lbl_reports_trialbalance_balance: "Balance",
            lbl_reports_financial_statements_opertion: "Operation",
            lbl_reports_financial_statements_value: "",
            lbl_rr_statements_op_debit: "Debit",
            lbl_rr_statements_op_credit: "Credit",
            lbl_topup_partners_topupthroughus: "TopUp Through Us",
            lbl_topup_partners_instructionurl: "Instruction Url",
            lbl_topup_partners_channel: "Channel",
            lbl_topup_partners_walletid: "Wallet Id",
            lbl_topup_partners_maximumtopup: "Maximum TopUp",
            lbl_topup_transactions: "Transactions",
            lbl_topup_wallet_transactions: "Wallet Transactions",
            lbl_topup_txn_txntopupid: "TXN. Id",
            lbl_topup_txn_createdat: "TXN. Date",
            lbl_topup_txn_topuppartner: "TopUp Partner",
            lbl_topup_txn_partner: "Partner",
            lbl_topup_txn_topuptype: "TopUp Type",
            lbl_topup_txn_txnvalue: "TXN. Value",
            lbl_topup_txn_txnstatus: "TXN. Status",
            lbl_topup_txn_txnfeestotal: "Total Fees",
            lbl_topup_txn_txnfeespartner: "Partner Fees",
            lbl_topup_txn_txnfeesplatform: "Platform Fees",
            lbl_topup_txn_txncbvalue: "Cashback Value",
            lbl_topup_txn_txnnetvalue: "Net. Value",
            lbl_topup_txn_txnlog: "TXN. Log",
            lbl_topup_txn_referenceid: "Reference Id",
            lbl_topup_txn_txnstatus_1: "Initiated",
            lbl_topup_txn_txnstatus_2: "Confirmed",
            lbl_topup_txn_txnstatus_3: "Cancelled",
            lbl_wallet_transaction_wallet_transaction_id: "Id",
            lbl_wallet_transaction_wallet_transaction_reference: "Wallet Reference",
            lbl_wallet_transaction_wallet_id: "Wallet Id",
            lbl_wallet_transaction_amount: "Amount",
            lbl_wallet_transaction_transaction_time: "Transaction Time",
            lbl_wallet_transaction_channel: "Channel",
            lbl_wallet_transaction_debitor: "Debitor",
            lbl_wallet_transaction_description: "Description",
            lbl_faield_reason: "Failed Reason",
            lbl_wallet_transaction_status: "Status",
            lbl_otp_message: 'We’ve sent an OTP to our client. Kindly ask the client for the OTP in order to complete the delivery process.',
            lbl_checkout_confirm_meesage_1: 'We will notify the customer about this cart to proceed in placing order. Are you sure you want to continue ?',
            lbl_checkout_confirm_meesage: 'You are about to place order on behalf of the customer. We assume that you already arrange with your customer about the order and the payment. Are you sure you want to continue ?',
            uploadNotes1: 'If you check the update box then Mawrid will update your current catalogue with the new values. E.g. all existing products that doesn’t exists in the new catalogue will stay and if the new catalogue contains any product that already exists Mawrid will update the information of the new product.',
            uploadNotes2: 'If you didn’t check the update box then Mawrid will remove all previous products and add the products in the new catalogue. Note: This will have no impact on any placed order.',
            lbl_continue: 'Continue',
            lbl_delete_1: "We are sorry to see you go, but if you’ve decided then here are a few points that you need to know:",
            lbl_delete_2: "Once your account is deleted, you can’t reactivate it, recover any data, or regain access. You will need to set up a new account if you want to use Mawrid Again.",
            lbl_delete_3: "When you submit your request to delete your account:",
            lbl_delete_4: "We will verify your identity for security purposes before accepting the request.",
            lbl_delete_5: "Once your request is processed, your personal information will be deleted except for certain information that we’re legally required to keep.",
            lbl_delete_6: "This process could take up to 60 days.",
            lbl_delete_7: "Before submitting your request to delete your account please check the following:",
            lbl_delete_8: "You need to complete any ongoing orders on the system.",
            lbl_delete_9: "You need to make sure that you Mawrid balance is Zero.",
            lbl_delete_10: "Once you submit your request you will be automatically logged out and you should receive a call from our customer service team to complete the deletion process.",
            lbl_import_helper: "Import Helper",
            lbl_import_task_id: "Import Task Id",
            lbl_import_task_created_at: "Created At",
            lbl_import_task_duration: "Duration",
            lbl_mapping: "Mapping",
            lbl_itemId: "Item id",
            lbl_ItemName: "Item name",
            lbl_ItemPrice: "Price",
            lbl_Discount: "Discount",
            lbl_ExpireDate: "Expire Date",
            lbl_itemBonus: "Bonus offer",
            lbl_itemDiscountOffer: "Discount offer",
            lbl_index: "Index",
            lbl_indices: "Indices",
            lbl_format: "Format (ex. 01/12/2027 => dd/MM/yyyy)",
            lbl_separator: "separator",
            lbl_algorithm: "Algorithm",
            lbl_SearchEngineOptions: "Search option",

            //sales offer

            lbl_sales_offer_list_title: "Sales offer",
            lbl_sales_offer_header: "Sales offer",
            lbl_sales_offer_price_list: "Price list",
            lbl_sales_offer_expire_date: "Expire date",
            lbl_sales_offer_description: "Description",
            lbl_sales_offer_store_name: "Store",
            lbl_sales_offer_title: "Title",
            lbl_sales_offer_store_product_name: "Product name",
            lbl_sales_offer_store_product_qty: "Quantity",
            lbl_sales_offer_store_product_discountType: "Discount type",
            lbl_sales_offer_store_product_discountVal: "Discount value",
            lbl_sales_offer_store_product_price: "Price",
            lbl_sales_offer_new: "Add sales offer",
            lbl_sales_offer_active: "Active",
            lbl_sales_offer_store_product_tax: "Tax",
            lbl_sales_offer_items: "Items",
            lbl_sales_offer_items_error: "You need to add at least one product",
            lbl_sales_offer_items_exists_error: "You already added the product",
            lbl_add_seles_offer_item_title: "Add item",

            lbl_sales_offer_sub_total: "Sub total",
            lbl_sales_offer_gross_total: "Gross total",
            lbl_sales_offer_total_tax: "Total tax",
            lbl_sales_offer_total: "Total",
            lbl_sales_offer_total_discounts: "Total discounts",
            //payment partner
            lbl_payments: "Payments",
            lbl_payment_partners: "Payment Partners",
            lbl_payment_partner_active: "Active",
            lbl_partners_partner_title_ar: "Title (Arabic)",
            lbl_partners_partner_title_en: "Title (English)",
            lbl_payment_partners_payment_information: "Payment info",
            lbl_payment_partners_transferCost: "Transfer cost",
            lbl_payment_partners_transferSelling: "Transfer selling",
            lbl_payment_partners_cashbackPercentage: "Cashback percentage",
            lbl_payment_partners_infoUrl: "info url",
            lbl_partners_uniqueid: "Unique Id",
            lbl_payment_partners_otpLength: "Otp Length",
            lbl_payment_partners_paySellerOnDeliver: "Pay seller on deliver",
            lbl_payment_partners_transferOnDeliver: "Transfer on deliver",

            //wrong orders
            lbl_payment_report: "Payment Reports",
            lbl_payment_delivered: "Delivered Report",
            lbl_payment_shipped: "Shipped Report",
            lbl_payment_placed: "Placed Report",

            //payment transactions
            lbl_payment_transactions: "Payment transactions",
            lbl_payment_transactions_id: "Payment transaction Id",
            lbl_payment_txn_paymentTransactionId: "Transaction ID",
            lbl_payment_txn_createdat: "Create date",
            lbl_payment_txn_payment_partner: "Payment name",
            lbl_payment_txn_seller_partner: "Seller name",
            lbl_payment_txn_buyer_partner: "Buyer name",
            lbl_payment_txn_amount: "Amount",
            lbl_payment_txn_txnstatus: "Status",
            lbl_payment_txn_mawrid_unique_reference: "Mawrid reference",
            lbl_payment_txn_partner_unique_reference: "Partner reference",
            lbl_payment_txn_type: "Type",
            lbl_payment_txn_state: "State",
            lbl_topup_txn_otp_trials: "Otp trials",
            lbl_transaction_details: "Transaction details",


            //payment communications
            lbl_payment_communication: "Communication ",
            lbl_payment_communication_id: "Id",
            lbl_payment_communication_create_at: "Create at",
            lbl_payment_communication_response_status: "Response status",
            lbl_communication_response: "Response",
            lbl_communication_request: "Request",
            lbl_cahnge_category: "Update Category",

            //payment broken order

            lbl_broken_orders: "Broken Orders",
            lbl_broken_orders_financialBrokenOrderId: "Id",
            lbl_broken_orders_createdat: "Create date",
            lbl_broken_orders_seller_partner: "Seller",
            lbl_broken_orders_buyer_partner: "Buyer",
            lbl_broken_orders_amount: "Amount",
            lbl_broken_orders_reason: "Reason",


            //payment orders
            lbl_payment_orders: "Payment Orders",
            lbl_payment_orders_orderId: "Order Id",
            lbl_payment_orders_id: "Payment order Id",
            lbl_payment_orders_paymentTransactionId: "Id",
            lbl_payment_orders_createdat: "Create date",
            lbl_payment_orders_seller_partner: "Seller",
            lbl_payment_orders_buyer_partner: "Buyer",
            lbl_payment_orders_payment_partner: "Payment",
            lbl_payment_orders_amount: "Amount",
            lbl_payment_orders_release: "Release",
            lbl_payment_orders_payment_amount: "Payment amount",
            lbl_payment_orders_cart_id: "Cart id",
            lbl_payment_orders_block_amount_trials: "Block amount trials",
            lbl_payment_orders_status: "Payment Order Status",
            lbl_payment_orders_order_total: "Order total",

            //delivery partner
            lbl_delivery: "Delivery",
            lbl_delivery_partners: "Delivery partners",
            lbl_delivery_partners_codCost: "COD cost",
            lbl_delivery_partners_codSelling: "COD selling",
            lbl_delivery_partners_costs_information: "Costs information",
            lbl_delivery_cost_state: "State",
            lbl_delivery_cost: "Delivery cost",
            lbl_delivery_selling: "Delivery selling",
            lbl_delivery_costs: "Delivery costs",
            lbl_delivery_partners_requiredOtp: "Otp required",
            lbl_partners_partner_mawrid_unique_Id: "Mawrid Id",
            //paymentransactionStatuses
            lbl_payment_txn_txnstatus_1: "OTP sent",
            lbl_payment_txn_txnstatus_2: "Amount Reserved",
            lbl_payment_txn_txnstatus_3: "Canceled",

            //payment order status
            lbl_payment_order_status_1: "INITIATED",
            lbl_payment_order_status_2: "AMOUNT_BLOCKED",
            lbl_payment_order_status_3: "PAID",
            lbl_payment_order_status_4: "CANCEL",

            //paymentTransactionTypes
            lbl_payment_txn_txntype_1: "Order",
            lbl_payment_txn_txntype_2: "Return Order",
            lbl_payment_txn_txntype_3: "Payment voucher",
            lbl_payment_txn_txntype_4: "Receipt voucher",
            lbl_payment_txn_txntype_5: "Credit note",
            lbl_payment_txn_txntype_6: "Debit note",
            lbl_payment_txn_txntype_7: "Tawreed commission",
            lbl_payment_txn_txntype_8: "Return Tawreed commission",
            lbl_payment_txn_txntype_9: "Top fees",
            lbl_payment_txn_txntype_10: "TOPUP cashback",
            lbl_payment_txn_txntype_11: "TOPUP",
            lbl_payment_txn_txntype_12: "TOPUP fees expenses",
            lbl_payment_txn_txntype_13: "Tax",
            lbl_eq_bonus_operation: "Equals(=)",
            lbl_gte_bonus_operation: "Greater than(<)",
            lbl_inbound: "Increase",
            lbl_outbound: "Decrease",
            lbl_stock_reset: "Reset",

            lbl_movement_source_order: "Order",
            lbl_movement_source_manual: "Manual",
            lbl_movement_source_update: "Update",
            lbl_movement_source_external: "External",
            lbl_movement_source_import: "Import",

            lbl_stock_history: "Stock history",
            lbl_stock_stock_id: "ID",
            lbl_stock_movement_type: "Movment Type",
            lbl_stock_movement_source: "Movment Source",
            lbl_stock_quantity: "Qty",
            lbl_stock_stock_balance: "Balance",
            lbl_stock_reference_id: "Ref id",



            lbl_readyToDeliver_number_of_package: "Package number",
            lbl_readyToDeliver: "Please specify how many packages your order contains and provide additional information about the packages if needed",
            lbl_readyToDeliverWithFiles: "Please specify how many packages your order contains With Files",
            lbl_readyToDeliverNote: "Note: Store will be selected as delivery",
            lbl_readyToDeliver_remark: "Remark",
            lbl_readyToDeliver_requireRefregerator: "Refrigerator",

            //mapping
            lbl_store_delivery_mapping_list: "Mapping",
            lbl_store_delivery_mapping_delivery_name: "Delivery",
            lbl_store_delivery_mapping_add: "Add",
            lbl_store_delivery_mapping_value: "Value",

            //finance dashboard
            
            lbl_finance_header:"Details",
            lbl_stores_liabilities: "Stores Liabilities",
            lbl_stores_dues: "Stores Dues",
            lbl_topup_balances: "TOPUP Balances",
            lbl_customer_wallet_balances: "Customer Wallet Balances",
            lbl_bank_balances: "Bank Balances"

        });
        addLocale("ar", {
            // Primereact
            startsWith: 'يبدأ بـ',
            contains: 'يحتوي',
            notContains: 'لا يحتوي',
            endsWith: 'ينتهي بـ',
            equals: 'يساوي',
            notEquals: 'لا يساوي',
            noFilter: 'لا يوجد تصفية',
            lt: 'أقل من',
            lte: 'أقل من أو يساوي',
            gt: 'أكبر من',
            gte: 'أكبر من أو يساوي',
            dateIs: 'يساوي',
            dateIsNot: 'لا يساوي',
            dateBefore: 'قبل',
            dateAfter: 'بعد',
            custom: 'Custom',
            clear: 'مسح',
            apply: 'تطبيق',
            matchAll: 'Match All',
            matchAny: 'Match Any',
            addRule: 'إضافة تصفية',
            removeRule: 'حذف تصفية',
            accept: 'نعم',
            reject: 'لا',
            choose: 'اختر',
            upload: 'تحميل',
            cancel: 'إلغاء',
            dayNames: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
            dayNamesShort: ['أحد', 'إثنين', 'ثلاثاء', 'أربعاء', 'خميس', 'جمعة', 'سبت'],
            dayNamesMin: ['أحد', 'إثنين', 'ثلاثاء', 'أربعاء', 'خميس', 'جمعة', 'سبت'],
            monthNames: ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'],
            monthNamesShort: ['يناير', 'فبراير', 'مارس', 'إبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'],
            today: 'اليوم',
            weekHeader: 'Wk',
            firstDayOfWeek: 6,
            dateFormat: 'dd/mm/yyyy',
            weak: 'أسبوع',
            medium: 'وسط',
            strong: 'قوي',
            passwordPrompt: 'Enter a password',
            emptyFilterMessage: 'لايوجد نتائج',
            emptyMessage: 'لايوجد نتائج',
            lbl_price: "السعر",
            lbl_tax: "الضريبة",
            lbl_discount: "الخصم",
            lbl_quantity: "الكمية",
            lbl_sellingPrice: "السعر بعد الخصم",
            lbl_sellingPrice_befor_per_unit: "سعر القطعة قبل الخصم",
            lbl_sellingPrice_befor_after_unit: "سعر القطعة بعد الخصم",
            lbl_bonus: "بونص",
            lbl_Discounts: "الخصومات",
            lbl_base_discount: "الخصم الاساسي",
            lbl_additional_discount: "الخصم الاضافي",
            lbl_additional_discount_unit: "الخصم الاضافي (د.أ)",
            lbl_sub_total: "الإجمالي قبل الضريبة",
            lbl_sub_total1: "الإجمالي قبل الضريبة (JOD)",
            lbl_total_tax: "اجمالي الضريبة",
            lbl_total: "الاجمالي الكلي",
            lbl_total_unit: "الاجمالي الكلي (د.أ)",
            lbl_total1: "إجمالي المنتج (JOD)",
            // Notification
            lbl_notifications: "الإشعارات",
            lbl_notifications_mark_as_read: "تعيين كمقروء",
            lbl_notifications_filter_mode_all: "الكل",
            lbl_notifications_filter_mode_unread: "غير مقروء",
            lbl_notification_summary: "",
            lbl_notification_summary_success: "تأكيد",
            lbl_notification_summary_info: "إشعار",
            lbl_notification_summary_warn: "تنبيه",
            lbl_notification_summary_error: "خطأ",
            lbl_clear_filters: "مسح",
            //
            lbl_paginator_curr_of: "من",
            lbl_footer_app_created_by: "by",
            lbl_items_selected: "تم تحديد {0} عناصر",
            msg_field_is_required: "هذا الحقل مطلوب.",
            msg_cat_error: "فئة المنتج غير صحيحة. اختر واحدة أخرى",
            msg_field_is_too_long: "عدد الأحرف تجاوز الحد الأقصى.",
            msg_field_max_allowed_exceeded: "القيمة العليا المسموح بها هي {0}",
            msg_field_min_allowed_exceeded: "القيمة الدنيا المسموح بها هي {0}",
            lbl_language_toggle: "اللغة",
            lbl_ar_language_toggle: "العربية",
            lbl_en_language_toggle: "English",
            lbl_home: "الرئيسية",
            lbl_all: "الكل",
            lbl_back_to_catalog: "رجوع إلى الكتالوج",
            lbl_previous: "السابق",
            lbl_back: "رجوع",
            lbl_reset: "مسح",
            lbl_create: "جديد",
            lbl_save: "حفظ",
            lbl_search: "نص، رقم أو تاريخ (yyyy-MM-dd)",
            lbl_activate_all: "تفعيل ({0})",
            lbl_deactivate_all: "إلغاء التفعيل ({0})",
            lbl_activate: "تفعيل",
            lbl_deactivate: "إلغاء التفعيل",
            lbl_login: "تسجيل الدخول إلى مورد",
            lbl_logout: "تسجيل خروج",
            lbl_login_username: "اسم المستخدم",
            lbl_login_password: "كلمة المرور",
            lbl_login_remember_me: "تذكرني",
            lbl_login_submit: "دخول",
            // Dialog
            tawreed_dialog_confirm_msg: 'هل أنت متأكد من اكمال الإجراء؟',
            tawreed_dialog_confirm_hdr: 'تأكيد',
            tawreed_dialog_confirm_delete_msg: 'هل أنت متأكد من حذف هذا العنصر (العناصر)؟',
            tawreed_dialog_confirm_delete_hdr: 'تأكيد الحذف',
            // roles
            lbl_roles_1: "مدير النظام",
            lbl_roles_2: "مدير",
            lbl_roles_3: "توصيل",
            lbl_roles_4: "مبيعات",
            lbl_roles_5: "مورد",
            lbl_roles_6: "عميل",
            lbl_roles_7: "Store Delivery",
            lbl_roles_8: "TopUp Admin",
            lbl_roles_9: "Wallet Admin",
            lbl_roles_10: "Cat Maanager",
            lbl_roles_11: "Sys Admin",
            lbl_roles_12: "Finance",
            //discounts types
            lbl_discountType_1_value: "fixed",
            lbl_discountType_1_label: "ثابت",
            lbl_discountType_2_value: "percentage",
            lbl_discountType_2_label: "نسبة",
            lbl_discountType_3_value: "bonus",
            lbl_discountType_3_label: "بونص",
            //checkoutModes
            lbl_checkout_mode_notify: 'ارسال اشعار للزبون',
            lbl_checkout_mode_place: "طلبية",

            // users
            lbl_management: "الإعدادات",
            lbl_category: "الاصناف",
            lbl_banner: "لافتات",
            lbl_banner_id: "لافتة",
            lbl_category_ar: "اسم الصنف (عربي)",
            lbl_category_en: "اسم الصنف (انكليزي)",

            lbl_users: "المستخدمين",
            lbl_users_full_name: "الاسم",
            lbl_users_partner_name: "الشريك",
            lbl_users_user_name: "اسم الدخول",
            lbl_users_locked: "نشط",
            msg_users_emailormobilenumberrequired: "يجب إدخال البريد الاكتروني أو اسم المستخدم أو رقم الهاتف",
            lbl_users_is_locked: "غير نشط",
            lbl_users_is_not_locked: "نشط",
            lbl_users_partner_type: "النوع",
            lbl_change_password: "تغيير كلمة المرور",
            lbl_partner_information: "معلومات الشريك",
            lbl_users_user: "المستخدم",
            lbl_users_first_name: "الاسم الأول",
            lbl_users_last_name: "الاسم الأخير",
            lbl_users_email: "البريد الالكتروني أو اسم المستخدم",
            lbl_users_site_id: "الموقع",
            lbl_users_mobile_no: "رقم الهاتف",
            lbl_users_language: "اللغة",
            lbl_users_role: "الدور",
            lbl_users_new_password: "كلمة المرور الجديدة",
            lbl_users_confirmed_password: "تأكيد كلمة المرور",
            lbl_users_new_password_required: "كلمة المرور ضرورية",
            lbl_users_confirmed_password_required: "تأكيد كلمة المرور ضرورية",
            lbl_users_confirmed_password_not_equal: "كلمات المرور غير متطايقة",
            // Partners
            lbl_partners: "الشركاء",
            lbl_partners_all: "الكل",
            lbl_partners_1: "الموردين",
            lbl_partners_2: "العملاء",
            lbl_partners_7: "شركاء الدفع",
            lbl_partners_4: "شحن الرصيد",
            lbl_partner: "الحساب",
            lbl_partner_0: "توريد",
            lbl_partner_1: "مورد",
            lbl_partner_2: "عميل",
            lbl_partner_3: "شحن",
            lbl_partner_4: "تعبئة رصيد",
            lbl_wallet_status_Empty: "لم ينشأ",
            lbl_wallet_status_Active: "فعال",
            lbl_wallet_status_Inactive: "غير فعال",
            lbl_wallet_status_Pending: "قيد الانتظار",
            lbl_wallet_status: "حالة الحافظة",
            lbl_partners_business_type: "نوع العمل",
            lbl_partners_partnerid: "رقم العميل",
            lbl_partners_balanceid: "رقم الحساب",
            lbl_partners_partner_name: "الاسم",
            lbl_partners_status_id: "الحالة",
            lbl_partners_partner_type: "النوع",
            lbl_partners_start_date: "تاريخ التسجيل",
            lbl_partners_address_information: "بيانات العنوان",
            lbl_partners_commission_percent: "العمولة",
            lbl_partners_order_min: "الحد الأدنى للطلب",
            lbl_partners_national_id: "National ID",
            lbl_partners_establishmentnationalid: "معرف المؤسسة الوطنية",
            lbl_partners_license_number: "رقم الرخصة",
            lbl_partners_licence_expire_date: "License Expire Date",
            lbl_sales_orders_pricelistid: "فئة السعر",
            lbl_sales_orders_paymentmodeid: "نمط الدفع",
            msg_partners_files_required: "هذه الملفات مطلوبة {0}.",
            lbl_upload: "تحميل",
            lbl_download: "تنزيل",
            lbl_partners_logo: "الشعار",
            lbl_partners_priority: "الأولوية",
            lbl_partners_contact_information: "بيانات الاتصال",
            lbl_partners_contact_name: "الاسم",
            lbl_partners_contact_mobile: "رقم الموبايل",
            lbl_partners_contact_email: 'البريد الاكتروني',
            lbl_partners_address_zone: "المنطقة",
            lbl_partners_address_state_id: "المدينة",
            lbl_partners_address_street: "العنوان",
            lbl_partners_address_latitude: "خط العرض",
            lbl_partners_address_longitude: "خط الطول",
            lbl_partners_address_location: "عرض على الخريطة",
            lbl_partners_address_address: "العنوان",
            lbl_partners_address_city_id: "المنطقة",
            lbl_partners_files: "الملفات",
            lbl_partners_new: "جديد",
            lbl_partners_verified: "موثق",
            lbl_partners_active: "نشط",
            lbl_partners_not_active: "غير نشط",
            //partner connection
            lbl_add_connection: "إضافة",
            lbl_partners_Classification: "الفئة",
            lbl_connections: "علاقات العميل",
            lbl_set: "إضافة فئة ({0})",
            lbl_remove: "حذف الفئة ({0})",
            lbl_setItem: "إضافة فئة",
            lbl_removeItem: "حذف الفئة",
            lbl_partners_from_partner_name: "اسم العميل المصدر",
            lbl_partners_to_partner_name: "اسم العميل",
            // catalog
            lbl_catalog: "الكتالوج",
            lbl_products: "المنتجات",
            lbl_unverified_products: "المنتجات غير مُتحققة",
            lbl_products_upload: "الكتالوج الفرعي",
            lbl_success: "تم بنجاح",
            lbl_failed: "فشلت",
            lbl_cancel: "إلغاء",
            lbl_next: "التالي",
            lbl_add: "إضافة",
            lbl_edit: "تعديل",
            lbl_accept: "موافق",
            lbl_store_products_import: "رفع قائمة منتجات جديدة",
            lbl_store_products_download: "تحميل قائمة المنتجات",
            lbl_store_products_download_template: "تحميل نموذج قائمة المنتجات",
            lbl_store_id: "المورد",
            lbl_file: "الملف",
            lbl_store_products_store_product_name: "اسم المنتج (المورد)",
            lbl_store_products_product_id: "اسم المنتج",
            lbl_store_products_id: "المعرف",
            msg_update_unverified_category: "لا يمكن تعديل صنف لمنتج غير موثق",
            lbl_store_products_product_id_ar: "اسم المنتج (عربي)",
            lbl_store_products_product_id_en: "اسم المنتج (انكليزي)",
            lbl_store_products_retail_price: "السعر",
            lbl_store_products_discount_percent: "الخصم",
            lbl_store_products_store_name: "المورد",
            lbl_store_products_customer_name: "العميل",
            lbl_store_products_qty: "الكمية",
            lbl_store_products_class: "الفئة",
            lbl_store_products_bonus: "بونص",
            lbl_store_products_bonus_title: "عروض البونص",
            lbl_store_products_discount_bonus_title: "عروض الخصم النقدي",
            lbl_store_products_discount_bonus: "العروض والحسومات",
            lbl_store_products_discount_bonus_val: "قيمة الخصم",
            lbl_store_products_bonus_qty: "الكمية",
            lbl_otp_code: "رمز التحقق",
            lbl_store_products_bonus_value: "القيمة",
            lbl_store_products_tax: "الضريبة",
            lbl_store_product_item_id: "المعرف",
            lbl_store_product_store_hint: "إذا كنت تستخدم نظامك الخاص للمنتجات ، فالرجاء إدخال معرف المنتج الخاص بك",
            lbl_store_product_expire_date: "تاريخ انتهاء الصلاحية",
            lbl_min_order_qty: "أقل كمية ممكن طلبها",
            lbl_max_order_qty: "أكثر كمية ممكن طلبها",
            lbl_store_product_browse: "عرض المنتجات",
            lbl_store_product_all_browse: "عرض جميع المنتجات",
            lbl_store_product_description: "الوصف",
            lbl_price_scheme_price: "السعر قبل الضريبة",
            lbl_price_scheme_discountValue: "قيمة الخصم",
            lbl_price_scheme_discountType: "نوع الخصم",
            lbl_bonus_operation: "العملية",
            lbl_price_scheme_price_list_name: "قائمة الاسعار",
            lbl_old_batch: "تحديث المنتجات الحالية",
            lbl_add_base_product: "اضافة المتجات الغير موجودة الى المساتر كاتالوغ",
            lbl_delete_my_account: "حذف حسابي",
            lbl_commission_list: "قائمة العمولة",
            lbl_commission_error: "يجب تعبئة جميع الحقول الخاصة بالعمولة",
            lbl_city_error: "الرجاء اختيار مدينة صحيحة",
            lbl_bonus_error: "الخصم موجود مسبقا",
            lbl_price_list_error: "يجب تعبئة سعر واحد على الاقل",
            lbl_price_list_commission_error: 'لا يمكن أن يكون السعر بعد الخصم أقل من العمولة',
            lbl_selling_price_error: "لا يمكن أن يكون السعر بعد الخصم أقل من 0",
            lbl_commission_value: "قيمة العمولة",
            lbl_commission_type: "نوع العمولة",
            lbl_selling_price: "السعر بعد الخصم",
            lbl_selling_price1: "السعر بعد الخصم (JOD)",
            lbl_cleanup: "تنظيف عمليات التعبئة",
            lbl_delete: "حذف",
            lbl_add_price: "إصافة سعر",
            lbl_delete_all: "حذف ({0})",
            lbl_new: "جديد",
            lbl_store_product_new: "اضافة منتج",
            lbl_info: "Info",
            lbl_product: "المنتج",
            lbl_product_id: "رقم المنتج",
            lbl_product_international_code: "الرمز الموحد",
            lbl_product_product_tax: "ضريبة المنتج ",
            lbl_product_price: "سعر المنتج",
            lbl_product_keywords: "الكلمات المفتاحية",
            lbl_product_tax: "الضريبة",
            lbl_product_dosage: "الجرعة",
            lbl_product_agent: "الوكيل",
            lbl_product_filling: "التعبئة",
            lbl_product_concentrate: "التركز",
            lbl_product_ingredient: "المكونات",
            lbl_product_industryCmp: "الصناعة",
            lbl_product_active: "نشط",
            lbl_products_active: "نشط",
            lbl_products_not_active: "غير نشط",
            lbl_product_name: "الاسم",
            lbl_product_category: "الفئة",
            lbl_product_seller_count: "المتاجر",
            lbl_product_verified: "موثق",
            lbl_product_unverified: "غير موثق",
            lbl_product_categories: "أصناف المنتج",
            lbl_product_name_en: "الاسم (إنجليزي)",
            lbl_whiteSapce_field: "الرجاء ادخال قيمة صحيحة",
            lbl_store_product_title_en: "الاسم (إنجليزي)",
            lbl_store_product_title_ar: "الاسم (عربي)",
            lbl_company: "الشركة",
            lbl_company_en: "الشركة (إنجليزي)",
            lbl_displayName: "الأسم الكامل",
            lbl_diaplay_name_formula: "العلامة التجارية - اسم المنتج - تركيز الدواء - تعبئة الدواء - الجرعة",
            displayName: "الاسم المعروض",
            lbl_generic: "التركيبة",
            lbl_generic_en: "التركيبة (إنجليزي)",
            lbl_active: "نشط",
            lbl_not_active: "غير نشط",
            lbl_page_customers: "العملاء",
            lbl_page_customer_new: "New Customer",
            lbl_page_customer_details: "Customer Details",
            lbl_page_customer_edit: "Edit Customer",
            lbl_page_stores: "الموردين",
            lbl_page_store_new: "New Store",
            lbl_page_store_details: "Store Details",
            lbl_page_store_edit: "Edit Store",
            lbl_product_new: "New Product",
            lbl_retail_price: "السعر",
            msg_save_succeeded: "تم بنجاح",
            msg_def_prompt_header: "تأكيد",
            msg_def_prompt_message: "هل أنت متأكد؟",
            msg_def_prompt_accept_label: "نعم",
            msg_def_prompt_reject_label: "إلغاء",
            msg_upload_completed_successfully: "تم بنجاح",
            msg_unknown_error: "حدث خطأ",
            lbl_dashboard: "لوحة التحكم",
            lbl_store_products: "الكتالوج الرئيسي",
            lbl_store_product: "الكتالوج الرئيسي",
            lbl_store_product_active: "نشط",
            lbl_store_name: "المورد",
            lbl_discount_percent: "الخصم",
            lbl_products_variants: "المرادفات",
            lbl_general_information: "البيانات الرئيسية",
            lbl_quantity_trackin: "تتبع الكميات",
            lbl_product_images: "ًصور المنتج",
            lbl_category_image: 'صورة المنتج',
            lbl_Banner_image: "صورة اللافتة",
            lbl_verify: "توثيق",
            lbl_link: "ربط",
            lbl_create_wallet: "انشاء محفظة",
            lbl_enable_upload: "تفعيل تحميل الوثائق",
            lbl_enable: "تفعيل",
            lbl_disable: "الغاء تفعيل",
            lbl_unenable_upload: "الغاء تفعيل تحميل الوثائق",
            lbl_trade_license: 'رخصة المهن',
            lbl_logo: 'الشعار',
            lbl_trade_registry: 'السجل التجاري',
            lbl_sales_carts: "السلة",
            lbl_sales_cart: "السلة {0}",
            lbl_sales_cart_customer_information: "بيانات العميل",
            lbl_sales_carts_customer_partner_name: "الاسم",
            lbl_files: "الملفات",
            lbl_files_filename: "اسم الملف",
            lbl_files_file: "الملف",
            lbl_sales_cart_products: "بيانات المنتج",
            lbl_sales_carts_products_product_id: "الاسم",
            lbl_sales_carts_products_quantity: "الكمية",
            lbl_add_to_cart: "إضافة",
            lbl_otp: "التحقق",
            lbl_sales_carts_products_store_name: "المورد",
            lbl_sales_carts_products_retail_price: "السعر",
            lbl_sales_carts_products_discount_percent: "الخصم",
            lbl_sales_carts_products_sale_price: "سعر البيع",
            lbl_sales_cart_summary: "الملخص",
            lbl_sales_cart_summary_bonus: "الملخص باستخدام البونص",
            lbl_sales_cart_summary_discount: "الملخص باستخدام الخصومات",
            lbl_sales_cart_sub_total: "الإجمالي الفرعي",
            lbl_sales_cart_gross_total: "الإجمالي",
            lbl_sales_cart_gross_total_1: "الإجمالي الكلي",
            lbl_sales_cart_total_tax: "اجمالي الضريبة",
            lbl_sales_cart_total: "الإجمالي",
            lbl_sales_cart_shipping_total: "رسوم الشحن",
            lbl_sales_cart_total_discounts: "إجمالي الخصم",
            lbl_sales_cart_order_total: "الإجمالي",
            lbl_checkout_all: "اطلب الآن",
            lbl_checkout: "اطلب الآن",
            lbl_checkout_note: "الملاحظة",
            lbl_sales_cart_store_cart_items_product_name: "المنتج",
            lbl_sales_cart_store_cart_items_quantity: "الكمية",
            lbl_sales_cart_store_cart_items_retail_price: "سعر القطعة",
            lbl_sales_cart_store_cart_items_retail_price_unit: "سعر القطعة (د.أ)",
            lbl_sales_cart_store_cart_items_discount_percent: "الخصم",
            lbl_sales_cart_store_cart_items_item_total: "الإجمالي",
            lbl_sales_cart_store_cart_items_item_total_discount: "أجمالي الخصم",
            lbl_sales_cart_store_cart_items_item_total_tax: "اجمالي الضريبة",
            lbl_sales_cart_store_cart_items_item_total_tax1: "الضريبة (%) ",
            lbl_sales_cart_customer: "العميل",
            lbl_sales_cart_order_totals: "الإجمالي",
            lbl_sales_cart_type: "النوع",
            lbl_sales_cart_types_1: "من قبل العميل",
            lbl_sales_cart_types_2: "من قبل المتجر (المتجر)",
            lbl_sales_cart_types_3: "من قبل المتجر (العميل)",
            lbl_sales_cart_orders_count_total: "عدد الطلبيات",
            msg_sales_cart_checkout_dialog_1: "لم تستكمل الحد الأدنى للشركات التالية:",
            msg_sales_cart_checkout_dialog_2: "هل ترغب في تأكيد الطلب بدونهم؟",
            lbl_barcode_price: "الرمز",
            lbl_placed_note: 'باستطاعتك تغيير الكميات و البونص قبل تأكيد الطلب. الرجاء التأكد من إعلام المشتري بالتغيرات قبل التأكيد.',
            lbl_sales: "المبيعات",
            lbl_sales_orders: "الطلبيات",
            lbl_sales_orders_status: "الحالة",
            lbl_sales_orders_invalidCols: "الأعمدة الغير صالحة",
            lbl_sales_orders_order_id: "رقم الطلب",
            lbl_sales_orders_header_order_id: "الرقم",
            lbl_sales_orders_header_date: "التاريخ",
            lbl_sales_orders_header_pricelistid: "فئة السعر",
            lbl_sales_orders_header_order_total: "الإجمالي",
            lbl_sales_orders_return_id: "رقم الإرجاع",
            lbl_sales_orders_date: "تاريخ الطلب",
            lbl_sales_orders_customer: "العميل",
            lbl_sales_orders_store: "المورد",
            lbl_sales_orders_customer_state: "المدينة",
            lbl_sales_orders_offer: "العرض",
            lbl_sales_orders_order_total: "الإجمالي",
            lbl_sales_orders_o_info_items_product_name: "المنتج",
            lbl_sales_orders_o_info_items_quantity: "الكمية",
            lbl_sales_orders_o_info_items_retail_price: "السعر",
            lbl_salesordersitem_totaldiscount: "الخصم",
            lbl_salesordersitem_totaltax: "الضريبة",
            lbl_sales_orders_o_info_items_discount_percent: "الخصم",
            lbl_sales_orders_o_info_items_sale_price: "سعر البيع",
            lbl_sales_orders_o_info_items_item_total: "الإجمالي",
            lbl_sales_orders_o_info_items_item_total_unit: "الإجمالي (د.أ)",
            lbl_sales_order: "الطلبية",
            lbl_sales_orders_o_info: "بيانات الطلب",
            lbl_sales_orders_o_info_customer: "بيانات العميل",
            lbl_sales_orders_o_info_items: "المنتجات",
            lbl_sales_orders_sh_info: "معلومات الشحن",
            lbl_sales_orders_shipment_no: "Shipment No.",
            lbl_sales_orders_shipment_date: "Shipment Date",
            lbl_sales_orders_shipment_method: "Shipment Method",
            lbl_sales_orders_carrier: "الشحن",
            lbl_sales_orders_shipping_total: "رسوم الشحن",
            lbl_sales_orders_shipping_total_unit: "رسوم الشحن (د.أ)",
            lbl_sales_orders_items_count: "عدد المنتجات",
            lbl_sales_orders_items_count_short: "المنتجات",
            lbl_sales_orders_items_quantity: "إجمالي الكميات",
            lbl_sales_orders_currency_code: "العملة",
            lbl_sales_orders_order_date: "تاريخ الطلب",
            lbl_sales_orders_updated_at: "أخر تحديث",
            lbl_sales_orders_cancel: "إلغاء الطلب",
            lbl_sales_orders_cancel_reason: "سبب الإلغاء",
            lbl_sales_orders_return_reason: "سبب الألغاء",
            lbl_sales_orders_return_reason_ar: "سبب الألغاء بالعربي",
            lbl_sales_orders_return_reason_en: "سبب الألغاء بالانكليزي",
            lbl_sales_orders_return: "إرجاع الطلب",
            lbl_sales_orders_deliver: "تسليم الطلب",
            lbl_sales_orders_print: "طباعة",
            lbl_sales_orders_print_all: "طباعة الكل ({0})",
            lbl_sales_orders_ship_all: "شحن الكل ({0})",
            lbl_sales_orders_return_all: "إرجاع الكل ({0})",
            lbl_sales_orders_deliver_all: "تسليم الكل ({0})",
            lbl_sales_orders_confirm: "تأكيد الطلب",
            lbl_sales_orders_ready: "جاهز للتوصيل",
            lbl_sales_orders_carrier_change: "تغيير الشحن",
            lbl_sales_orders_notify: "Notify",
            lbl_sales_orders_store_print: "تقرير المورد",
            lbl_sales_orders_customer_print: "تقرير العميل",
            lbl_sales_orders_shipment_print: "تقرير الشحن",
            lbl_sales_orders_items: "تقرير المكونات",
            lbl_sales_orders_sub_total: "الإجمالي الفرعي",
            lbl_sales_orders_sub_total_unit: "الإجمالي الفرعي (د.أ)",
            lbl_sales_orders_gross_total: "الإجمالي",
            lbl_sales_orders_gross_total_unit: "الإجمالي (د.أ)",
            lbl_sales_orders_total_tax: "إجمالي الضريبة",
            lbl_sales_orders_total_tax_unit: "إجمالي الضريبة (د.أ)",
            lbl_sales_orders_commission_amount: "العمولة",
            lbl_sales_orders_total: "الإجمالي",
            lbl_sales_orders_total_unit: "الإجمالي (د.أ)",
            lbl_sales_partners_orders: "Partners Orders",
            lbl_sales_partners_orders_partner: "الاسم",
            lbl_sales_partners_orders_carrier: "الشحن",
            lbl_sales_partners_orders_total_count: "عدد الطلبيات",
            lbl_sales_partners_orders_total_amount: "الإجمالي",
            lbl_sales_stores_placed_orders: "طلبيات جديدة",
            lbl_sales_stores_ready_orders: "طلبيات جاهزة للتوصيل",
            lbl_sales_stores_processing_orders: "طلبيات قيد التنفيذ",
            lbl_sales_customers_shipped_orders: "طلبيات مشحونة",
            lbl_sales_returned_orders: "المرتجعات",
            lbl_sales_orders_o_info_store: "بيانات المورد",
            lbl_sales_orders_o_info_carrier: "بيانات التوصيل",
            lbl_sales_returned_orders_shipped: "مرتجعات مشحونة",
            lbl_sales_returned_orders_ready: "طلبيات جاهزة للتوصيل",
            lbl_sales_returned_orders_new: "مرتجع جديد",
            lbl_sales_returned_order_customer: "العميل",
            lbl_sales_returned_order_order_item: "المنتج",
            lbl_sales_returned_order_order_id: "الطلب",
            lbl_sales_returned_order_store_name: "المورد",
            lbl_sales_returned_order_retail_price: "السعر",
            lbl_sales_returned_order_discount_percent: "الخصم",
            lbl_sales_returned_order_qantity: "الكمية المتاحة",
            lbl_sales_returned_order_return_qantity: "الكمية",
            lbl_finance_documents: "المالية",
            lbl_finance_documents_1: "سند دفع",
            lbl_finance_documents_2: "سند قبض",
            lbl_finance_documents_3: "إشعار دائن",
            lbl_finance_documents_4: "إشعار مدين",
            lbl_finance_documents_partner: "الحساب",
            lbl_finance_documents_document_date: "تاريخ السند",
            lbl_finance_documents_description: "البيان",
            lbl_finance_documents_description_Ar: "البيان (Ar)",
            lbl_finance_documents_journal: "دفتر اليومية اليدوي",
            lbl_finance_documents_first_account_id: "رقم الحساب الأول",
            lbl_finance_documents_is_first_account_credit: "ائتمان",
            lbl_finance_documents_second_account_id:"رقم الحساب الثاني",
            lbl_finance_documents_is_second_account_credit: "ائتمان",

            lbl_finance_documents_amount: "المبلغ",
            lbl_finance_documents_type: "نوع السند",
            lbl_finance_documents_paymentreference: "مرجع الدفع",
            lbl_reference: "مرجع",
            lbl_financial_documents_type_payment_voucher: "Payment Voucher",
            lbl_financial_documents_type_receipt_voucher: "Receipt Voucher",
            lbl_financial_documents_type_credit_note: "Credit Note",
            lbl_financial_documents_type_debit_note: "Debit Note",

            lbl_Doument_files: "الملفات",
            lbl_document_files_title: "العنوان",
            lbl_document_files_add_file: "إضافة ملف",
            lbl_document_files_add_invoice: "إضافة فاتورة",
            lbl_sales_orders_ship: "شحن الطلب",
            lbl_orders_partners_1_false_1: "طلبيات جديدة",
            lbl_orders_partners_1_false_7: "طلبيات جاهزة للتوصيل",
            lbl_orders_partners_1_false_2: "طلبيات قيد التنفيذ",
            lbl_orders_partners_1_false_3: "طلبيات مشحونة",
            lbl_orders_partners_1_true_3: "مرتجعات مشحونة",
            lbl_orders_partners_1_false_4: "Delivered Orders",
            lbl_orders_partners_1_false_5: "طلبيات ملغاة",
            lbl_orders_partners_1_false_6: "Return Orders",
            lbl_orders_partners_2_false_1: "طلبيات جديدة",
            lbl_orders_partners_2_false_7: "طلبيات جاهزة للتوصيل",
            lbl_orders_partners_2_false_2: "طلبيات قيد التنفيذ",
            lbl_orders_partners_2_false_3: "طلبيات مشحونة",
            lbl_orders_partners_2_true_3: "مرتجعات مشحونة",
            lbl_orders_partners_2_false_4: "Delivered Orders",
            lbl_orders_partners_2_false_5: "طلبيات ملغاة",
            lbl_orders_partners_2_false_6: "مرتجعات",
            lbl_orders_partner_1_false_1: "طلبيات جديدة {0}",
            lbl_orders_partner_1_false_7: "{0} طلبيات جاهزة للتوصيل",
            lbl_orders_partner_1_false_2: "طلبيات قيد التنفيذ {0}",
            lbl_orders_partner_1_false_3: "طلبيات مشحونة {0}",
            lbl_orders_partner_1_true_3: "مرتجعات مشحونة {0}",
            lbl_orders_partner_1_false_4: "Delivered Orders {0}",
            lbl_orders_partner_1_false_5: "طلبيات ملغاة {0}",
            lbl_orders_partner_1_false_6: "مرتجعات {0}",
            lbl_orders_partner_2_false_1: "طلبيات جديدة {0}",
            lbl_orders_partner_2_false_7: "{0} طلبيات جاهزة للتوصيل",
            lbl_orders_partner_2_false_2: "طلبيات قيد التنفيذ {0}",
            lbl_orders_partner_2_false_3: "طلبيات مشحونة {0}",
            lbl_orders_partner_2_true_3: "مرتجعات مشحونة {0}",
            lbl_orders_partner_2_false_4: "Delivered Orders {0}",
            lbl_orders_partner_2_false_5: "طلبيات ملغاة {0}",
            lbl_orders_partner_2_false_6: "مرتجعات {0}",
            lbl_order_add_note: "إضافة ملاحظة",
            lbl_order_notes: "الملاحظات",
            lbl_order_note: "الملاحظة",
            lbl_user_name: "اسم المستخدم",
            lbl_user_contact_mobile: "الموبايل",
            lbl_user_email: "الايميل",
            lbl_user_partner_name: "اسم العميل",
            lbl_user_details: "معلومات المستخدم",
            lbl_finance_documents_partner_balance: "الرصيد",
            lbl_finance_documents_account_id: "الحساب",
            lbl_sales_orders_o_info_items_returned: "عناصر ملغاة",
            lbl_price_list_default: "نقدي",
            lbl_price_list_credit: "ذمم المخزن",
            lbl_reports: "التقارير",
            lbl_reports_trial_balance: "ميزان المراجعة",
            lbl_reports_financial_documents: "السندات",
            lbl_reports_financial_statements_per_partner: "كشف الحساب",
            lbl_reports_financial_statements_per_account: "الحسابات",
            lbl_reports_sales_orders: "الطلبيات",
            lbl_reports_sales_returned_orders: "المرتجعات",
            lbl_reports_start_date: "من",
            lbl_reports_end_date: "إلى",
            lbl_reports_financial_documents_partner: "الحساب",
            lbl_reports_financial_documents_document_no: "رقم السند",
            lbl_reports_financial_documents_type_id: "نوع السند",
            lbl_reports_financial_statements_partner: "الحساب",
            lbl_reports_financial_statements_account: "الحساب",
            lbl_reports_sales_orders_store: "المورد",
            lbl_reports_sales_orders_customer: "العميل",
            lbl_reports_sales_orders_order: "رقم الطلب",
            lbl_reports_sales_orders_status_id: "الحالة",
            lbl_reports_sales_returned_orders_store: "المورد",
            lbl_reports_sales_returned_orders_customer: "العميل",
            lbl_reports_sales_returned_orders_order: "رقم الطلب",
            lbl_reports_sales_returned_orders_status_id: "الحالة",
            lbl_reports_invoices: "الفواتير",
            lbl_reports_invoice: "فاتورة",
            lbl_other: "اخرى",

            lbl_reports_invoices_returned: "فواتير المرتجعات",
            lbl_reports_invoices_store: "العميل",
            lbl_reports_invoices_invoice_no: "رقم الفاتورة",
            lbl_sales_orders_summary: "الملخص",
            lbl_reports_financial: "المالية",
            lbl_reports_sales: "المبيعات",
            lbl_sales_orders_order: "الطلب",
            lbl_reports_find: "بحث",
            lbl_reports_export_as_pdf: "تصدير ك PDF",
            lbl_reports_export_as_excel: "تصدير ك XLSX",
            lbl_sales_orders_total_discount: "خصم العناصر",
            lbl_sales_orders_total_discount_unit: "الخصم (د.أ)",
            lbl_sales_orders_o_info_items_quantity_ordered: "الكمية المطلوبة",
            lbl_sales_orders_applay_discount: "خصم الطلبية",
            lbl_sales_orders_applay_discount_button: "إضافة خصم للطلبية",
            // DashboardComponent
            lbl_dashboard_sales_total: "إجمالي المبيعات",
            lbl_sales_delivered_orders: "طلبيات تم تسليمها",
            lbl_sales_cancelled_orders: "طلبيات تم إلغائها",
            lbl_dashboard_balance_credit_total: "رصيد الحساب",
            lbl_dashboard_balance_debit_total: "المدينون",
            lbl_dashboard_partners_customers_total: "العملاء",
            lbl_dashboard_partners_customers_new: "{0} جديد",
            lbl_dashboard_orders_chart_title: "الطلبيات حسب الحالة",
            lbl_dashboard_orders_per_zone_chart_title: "الطلبيات حسب المنطقة",
            lbl_dashboard_orders_per_state_chart_title: "الطلبيات حسب المحافظة",
            lbl_dashboard_orders_chart_placed: "تم الطلب",
            lbl_dashboard_orders_chart_processing: "قيد التنفيذ",
            lbl_dashboard_orders_chart_shipped: "تم الشحن",
            lbl_dashboard_orders_chart_delivered: "تم التسليم",
            lbl_dashboard_orders_chart_cancelled: "تم الإلغاء",
            lbl_dashboard_partners_chart_title: "الطلبيات حسب المورد",
            lbl_dashboard_partners_chart_total_amount: "الإجمالي",
            lbl_dashboard_partners_chart_total_count: "العدد",
            lbl_dashboard_orders_totals_chart_title: "الطلبيات حسب الفترة",
            lbl_dashboard_orders_totals_chart_total_amount: "الإجمالي",
            lbl_dashboard_orders_totals_chart_total_count: "العدد",
            lbl_dashboard_orders_chart_total: "الإجمالي {0}",
            //
            KILO: "ألف",
            MILLION: "مليون",
            //errors
            lbl_errors_title: "عذرا، حدث خطأ غير متوقع",
            lbl_errors_refresh: "حاول مرة أخرى!",
            // sotres | zones
            lbl_store_zones: "مناطق الموردين",
            lbl_store_zones_store: "المورد",
            lbl_store_zones_state: "المحافظة",
            lbl_store_zones_zone: "المنطقة",
            lbl_store_zones_new: "منطقة مورد جديدة",
            lbl_store_zones_zones: "المناطق",
            // zones
            lbl_zones: "المناطق",
            lbl_zones_name: "اسم المنطقة",
            lbl_store_zones_name: "مناطق الخدمة",
            lbl_zones_new: "منطقة جديدة",
            lbl_zones_state: "المحافظة",
            lbl_zones_name_ar: "اسم المنطقة بالعربية",
            lbl_zones_name_en: "اسم المنطقة بالانكليزية",
            lbl_close: "إلغاء",
            lbl_okay: "تم",
            lbl_zones_choose: "اختر منطقة:",
            //
            lbl_sales_orders_o_history: "السجل",
            //
            lbl_marketing: "التسويق",
            lbl_marketing_campaign: "الرسائل",
            lbl_marketing_campaign_title: "العنوان",
            lbl_marketing_campaign_message: "الرسالة",
            lbl_marketing_campaign_partner_type: "الهدف",
            lbl_marketing_campaign_zones: "المناطق",
            //
            lbl_category_id: "الفئة",
            lbl_toggle_selection: "تحديد/إلغاء تحديد الكل",
            lbl_sales_orders_o_cancel_reason: "سبب الإلغاء:",
            lbl_sales_cart_store_cart_items_not_available: "غير متاح",
            lbl_error_message_checkout_from: 'خطأ : فقط الزبون قادر على اختيار الطلبية ضمن طريقة الدفع هذه',
            lbl_select: "اختيار",
            lbl_checkout_form: "معلومات الطلب",
            lbl_payment_mode: 'طريقة الدفع',
            lbl_checkout_mode: 'طريقة الطلب',
            lbl_topup: "شحن الرصيد",
            lbl_topup_partners: "شركاء التعبئة",
            lbl_topup_partners_topup_information: "معلومات شحن الرصيد",
            lbl_topup_partners_topupfeestype: "نوع الرسوم",
            lbl_topup_partners_topupfeesvalue: "قيمة الرسوم",
            lbl_topup_partners_topupcostvalue: "قيمة التكلفة",
            lbl_topup_partners_topupcosttype: "نوع التكلفة",
            lbl_topup_partners_platformpercentage: "نسبة المنصة",
            lbl_topup_partners_partnerpercentage: "نسبة الشريك",
            lbl_topup_partners_topupcashbacktype: "نوع الاسترداد النقدي",
            lbl_topup_partners_topupcashbackvalue: "قيمة الاسترداد النقدي",
            lbl_topup_partners_paylaterterms: "شروط الدفع لاحقا",
            lbl_topup_partners_terms: "الشروط",
            lbl_topup_partners_minimumtopup: "الحد الأدنى للشحن",
            lbl_topup_partners_topupcode: "رمز الشريك",
            lbl_finance_documents_paymentmethodid: "طريقة الدفع",
            lbl_reports_documents_documentid: "رقم السند",
            lbl_reports_documents_description: "الوصف",
            lbl_reports_documents_amount: "المبلغ",
            lbl_reports_documents_documenttype: "نوع الملف",
            lbl_reports_documents_documentdate: "تاريخ السند",
            lbl_reports_documents_partner: "العميل",
            lbl_reports_documents_paymentmethod: "طريقة الدفع",
            lbl_reports_documents_paymentreference: "مرجع الدفعة",
            lbl_reports_documents_site: "الموقع",
            lbl_reports_documents_totalcount: "العدد الكلي",
            lbl_reports_documents_totalamount: "المبلغ الكلي",
            lbl_reports_statements_transactionid: "رقم العملية",
            lbl_reports_statements_description: "الوصف",
            lbl_reports_statements_type: "النوع",
            lbl_reports_statements_txndate: "تاريخ العملية",
            lbl_reports_statements_createdat: "تاريخ الإنشاء",
            lbl_users_account_name: "الحساب",
            lbl_reports_statements_balance: "الرصيد",
            lbl_reports_statements_creditamount: "دائن",
            lbl_reports_statements_debitamount: "مدين",
            lbl_reports_invoices_invoiceid: "رقم الفاتورة",
            lbl_reports_invoices_description: "الوصف",
            lbl_reports_invoices_invoicedate: "تاريخ الفاتورة",
            lbl_reports_invoices_amount: "المبلغ",
            lbl_reports_invoices_tax: "الضريبة",
            lbl_reports_invoices_total: "الإجمالي",
            lbl_reports_invoices_partner: "العميل",
            lbl_reports_trialbalance_accounttitle: "الحساب",
            lbl_reports_trialbalance_accounttype: "نوع الحساب",
            lbl_reports_trialbalance_partnername: "العميل",
            lbl_reports_trialbalance_balance: "الرصيد",
            lbl_reports_financial_statements_opertion: "العملية",
            lbl_reports_financial_statements_value: "",
            lbl_rr_statements_op_debit: "مدين",
            lbl_rr_statements_op_credit: "دائن",
            lbl_topup_partners_topupthroughus: "شحن مورد",
            lbl_topup_partners_instructionurl: "رابط التعليمات",
            lbl_topup_partners_walletid: "معرف المحفظة",
            lbl_topup_partners_channel: "القناة",
            lbl_topup_partners_maximumtopup: "الحد الأعلى للشحن",
            lbl_topup_transactions: "عمليات التعبئة",
            lbl_topup_wallet_transactions: "عمليات المحفظة",
            lbl_topup_txn_txntopupid: "رقم العملية",
            lbl_topup_txn_createdat: "تاريخ العملية",
            lbl_topup_txn_topuppartner: "عميل التعبئة",
            lbl_topup_txn_partner: "العميل",
            lbl_topup_txn_topuptype: "نوع التعبئة",
            lbl_topup_txn_txnvalue: "قيمة العملية",
            lbl_topup_txn_txnstatus: "الحالة",
            lbl_topup_txn_txnfeestotal: "إجمالي الرسوم",
            lbl_topup_txn_txnfeespartner: "نسبة العمبل",
            lbl_topup_txn_txnfeesplatform: "نسبة المنصة",
            lbl_topup_txn_txncbvalue: "قيمة المكافأة",
            lbl_topup_txn_txnnetvalue: "الصافي",
            lbl_topup_txn_txnlog: "سجل العملية",
            lbl_topup_txn_referenceid: "مرجع العملية",
            lbl_topup_txn_txnstatus_1: "تم الطلب",
            lbl_topup_txn_txnstatus_2: "تم التأكيد",
            lbl_topup_txn_txnstatus_3: "تم الإلغاء",
            lbl_wallet_transaction_wallet_transaction_id: "الرمز",
            lbl_wallet_transaction_wallet_transaction_reference: "مرجع المحفظة",
            lbl_wallet_transaction_wallet_id: "رمز المحفظة",
            lbl_wallet_transaction_amount: "المقدار",
            lbl_wallet_transaction_transaction_time: "وقت العملية",
            lbl_wallet_transaction_channel: "القناة",
            lbl_wallet_transaction_debitor: "المدين",
            lbl_wallet_transaction_description: "تفاصيل",
            lbl_faield_reason: "سبب الفشل",
            lbl_wallet_transaction_status: "الحالة",
            lbl_status_initiated: "بدأت",
            lbl_status_processed: "معالجتها",
            lbl_users_nationality: "الجنسية",
            lbl_users_birthday: "تاريخ الميلاد",
            lbl_users_partner_admin: "مسؤول",
            lbl_users_suspend: 'معلق',
            lbl_otp_message: 'لقد قمنا بإرسال رمز تحقق لرقم الموبايل المسجل لدينا للعميل. الرجاء طلب الرمز من العميل لإتمام عملية التسليم.',
            lbl_checkout_confirm_meesage_1: 'سنقوم بتنبيه العميل بأن سلته مع متجركم جاهزة للدفع حتى يقوم بإنشاء الطلب. هل أنت متأكد من الاستمرار؟',
            lbl_checkout_confirm_meesage: 'أنت على وشك إنشاء طلب بالنيابة عن العميل. نفترض أنك قمت بالاتفاق مع العميل بخصوص الطلب و عملية الدفع. هل أنت متأكد من الاستمرار؟',
            uploadNotes1: 'إذا قمت باختيار "تحديث المنتجات الحالية" سيقوم مورد بتحديث كافة المنتجات الحالية و إضافة المنتجات الجديدة. مثال: في حال يوجد لديك منتجات على مورد غير موجودة في اللائحة الحالية سيحافظ مورد على هذه المنتجات كما هي. و في حال وجود منتجات في اللائحة موجودة سابقا على مورد سيقوم مورد بتحديث معلومات هذه المنتجات حسب معلومات اللائحة.',
            uploadNotes2: 'في حال عدم اختيار "تحديث المنتجات الحالية" سيقوم مورد بحذف كافة المنتجات القديمة و إضافة المنتجات الموجودة في اللائحة فقط. ملاحظة: هذا التغيير لن يؤثر على أي طلب تم إنشاؤه مسبقا.',
            lbl_continue: "موافق",
            lbl_delete_1: "نحن متأسفون على قرارك بحذف حسابك من منصة مورد و نود إعلامك ببعض النقاط قبل المتابعة:",
            lbl_delete_2: "إذا تمت عملية الحذف فلن تتمكن لاحقا من استرجاع حسابك أو استرجاع أي معلومات تخصه أو إعادة تفعيله. يجب عليك إنشاء حساب جديد في حال احتجت إلى استخدام مورد مرة أخرى.",
            lbl_delete_3: "عند تقديم طلبك فسوف نقوم بالتالي:",
            lbl_delete_4: "التأكد من صحة هويتك و طلبك لأسباب أمنية.",
            lbl_delete_5: "عند تأكيد طلبك فسوف يتم حذف كامل معلوماتك ما عدا بعض المعلومات التي سنحتاجها لأسباب قانونية.",
            lbl_delete_6: "قد تأخذ عملية الحذف قرابة 60 يوما.",
            lbl_delete_7: "قبل المتابعة نرجو التأكد من التالي:",
            lbl_delete_8: "التأكد من إتمام أي عملية شراء أو إلغاء الطلبات الفعالة.",
            lbl_delete_9: "التأكد من أن رصيدك مع مورد هو 0.",
            lbl_delete_10: "عند المتابعة سوف يتم تسجيل خروجك بطريقة أتوماتيكية و سوف تتلقى اتصال من قبل قسم خدمة العملاء لدينا لإتمام عملية الحذف.",
            lbl_import_helper: "مساعد رفع المنتجات",
            lbl_import_task_id: "رقم المهمة",
            lbl_import_task_created_at: "تاريخ الأنشاء",
            lbl_import_task_duration: "المدة",
            lbl_import_helper_status1_label: "قيد الانتظار",
            lbl_import_helper_status2_label: "لم تبدأ",
            lbl_import_helper_status3_label: "مكتمل",
            lbl_import_helper_status4_label: "فشل",
            lbl_import_helper_status1_value: "PENDING",
            lbl_import_helper_status2_value: "NOT_STARTED",
            lbl_import_helper_status3_value: "COMPLETED",
            lbl_import_helper_status4_value: "FAILED",
            lbl_import_store_product: "رفع المنتجات",
            lbl_refresh: "اعادة المهمة",
            lbl_download_original_file: "تحميل الملف الاصلي",
            lbl_downlad_confirmed_result: "تحميل النتائج",
            lbl_downlad_unconfirmed_result: "تحميل النتائج الغير مؤكدة",
            lbl_downlad_invalid_result: "تحميل النتائج الغير صالحة",
            lbl_sales_offer_items_error: "يجب إضافة منتج واحد على الأقل",
            lbl_sales_offer_items_exists_error: "المنتج موجود مسبقا",


            lbl_mapping: "التقابل",
            lbl_itemId: "رقم المادة",
            lbl_ItemName: "اسم المادة",
            lbl_ItemPrice: "السعر",
            lbl_Discount: "الخصم",
            lbl_ExpireDate: "تاريخ الانتهاء",
            lbl_itemBonus: "عروض الإضافة",
            lbl_itemDiscountOffer: "عروض الخصم",
            lbl_index: "الفهرس",
            lbl_indices: "الفهارس",
            lbl_format: "الصيغة(مثال 01/12/2027 => dd/MM/yyy)",
            lbl_separator: "الفاصل",
            lbl_algorithm: "الخوارزمية",
            lbl_SearchEngineOptions: "الألية",

            lbl_update: "تعديل",
            lbl_update_ref: "اعادة التطابق",
            lbl_store_raw_status1_label: "تطابق تام",
            lbl_store_raw_status2_label: "تطابق عالي",
            lbl_store_raw_status3_label: "تطابق وسط",
            lbl_store_raw_status4_label: "تطابق منخفض",
            lbl_store_raw_status5_label: "لا يوجد تطابق",

            lbl_store_raw_title1_label: "تطابقات تامة",
            lbl_store_raw_title2_label: "تطابقات عالية",
            lbl_store_raw_title3_label: "تطابقات متوسطة",
            lbl_store_raw_title4_label: "تطابقات منخفضة",
            lbl_store_raw_title5_label: "بلا تطابقات",
            lbl_store_raw_title6_label: "غير صالح",
            lbl_update_match: "تحديث",
            lbl_matches: "التطابقات الموجودة",
            lbl_not_selected: "غير محدد",
            lbl_options: "خيارات",
            lbl_view: "عرض",
            lbl_store_raw_confirm_status1: "تم التأكيد",
            lbl_store_raw_confirm_status2: "غير مؤكد",

            //sales offer
            lbl_sales_offer_list_title: "عروض البيع",
            lbl_sales_offer_header: "عرض البيع",
            lbl_sales_offer_price_list: "قائمة الأسعار",
            lbl_sales_offer_expire_date: "تاريخ الأنتهاء",
            lbl_sales_offer_description: "الوصف",
            lbl_sales_offer_store_name: "المتجر",
            lbl_sales_offer_title: "العنوان",
            lbl_sales_offer_store_product_name: "المنتج",
            lbl_sales_offer_store_product_qty: "الكمية",
            lbl_sales_offer_store_product_discountType: "نوع الخصم",
            lbl_sales_offer_store_product_discountVal: "قيمة الخصم",
            lbl_sales_offer_store_product_price: "السعر",
            lbl_sales_offer_new: "إضافة عرض",
            lbl_sales_offer_active: "نشط",
            lbl_sales_offer_store_product_tax: "الضريبة",
            lbl_sales_offer_items: "المنتجات",
            lbl_add_seles_offer_item_title: "إضافة منتج",
            lbl_sales_offer_sub_total: "الإجمالي الفرعي",
            lbl_sales_offer_gross_total: "الإجمالي الكلي",
            lbl_sales_offer_total_tax: "إجمالي الضريبة",
            lbl_sales_offer_total: "إجمالي العرض",
            lbl_sales_offer_total_discounts: "إجمالي الخصم",
            //payment partner

            lbl_payments: "الدفع",
            lbl_payment_partners: "شركاء الدفع",
            lbl_payment_partner_active: "نشط",
            lbl_partners_partner_title_ar: "العنوان (عربي)",
            lbl_partners_partner_title_en: "العنوان (انكليزي)",
            lbl_payment_partners_payment_information: "معلومات الدفع",
            lbl_payment_partners_transferCost: "تكلفة التحويل",
            lbl_payment_partners_transferSelling: "مبيع تكلفة التحويل",
            lbl_payment_partners_cashbackPercentage: "نسبة استرداد النقود",
            lbl_payment_partners_infoUrl: "رابط المعلومات",
            lbl_partners_uniqueid: "المعرف الفريد",
            lbl_payment_partners_otpLength: "طول رمز otp",
            lbl_payment_partners_paySellerOnDeliver: "الدفع للمتجر عند الاستلام",
            lbl_payment_partners_transferOnDeliver: "النقل عند الاستلام",

            //wrong orders
            lbl_payment_report: "تقارير الدفع",
            lbl_payment_delivered: "تقرير طلبات مسلمة",
            lbl_payment_shipped: "تقرير طلبات مشحونة",
            lbl_payment_placed: "تقرير طلبات ",

            //payment transactions
            lbl_payment_transactions: "معاملات الدفع",
            lbl_payment_transactions_id: "معرف معاملة الدفع",

            lbl_payment_txn_paymentTransactionId: "المعرف",
            lbl_payment_txn_createdat: "تاريخ الأنشاء",
            lbl_payment_txn_payment_partner: "شريك الدفع",
            lbl_payment_txn_seller_partner: "البائع",
            lbl_payment_txn_buyer_partner: "المشتري",
            lbl_payment_txn_amount: "المبلغ",
            lbl_payment_txn_txnstatus: "الحالة",
            lbl_payment_txn_mawrid_unique_reference: "معرف مورد ",
            lbl_payment_txn_partner_unique_reference: "معرف شريك الدفع",
            lbl_payment_txn_type: "النمط",
            lbl_payment_txn_state: "المرحلة",
            lbl_topup_txn_otp_trials: "Otp trials",
            lbl_transaction_details: "تفاصيل",


            //payment communications
            lbl_payment_communication: "الاتصال ",
            lbl_payment_communication_id: "المعرف",
            lbl_payment_communication_create_at: "تاريخ الأتصال",
            lbl_payment_communication_response_status: "حالة الاستجابة",
            lbl_communication_response: "الاستجابة",
            lbl_communication_request: "الطلب",
            lbl_cahnge_category: "تعديل الفئة",

            //payment order
            lbl_payment_orders: "طلبات الدفع",
            lbl_payment_orders_orderId: "معرف الطلبية",
            lbl_payment_orders_id: "معرف طلب الدفع",
            lbl_payment_orders_paymentTransactionId: "المعرف",
            lbl_payment_orders_createdat: "تاريخ الأنشاء",
            lbl_payment_orders_seller_partner: "البائع",
            lbl_payment_orders_buyer_partner: "الشاري",
            lbl_payment_orders_payment_partner: "الدفع",
            lbl_payment_orders_amount: "الكمية",
            lbl_payment_orders_release: "تحرير",
            lbl_payment_orders_payment_amount: "كمية الدفع",
            lbl_payment_orders_cart_id: "معرف السلة",
            lbl_payment_orders_block_amount_trials: "Block amount trials",
            lbl_payment_orders_status: "حالة طلب الدفع",
            lbl_payment_orders_order_total: "مبلغ الطلبية",


            //payment broken order

            lbl_broken_orders: "طلبات الدفع الفاشلة",
            lbl_broken_orders_financialBrokenOrderId: "المعرف",
            lbl_broken_orders_createdat: "تاريخ الأنشاء",
            lbl_broken_orders_seller_partner: "البائع",
            lbl_broken_orders_buyer_partner: "المشتري",
            lbl_broken_orders_amount: "الكمية",
            lbl_broken_orders_reason: "السبب",

            //delivery partner
            lbl_delivery: "التوصيل",
            lbl_delivery_partners: "شركاء التوصيل",
            lbl_delivery_partners_codCost: "تكلفة COD",
            lbl_delivery_partners_codSelling: "مبيع COD",
            lbl_delivery_partners_costs_information: "معلومات الدفع",
            lbl_delivery_cost_state: "المحافظة",
            lbl_delivery_cost: "تكلفة التوصيل",
            lbl_delivery_selling: "نسبة التوصيل",
            lbl_delivery_costs: "تكاليف التوصيل",
            lbl_delivery_partners_requiredOtp: "Otp يلزم",
            lbl_partners_partner_mawrid_unique_Id: "معرف مورد",

            //paymentransactionStatuses
            lbl_payment_txn_txnstatus_1: "تم ارسال OTP",
            lbl_payment_txn_txnstatus_2: "حجز مبلغ",
            lbl_payment_txn_txnstatus_3: "ملغاة",

            //paymentTransactionTypes
            lbl_payment_txn_txntype_1: "طلب",
            lbl_payment_txn_txntype_2: "طلب مرجع",
            lbl_payment_txn_txntype_3: "فاتوة شريك دفع",
            lbl_payment_txn_txntype_4: "فاتورة المستلم",
            lbl_payment_txn_txntype_5: "اشعار دائن",
            lbl_payment_txn_txntype_6: "اشعار مدين",
            lbl_payment_txn_txntype_7: "عمولة مورد",
            lbl_payment_txn_txntype_8: "عمولة مورد مرجعة",
            lbl_payment_txn_txntype_9: "رسوم تعبئة رصيد",
            lbl_payment_txn_txntype_10: "استرداد نقود لتعبئة الرصيد",
            lbl_payment_txn_txntype_11: "تعبئة رصيد",
            lbl_payment_txn_txntype_12: "مضاريف رسوم تعبئة رصيد",
            lbl_payment_txn_txntype_13: "ضريبة",
            lbl_eq_bonus_operation: "يساوي(=)",
            lbl_gte_bonus_operation: "اكبر من(<)",
            lbl_readyToDeliver_number_of_package: "عدد الطرود",
            lbl_readyToDeliver: "يرجى تحديد عدد الطرود في هذا الطلب و إدخال المعلومات الخاصة بكل طرد في حال الحاجة",
            lbl_readyToDeliverWithFiles: "يرجى تحديد عدد الطرود في هذا الطلب مع الملفات",
            lbl_readyToDeliverNote: "ملاحظة: سيتم اعتماد المورد لشحن الطلبية",
            lbl_readyToDeliver_remark: "ملاحظات",
            lbl_readyToDeliver_requireRefregerator: "يتطلب ثلاجة",
            //mapping
            lbl_store_delivery_mapping_list: "التقابلات",
            lbl_store_delivery_mapping_delivery_name: "المورد",
            lbl_store_delivery_mapping_add: "إضافة",
            lbl_store_delivery_mapping_value: "القيمة",
            lbl_can_shipping: "يمكن الشحن",
            lbl_inbound: "زيادة",
            lbl_outbound: "نقصان",
            lbl_stock_reset: "إعادة ضبط",
            lbl_movement_source_order: "طلبية",
            lbl_movement_source_manual: "يدوي",
            lbl_movement_source_update: "تحديث",
            lbl_movement_source_external: "خارجي",
            lbl_movement_source_import: "استيراد",
            lbl_stock_history: "تاريخ المخزون",
            lbl_stock_stock_id: "المعرف",
            lbl_stock_movement_type: "نوع الحركة",
            lbl_stock_movement_source: "مصدر الحركة",
            lbl_stock_quantity: "الكمية",
            lbl_stock_stock_balance: "الرصيد",
            lbl_stock_reference_id: "المرجع",

            
            //finance dashboard
            lbl_finance_header:"تفاصيل",
            lbl_stores_liabilities: "التزامات الموردين",
            lbl_stores_dues: "رسوم الموردين",
            lbl_topup_balances: "أرصدة شركاء التعبئة",
            lbl_customer_wallet_balances: "أرصدة العملاء",
            lbl_bank_balances: "أرصدة البنك"

        });

        this.translations = {};
    }

    public setLocale(language: string): void {
        PrimeReact.locale = language;
        this.translations = locale(language).options;
    }

    public translate(key: string | undefined | null, ...args: Array<string>): string {
        if (!key) {
            return "";
        }
        if (!this.translations.hasOwnProperty(key)) {
            return key;
        }
        let value = this.translations[key];
        if (args && args.length) {
            args.forEach((e, i) => (value = value.replace(`{${i}}`, e)));
        }
        return value;
    }
}

export const tawreedTranslationService = new TawreedTranslationService();
